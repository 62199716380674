// @ts-nocheck
import { environment as prodEnv } from "environments/environment.prod";
import { emizaConfig } from "tenants/emiza/config";

export const environment = {
  ...prodEnv,
  tenant: emizaConfig,
};

environment.tenant.deliveryManagerUrl =
  "https://tracking-page.emizainc.com/delivery-manager";
