import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DownloadWeightDiscrepancyOrdersComponent } from "./download-weight-discrepancy-orders/download-weight-discrepancy-orders.component";
import { MatButtonModule } from "@angular/material/button";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatIconModule } from "@angular/material/icon";

@NgModule({
  declarations: [DownloadWeightDiscrepancyOrdersComponent],
  imports: [CommonModule, MatButtonModule, MatToolbarModule, MatIconModule]
})
export class DownloadWeightDiscrepancyOrdersModule {}
