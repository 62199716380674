<div
  fxLayout="row"
  fxLayoutAlign="start center"
  class="toolbar-notifications"
  (clickOutside)="onClickOutside()"
>
  <button
    mat-button
    [class.open]="isOpen"
    class="button"
    (click)="toggleDropdown()"
  >
    <mat-icon
      class="secondary-text"
      [matBadgeHidden]="!notifications.length"
      [matBadge]="notifications.length"
      matBadgeColor="accent"
      >notifications_active</mat-icon
    >
  </button>

  <mat-card [class.open]="isOpen" class="dropdown">
    <!-- Header -->
    <div
      class="card-header"
      fxLayout="row"
      fxLayoutAlign="space-between center"
    >
      <div>
        <mat-card-title class="card-title">Notifications</mat-card-title>
        <mat-card-subtitle class="card-subtitle">
          You have {{ notifications.length }} notifications.
        </mat-card-subtitle>
        <!-- <mat-card-subtitle class="card-subtitle" *ngIf="lastUpdated">
          Last updated {{ lastUpdated | dateAgo }}
        </mat-card-subtitle> -->
      </div>

      <button
        mat-icon-button
        type="button"
        (click)="refreshNotifications()"
        [class.loading]="refreshed"
        [disabled]="refreshed"
        title="Refresh notifications"
      >
        <mat-icon class="icon">autorenew</mat-icon>
      </button>
    </div>

    <mat-divider [inset]="true"></mat-divider>

    <!-- Card content -->
    <mat-card-content class="card-content" fusePerfectScrollbar>
      <ng-container *ngFor="let notification of notifications; let last = last">
        <div
          class="notification"
          fxLayout="row"
          fxLayoutAlign="start center"
          (click)="takeAction(notification)"
          matRipple
        >
          <mat-icon class="icon">
            {{ notification.icon }}
          </mat-icon>
          <div class="label" fxLayout="column">
            <div title="{{ notification.title }}">
              {{ notification.title | reducedText: 30 }}
            </div>
            <div class="desc" title="{{ notification.description }}">
              {{ notification.description | reducedText: 35 }}
            </div>
          </div>

          <span fxFlex></span>

          <button
            type="button"
            mat-icon-button
            (click)="dismiss($event, notification._id)"
            *ngIf="!notification?.canNotBeCleared"
          >
            <mat-icon class="close">close</mat-icon>
          </button>
        </div>
        <mat-divider [inset]="true" *ngIf="!last"></mat-divider>
      </ng-container>

      <div *ngIf="!notifications.length" class="empty">
        All fine! No notifications right now.
      </div>
    </mat-card-content>

    <mat-divider [inset]="true"></mat-divider>
    <div
      matRipple
      [matRippleDisabled]="!notifications.length"
      [class.secondary-text]="!notifications.length"
      class="font-size-12 cursor-pointer"
      fxLayout="row"
      fxLayoutAlign="center"
      (click)="clearNotifications()"
    >
      Clear all notifications
    </div>
  </mat-card>
</div>
