<div class="dialog-content-wrapper">
  <mat-toolbar matDialogTitle class="mat-accent m-0">
    <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
      <span class="title dialog-title">{{
        bulkProcessStatus === "processing"
          ? "Processing Orders"
          : "Orders Summary"
      }}</span>
      <button mat-icon-button aria-label="Close dialog" (click)="closeDialog()">
        <mat-icon [ngClass]="{ 'mb-12': bulkProcessStatus === 'processing' }">{{
          closeIcon
        }}</mat-icon>
      </button>
    </mat-toolbar-row>
  </mat-toolbar>

  <div mat-dialog-content class="p-24 pt-12 m-0">
    <bulk-processing
      *ngIf="bulkProcessStatus === 'processing'; else processed"
      [progressValue]="progressValue"
      [step]="orderSteps"
      [processedOrder]="processedOrder"
      [totalOrder]="totalOrder"
    ></bulk-processing>

    <ng-template #processed>
      <bulk-processed
        [summaryTotalOrder]="summaryTotalOrder"
        [totalShippingCharge]="totalShippingCharge"
        [totalWeight]="totalWeight"
        [unservicable]="unservicable"
        [noPickUp]="noPickUp"
        [notBooked]="notBooked"
        [pickedAWB]="pickedAWB"
        [bulkResponse]="response"
        (closeDialog)="_dialogRef.close()"
      ></bulk-processed>
    </ng-template>
  </div>
</div>
