import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DocumentDetailsComponent } from "./document-details/document-details.component";
import { PipesModule } from "../../pipes/pipes.module";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";

@NgModule({
  declarations: [DocumentDetailsComponent],
  imports: [CommonModule, PipesModule, MatIconModule, MatButtonModule],
  exports: [DocumentDetailsComponent]
})
export class DocumentDetailsModule {}
