import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";

@Component({
  selector: "app-kyc-notification",
  templateUrl: "./kyc-notification.component.html",
  styleUrls: [
    "./kyc-notification.component.scss",
    "./../../../scss/responsive-modals.scss"
  ],
  encapsulation: ViewEncapsulation.None
})
export class KycNotificationComponent implements OnInit {
  constructor(
    private _router: Router,
    private _dialogRef: MatDialogRef<KycNotificationComponent>
  ) {}

  ngOnInit(): void {}

  navigationUrl() {
    this._router.navigateByUrl("settings/accounting?tab=accounting-details");
    this._dialogRef.close();
  }
}
