import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject } from "rxjs";
import { NotificationService } from "app/main/shared/services/notification.service";

@Injectable({
  providedIn: "root"
})
export class ReverseOrderService {
  currentOrder: BehaviorSubject<any> = new BehaviorSubject({});

  constructor(
    private _http: HttpClient,
    private _router: Router,
    public _notification: NotificationService
  ) {}

  reverseOrder(order) {
    this.currentOrder.next(order);
    this._router.navigate(["actions", "reverse-order", order.SG_ORDERID]);
  }

  updateOrder = (body: any) =>
    this._http.post<any>("user/order/reverseOrder", body);

  getOrder(body): void {
    this._http.post("user/order/singleOrder", body).subscribe((res: any) => {
      if (res.success) {
        this.currentOrder.next(res);
      } else {
        this.currentOrder.next({});
        this._notification.show(res.msg);
      }
    });
  }

  getCourierPrices = (body) => this._http.post("user/order/orderPrice", body);
}
