import { Component, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { FuseConfigService } from "@fuse/services/config.service";
import { navigation } from "app/navigation/navigation";
import * as supportedBrowsers from "../../../../../supportedBrowsers";
import { BannerMessageService } from "app/main/shared/modules/banner-message/banner-message.service";

@Component({
  selector: "vertical-layout-1",
  templateUrl: "./layout-1.component.html",
  styleUrls: ["./layout-1.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class VerticalLayout1Component implements OnInit, OnDestroy {
  fuseConfig: any;
  navigation: any;
  showBanner: boolean = false;

  // Private
  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   *
   * @param {FuseConfigService} _fuseConfigService
   */
  constructor(
    private _fuseConfigService: FuseConfigService,
    private _bannerService: BannerMessageService
  ) {
    // Set the defaults
    this.navigation = navigation;

    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Subscribe to config changes
    this._fuseConfigService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((config) => {
        this.fuseConfig = config;
      });

    this.checkIfBrowserSupported();
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  hideBannerMessage(): void {
    this.showBanner = false;
  }

  checkIfBrowserSupported(): void {
    const isBrowserSupported = supportedBrowsers.test(navigator.userAgent);

    if (!isBrowserSupported) {
      // this.showBanner = true;
      this._bannerService.showBannerMessage({
        content:
          "It looks like you are using an unsupported browser. Please use a Supported Browser for better user experience.",
        action: "openModal",
        actionContent: "View List"
      });
    }
  }
}
