import { TitleCasePipe } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject } from "rxjs";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { ToolbarNotificationService } from "app/main/ui/toolbar-notification.service";
import { allNotifications } from "./../../shared/ts/notificationConfig";
import { ToolbarNotification } from "../interfaces/toolbar-notificaton";
import * as moment from "moment";
import { integrationUrls } from "./../../shared/ts/integrationConfig";
import { BannerMessageService } from "../modules/banner-message/banner-message.service";
import { BrowserStorageService } from "./browser-storage.service";
import { MatDialog } from "@angular/material/dialog";
import { KycNotificationComponent } from "../modals/kyc-notification/component/kyc-notification.component";

@Injectable({
  providedIn: "root"
})
export class ProfileDataService {
  constructor(
    private _http: HttpClient,
    private _toolbarNotification: ToolbarNotificationService,
    private _router: Router,
    private _bannerMessageService: BannerMessageService,
    private _browserStorage: BrowserStorageService,
    private _dialog: MatDialog
  ) {}
  // declarations
  titleCasePipe = new TitleCasePipe();

  profileData: BehaviorSubject<any> = new BehaviorSubject({});
  kycData: BehaviorSubject<any> = new BehaviorSubject({});
  platforms: BehaviorSubject<any> = new BehaviorSubject([]);

  _integrations: BehaviorSubject<any> = new BehaviorSubject({});
  integrations = this._integrations.asObservable();

  _userPermissions: BehaviorSubject<any> = new BehaviorSubject({});
  userPermissions = this._userPermissions.asObservable();

  _apiPermissions: BehaviorSubject<any> = new BehaviorSubject({});
  apiPermissions = this._apiPermissions.asObservable();

  _orderPermissions: BehaviorSubject<any> = new BehaviorSubject({});
  orderPermissions = this._orderPermissions.asObservable();

  _configurationPermissions: BehaviorSubject<any> = new BehaviorSubject({});
  configurationPermissions = this._configurationPermissions.asObservable();

  storeIdObject = {
    shopify: undefined,
    woocommerce: undefined,
    magento2: undefined,
    magento1: undefined,
    amazon: undefined,
    instamojo: undefined,
    zoho: undefined
  };

  kycContent = {
    0: {
      content:
        "Please verify your KYC status to get full access to the dashboard.",
      action: "navigate",
      actionContent: "Click Here",
      url: "settings/accounting"
    }, // yes
    1: {
      content:
        "Your KYC verification request has been submitted. Please give us sometime to approve the same."
    },
    2: {
      content:
        "Your KYC verification request has been submitted. Please give us sometime to approve the same."
    },
    "-1": {
      content:
        "Your KYC verification request has been rejected. Please re-verify or contact support.",
      action: "navigate",
      actionContent: "Click Here",
      url: "settings/accounting"
    } // yes
  };

  getProfileData(): void {
    // console.log("getProfileData from profile-data.service.ts called");

    this._http.get("user/config/profile").subscribe(
      (res: any) => {
        if (res.success) {
          this.profileData.next(res);
          this._integrations.next(res.integrations || {});
          this.transformIntegrations(res.integrations);
          this.handleBanner(res.profile);
          // to add/remove toolbar notification
          this.pushNotifications();
        } else {
          this.profileData.next({});
        }
      },
      () => {
        this.profileData.next({});
      }
    );
  }

  handleBanner(profile) {
    const { status } = profile?.kyc;
    const { tenant, signUpPlatform } = profile;
    if (status === 3 || tenant === "emiza") {
      this._bannerMessageService.hideBannerMessage();
    } else if (status === 4 || signUpPlatform === "swiggy") {
      this._bannerMessageService.hideBannerMessage();
      this.getKycStatus();
    } else if (status === -1) {
      this._bannerMessageService.showBannerMessage(this.kycContent[status]);
      this.openKYCModal();
    } else {
      this.getKycStatus();
      this._bannerMessageService.showBannerMessage(this.kycContent[status]);
      this.openKYCModal();
    }
  }

  openKYCModal() {
    this._dialog.open(KycNotificationComponent, {
      panelClass: "small-modal"
    });
  }

  getKycStatus() {
    let token = this._browserStorage.getItemFromLS("shippiGoToken");
    this._http
      .get("user/config/kycVerification/status", {
        headers: {
          "x-access-token": token
        }
      })
      .subscribe((res: any) => {
        if (res.success) {
          this.kycData.next(res.data);
        } else {
          this.kycData.next({});
        }
      });
  }

  getStoreId(route: string, callbackFn: () => void): void {
    const storeIdApiObject = integrationUrls[route];
    const apiUrl = storeIdApiObject?.getStore;

    if (!apiUrl) {
      throw new Error(`No API URL for ${route} found.`);
    }

    if (!this.storeIdObject[route]) {
      this._http.get(apiUrl).subscribe(
        (res: any) => {
          if (res.success) {
            this.storeIdObject[route] = res.stores[0].storeId;
            callbackFn();
          } else {
            this.storeIdObject[route] = "";
          }
        },
        () => {
          this.storeIdObject[route] = "";
        }
      );
    } else {
      callbackFn();
    }
  }

  transformIntegrations(integrations): void {
    const intergrationList = [];

    if (!!Object.keys(integrations).length) {
      intergrationList.push({ label: "Manual/CSV", value: "manual-csv" });

      Object.keys(integrations).forEach((key) => {
        if (integrations[key]) {
          intergrationList.push({
            label: key,
            value: key
          });
        }
      });
    }

    this.platforms.next(intergrationList);
  }

  getPermissions(): void {
    this._http.get("user/config/getPermissions").subscribe(
      (res: any) => {
        if (res.success) {
          const { vas, order, configuration, ...api } = res.permissions;
          const apiModified = { ...res.permissions, config: api.configuration };
          this._orderPermissions.next(order);
          this._apiPermissions.next(apiModified);
          this._userPermissions.next(vas);
          this._configurationPermissions.next(configuration);
        } else {
          this._apiPermissions.next({});
          this._userPermissions.next({});
          this._orderPermissions.next({});
          this._configurationPermissions.next({});
        }
      },
      () => {
        this._apiPermissions.next({});
        this._userPermissions.next({});
      }
    );
  }

  pushNotifications(): void {
    let { accountDetails, companyDetails, accountBalance, bulkProcessFailed } =
      allNotifications as { [key: string]: ToolbarNotification };

    const { accountingDetails, status, balance, bulkData, bulk } =
      this.profileData?.getValue()?.profile || {};

    // check if accounting details added
    if (!accountingDetails) {
      this._toolbarNotification.add(accountDetails);
    } else {
      this._toolbarNotification.remove(accountDetails._id);
    }

    // check if company details added
    if (status <= 1) {
      this._toolbarNotification.add(companyDetails);
      this.navigateToCompanyDetails();
    } else {
      this._toolbarNotification.remove(companyDetails._id);
    }

    // check if wallet balance is less than 500 add toolbar notification else remove
    if (balance < 50000) {
      this._toolbarNotification.add(accountBalance);
    } else {
      this._toolbarNotification.remove(accountBalance._id);
    }

    // check error of last failed bulk process
    if (
      bulk === "close" &&
      !bulkData.success &&
      this.whetherBulkRecent(bulkData.closedAt)
    ) {
      const { batchId, msg } = bulkData;

      if (batchId && msg) {
        bulkProcessFailed.url = `orders/batch/${batchId}`;
        bulkProcessFailed.description = msg;

        this._toolbarNotification.add(bulkProcessFailed);
      }
    } else {
      this._toolbarNotification.remove(bulkProcessFailed._id);
    }
  }

  whetherBulkRecent(closedAt: string): boolean {
    if (!closedAt) return false;

    const now = moment();
    const minuteDiff = now.diff(closedAt, "minutes");

    if (minuteDiff > 60) return false;
    return true;
  }

  navigateToCompanyDetails(): void {
    this._router.navigate(["settings", "profile"], {
      queryParams: { tab: "company-details" }
    });
  }
}
