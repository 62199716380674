<div class="dialog-content-wrapper">
  <mat-toolbar matDialogTitle class="mat-accent m-0">
    <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
      <span class="title dialog-title">{{ confirmTitle }}</span>
      <button
        mat-icon-button
        [mat-dialog-close]="false"
        aria-label="Close dialog"
      >
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar-row>
  </mat-toolbar>

  <div mat-dialog-content class="p-24 pb-0 m-0" fusePerfectScrollbar>
    <p>
      {{ confirmMessage }}
    </p>

    <p *ngIf="confirmSummary">
      {{ confirmSummary }}
    </p>
  </div>

  <div
    mat-dialog-actions
    class="m-0 p-16"
    fxLayout="row"
    fxLayoutGap="2px"
    fxLayoutAlign="end center"
  >
    <button
      mat-raised-button
      class="mat-accent"
      cdkFocusInitial
      [mat-dialog-close]="true"
    >
      {{ confirmButtonText }}
    </button>
    <button mat-stroked-button color="primary" mat-dialog-close>
      {{ cancelButtonText }}
    </button>
  </div>
</div>
