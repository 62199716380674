export type Courier = {
  name: string;
  value: string;
  rates: any[];
  count: number;
};

export const couriers: Courier[] = [
  {
    name: "Delhivery",
    value: "delhivery",
    rates: [],
    count: 0
  },
  {
    name: "DTDC",
    value: "dtdc",
    rates: [],
    count: 0
  },
  {
    name: "Ecom",
    value: "ecom",
    rates: [],
    count: 0
  },
  {
    name: "Ekart",
    value: "ekart",
    rates: [],
    count: 0
  },
  {
    name: "Shadowfax",
    value: "shadowfax",
    rates: [],
    count: 0
  },
  {
    name: "Smartr",
    value: "smartr",
    rates: [],
    count: 0
  },
  {
    name: "Xpressbees",
    value: "xpressbees",
    rates: [],
    count: 0
  },
  {
    name: "Loadshare",
    value: "loadshare",
    rates: [],
    count: 0
  },
  {
    name: "BlueDart",
    value: "bluedart",
    rates: [],
    count: 0
  },
  {
    name: "Pikndel",
    value: "pikndel",
    rates: [],
    count: 0
  }
];

export type CourierZone = {
  zone: string;
  key: string;
  label: string;
  description: string;
};

export const zones: CourierZone[] = [
  {
    // A = Within City
    zone: "A",
    key: "same_city",
    label: "Within City",
    description: "Pickup & Delivery city is same."
  },
  {
    // B = Within State
    zone: "B",
    key: "same_state",
    label: "Within State",
    description: "Pickup & Delivery state is same."
  },
  {
    // C = Within Region
    zone: "C",
    key: "same_zone",
    label: "Within Zone",
    description: "Pickup & Delivery state is in same zone."
  },
  {
    // D = Metro to Metro
    zone: "D",
    key: "metros",
    label: "Metro to Metro",
    description: "Pickup & Delivery city is a metro city."
  },
  {
    // E = Rest of India
    zone: "E",
    key: "rest_of_india",
    label: "Rest of India",
    description:
      "If any or both Pickup & Delivery city is in Rest of India but not in Zone F and after."
  },
  {
    // F = HP
    zone: "F",
    key: "HP",
    label: "Himachal Pradesh",
    description: "If any or both Pickup & Delivery city is in Himachal Pradesh."
  },
  {
    // G = UK
    zone: "G",
    key: "UK",
    label: "Uttarakhand",
    description: "If any or both Pickup & Delivery city is in Uttarakhand."
  },
  {
    // H = NE
    zone: "H",
    key: "NE",
    label: "North East",
    description:
      "If any or both Pickup & Delivery state is in North East region."
  },
  {
    // I = Kerala
    zone: "I",
    key: "KL",
    label: "Kerala",
    description: "If any or both Pickup & Delivery city is in Kerala."
  },
  {
    // J = Jammu
    zone: "J",
    key: "JAMMU",
    label: "Jammu",
    description: "If any or both Pickup & Delivery city is in Jammu."
  },
  {
    // X = Special Destination (Andaman & Nicobar, Lakshadweep, Kashmir, Leh, Ladakh)
    zone: "X",
    key: "special_zone",
    label: "Special Zone",
    description:
      "If any or both Pickup & Delivery city is in Andaman & Nicobar, Lakshadweep, Kashmir, Leh, and Ladakh"
  }
];

// B2B zones
export type B2BCouierZone = {
  zone: string;
  key: string;
  description: string;
};
export const b2bZones: B2BCouierZone[] = [
  {
    zone: "C1",
    key: "c1",
    description: "Bhopal, Indore, Raipur"
  },
  {
    zone: "C2",
    key: "c2",
    description: "Chattisgarh, Madhya Pradesh"
  },
  {
    zone: "E1",
    key: "e1",
    description: "Kolkata, Patna, Ranchi,  Bhubaneswar , Siliguri"
  },
  {
    zone: "E2",
    key: "e2",
    description: "Bihar, Odisha, Jharkhand, West Bengal"
  },
  {
    zone: "N1",
    key: "n1",
    description:
      "New Delhi, Faridabad, Gurgaon, Ghaziabad, Noida, Sahibabad, Chandigarh, Jaipur"
  },
  {
    zone: "N2",
    key: "n2",
    description: "Dehradun, Ludhiana"
  },
  {
    zone: "N3",
    key: "n3",
    description: "Haryana, Punjab, Rajasthan,  Uttar Pradesh"
  },
  {
    zone: "N4",
    key: "n4",
    description: "Himachal Pradesh, Jammu & Kashmir, Uttarakhand"
  },
  {
    zone: "NE1",
    key: "ne1",
    description: "Guwahati"
  },
  {
    zone: "NE2",
    key: "ne2",
    description:
      "Arunachal Pradesh, Assam, Manipur, Meghalaya, Mizoram, Nagaland, Sikkim, Tripura"
  },
  {
    zone: "S1",
    key: "s1",
    description: "Bengaluru, Hyderabad, Chennai"
  },
  {
    zone: "S2",
    key: "s2",
    description: "Andhra Pradesh, Karnataka, Telangana"
  },
  {
    zone: "S3",
    key: "s3",
    description: "Tamil Nadu, Pondicherry"
  },
  {
    zone: "S4",
    key: "s4",
    description: "Kerala"
  },
  {
    zone: "W1",
    key: "w1",
    description: "Mumbai, Pune, Thane,  Ahmedabad , Surat"
  },
  {
    zone: "W2",
    key: "w2",
    description: "Maharashtra, Gujarat, Goa"
  }
];

export type CourierRates = {
  courier_service: string;
  mode: "express" | "surface";
  rateType: "B2B" | "B2C";
  sdd: boolean; // Same Day Delivery
  ndd: boolean; // Next Day Delivery
};

export type CourierServices = {
  name: string;
  location: number;
  filterLogic: (rates: CourierRates) => boolean;
  rates: any[];
  count: number;
};

export const services: CourierServices[] = [
  {
    name: "Express B2C",
    location: 0,
    filterLogic: ({ mode, rateType, sdd, ndd }: CourierRates) =>
      mode === "express" && rateType === "B2C" && !sdd && !ndd,
    rates: [],
    count: 0
  },
  {
    name: "Express B2B",
    location: 1,
    filterLogic: ({ mode, rateType, sdd, ndd }: CourierRates) =>
      mode === "express" && rateType === "B2B" && !sdd && !ndd,
    rates: [],
    count: 0
  },
  {
    name: "Surface B2C",
    location: 2,
    filterLogic: ({ mode, rateType, sdd, ndd }: CourierRates) =>
      mode === "surface" && rateType === "B2C" && !sdd && !ndd,
    rates: [],
    count: 0
  },
  {
    name: "Surface B2B",
    location: 3,
    filterLogic: ({ mode, rateType, sdd, ndd }: CourierRates) =>
      mode === "surface" && rateType === "B2B" && !sdd && !ndd,
    rates: [],
    count: 0
  },
  {
    name: "Same Day Delivery",
    location: 4,
    filterLogic: ({ sdd }: CourierRates) => sdd,
    rates: [],
    count: 0
  },
  {
    name: "Next Day Delivery",
    location: 5,
    filterLogic: ({ ndd }: CourierRates) => ndd,
    rates: [],
    count: 0
  }
];
