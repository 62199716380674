<div class="dialog-content-wrapper">
  <mat-toolbar matDialogTitle class="mat-accent m-0">
    <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
      <span class="title dialog-title">Create Ticket</span>
      <button
        mat-icon-button
        [mat-dialog-close]="false"
        aria-label="Close dialog"
      >
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar-row>
  </mat-toolbar>

  <div mat-dialog-content class="p-24 pb-0 m-0" fusePerfectScrollbar>
    <form [formGroup]="createTicketForm" fxLayout="column">
      <mat-form-field *ngIf="orderData" appearance="outline" fxFlex>
        <mat-label>Issue Type</mat-label>
        <mat-select
          placeholder="Issue Type"
          formControlName="queryType"
          >
          <!-- (selectionChange)="reasonField($event)" -->
          <mat-option *ngFor="let issue of issueTypes" [value]="issue">
            {{ issue }}
          </mat-option>
        </mat-select>

        <mat-error>
          <error-message
            fieldName="Issue Type"
            [formControlErr]="createTicketForm.controls.queryType"
          ></error-message>
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline" fxFlex>
        <mat-label>Order Number</mat-label>
        <input matInput formControlName="orderNo" trimValue />
      </mat-form-field>

      <mat-form-field appearance="outline" fxFlex>
        <mat-label>AWB Number</mat-label>
        <input matInput formControlName="awb" trimValue />
      </mat-form-field>

      <mat-form-field appearance="outline" fxFlex>
        <mat-label>Subject</mat-label>
        <input matInput formControlName="subject" trimValue />

        <mat-error>
          <error-message
            fieldName="Subject"
            [formControlErr]="createTicketForm.controls.subject"
          ></error-message>
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline" fxFlex>
        <mat-label>Description</mat-label>
        <textarea
          matInput
          formControlName="msg"
          required
          maxlength="240"
          rows="5"
          trimValue
        ></textarea>

        <mat-error>
          <error-message
            fieldName="Description"
            [formControlErr]="createTicketForm.controls.msg"
          ></error-message>
        </mat-error>
      </mat-form-field>


      <!-- Attachment -->
      <div fxFlex>
        <input
          type="file"
          #attachmentInput
          (change)="uploadDocument($event, createTicketForm.get('attachments1'), 'attachments1')"
          accept=".png, .jpg, .jpeg, .pdf, .csv, .doc, .docx, .xls, .xlsx"
          hidden
        />
        <mat-form-field appearance="outline" fxFlex>
          <mat-label>Attachment</mat-label>
          <input matInput formControlName="attachments1" readonly />
          <button
            type="button"
            mat-icon-button
            matSuffix
            color="primary"
            eid="uploadAttachmentBtn"
          >
            <mat-icon (click)="attachmentInput.click()"> attach_file </mat-icon>
          </button>

          <mat-hint>
            Allowed file formats:
            <span *ngFor="let file of allowedFileFormats; last as last"
              >{{ file }}<span *ngIf="!last">, </span></span
            >
          </mat-hint>

          <mat-error *ngIf="createTicketForm.get('attachments1').invalid">
            <div *ngIf="createTicketForm.get('attachments1').hasError('invalidFile')">
              Invalid File.
            </div>
            <div *ngIf="createTicketForm.get('attachments1').hasError('largeFile')">
              File size must be less then 10MB.
            </div>
            <div *ngIf="createTicketForm.get('attachments1').hasError('required')">
              PAN Card Photo is required.
            </div>
          </mat-error>
        </mat-form-field>
      </div>
    </form>
  </div>

  <div
    mat-dialog-actions
    class="m-0 p-16"
    fxLayout="row"
    fxLayoutAlign="end center"
  >
    <button
      mat-flat-button
      class="mat-accent"
      [disabled]="createTicketForm.invalid"
      (click)="onSubmit()"
    >
      Submit
    </button>
  </div>
</div>
