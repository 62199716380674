import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

export type Action = {
  type: string;
  count?: number;
};

@Injectable({
  providedIn: "root"
})
export class OrdersDataService {
  // for refresh orders
  updateOrders: Subject<any> = new Subject();

  refreshOrders = (action: Action = { type: "unknown" }) =>
    this.updateOrders.next(action);
}
