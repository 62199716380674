import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DisputeDiscrepancyModalComponent } from "./dispute-discrepancy-modal/dispute-discrepancy-modal.component";
import { MatInputModule } from "@angular/material/input";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { FuseSharedModule } from "@fuse/shared.module";
import { MatDialogModule } from "@angular/material/dialog";
import { FuseNavigationModule } from "@fuse/components";
import { ImagesUploadComponent } from "./child-component/images-upload/images-upload.component";
import { FileSizeModule } from "../../pipes/file-size/file-size.module";
import { ErrorMessageModule } from "../../modules/error-message/error-message.module";

@NgModule({
  declarations: [DisputeDiscrepancyModalComponent, ImagesUploadComponent],
  imports: [
    CommonModule,
    MatInputModule,
    MatButtonModule,
    MatToolbarModule,
    MatDialogModule,
    FuseSharedModule,
    MatIconModule,
    FuseNavigationModule,
    FileSizeModule,
    ErrorMessageModule
  ]
})
export class DisputeDiscrepancyModule {}
