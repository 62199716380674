import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root"
})
export class ShippingApiService {
  constructor(private _http: HttpClient) {}

  addWarehouse = (body) => this._http.post("user/config/addWarehouse", body);

  // delete warehouse
  deleteWarehouse = (body) =>
    this._http.post("user/config/deleteWarehouse", body);

  getAllSku = (page, body) =>
    this._http.post(`user/config/sku/showSKU/${page}`, body);

  addSingleSku = (body) =>
    this._http.post("user/config/sku/updateDetails", body);

  uploadSkuCsv = (body) => this._http.post("user/config/sku/uploadCsv", body);

  syncSKUWithShopify = (body) =>
    this._http.post("user/order/fetchShopifyProducts", body);

  updateLabel = (body) => this._http.post("user/config/labelOptions", body);

  syncSkuWith = (platform: string, body) => {
    const syncProductApi = {
      shopify: "user/order/fetchShopifyProducts",
      woocommerce: "user/order/woo/fetchProducts",
      magento1: "user/order/mag/1/fetchProducts",
      magento2: "user/order/mag/2/getProducts"
    };
    const apiUrl = syncProductApi[platform];

    return this._http.post(apiUrl, body);
  };

  syncWarehouses = (platform: string, body) => {
    const syncWarehouseApi = {
      shopify: "user/order/fetchShopifyWarehouses",
      magento2: "user/order/mag/2/syncWarehouses"
    };
    const apiUrl = syncWarehouseApi[platform];

    return this._http.post(apiUrl, body);
  };

  getPrintPreferences = () => this._http.get("user/order/print/preferences");

  updatePrintPreferences = (body) =>
    this._http.post("user/order/print/preferences", body);

  editWarehouse = (body) => this._http.post("user/config/editWarehouse", body);

  updateCourierPreference = (body) =>
    this._http.post("user/config/courierSelection", body);

  deleteSingleSku = (body) =>
    this._http.post("user/config/sku/deleteSKU", body);
}
