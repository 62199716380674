import { NgModule } from "@angular/core";
import { ShowMessageDetailsComponent } from "./component/show-message-details.component";
import { FuseSharedModule } from "@fuse/shared.module";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { MatIconModule } from "@angular/material/icon";
import { MatToolbarModule } from "@angular/material/toolbar";

@NgModule({
  declarations: [ShowMessageDetailsComponent],
  imports: [
    // fuse
    FuseSharedModule,

    // material
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    MatToolbarModule
  ]
})
export class ShowMessageDetailsModule {}
