import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NdrReattemptDeliveryComponent } from "./component/ndr-reattempt-delivery.component";
import { FuseSharedModule } from "@fuse/shared.module";
import { MatIconModule } from "@angular/material/icon";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { ErrorMessageModule } from "../../modules/error-message/error-message.module";

@NgModule({
  declarations: [NdrReattemptDeliveryComponent],
  imports: [
    // fuse
    FuseSharedModule,

    ErrorMessageModule,

    // material
    MatIconModule,
    MatToolbarModule,
    MatButtonModule,
    MatDialogModule,
    MatInputModule,
    MatFormFieldModule
  ]
})
export class NdrReattemptDeliveryModule {}
