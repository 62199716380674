const noBatchStore = ["unicommerce", "ESEC", "shippigo", "clickpost"];

const noSkuStore = [
  "unicommerce",
  "ESEC",
  "shippigo",
  "amazon",
  "zoho",
  "instamojo",
  "clickpost"
];

const noWarehouseStore = [
  "woocommerce",
  "magento1",
  "unicommerce",
  "ESEC",
  "shippigo",
  "amazon",
  "zoho",
  "instamojo",
  "clickpost"
];

const noChannelStore = ["shippigo"];

// these are paths
const showSyncButton = [
  "shopify",
  "woocommerce",
  "magento1",
  "magento2",
  "amazon",
  "zoho",
  "instamojo"
];

const showResyncButton = ["shopify", "woocommerce", "zoho"];

export {
  noBatchStore,
  noSkuStore,
  noWarehouseStore,
  noChannelStore,
  showSyncButton,
  showResyncButton
};
