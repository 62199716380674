import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { QuicklinkModule, QuicklinkStrategy } from "ngx-quicklink";
import { AuthenticationGuard } from "./main/shared/guards/authentication.guard";
import { BatchListGuard } from "./main/shared/guards/batch-list.guard";
import { CustomTrackingRedirectGuard } from "./main/shared/guards/custom-tracking-redirect.guard";
import { DashboardGuard } from "./main/shared/guards/dashboard.guard";
import { LoginGuard } from "./main/shared/guards/login.guard";
import { OAuthGuard } from "./main/shared/guards/o-auth.guard";
import { OnboardingGuard } from "./main/shared/guards/onboarding.guard";
import { OtpVerifyGuard } from "./main/shared/guards/otp-verify.guard";
import { VasGuard } from "./main/shared/guards/vas.guard";
import { ActuatorHealthComponent } from "./main/shared/components/actuator-health/actuator-health.component";

const routes: Routes = [
  {
    path: "",
    pathMatch: "full",
    redirectTo: "login"
  },
  {
    path: "login",
    canActivate: [LoginGuard],
    loadChildren: () =>
      import("./main/pages/login/login.module").then((m) => m.LoginModule)
  },
  {
    path: "register",
    canLoad: [DashboardGuard],
    canActivate: [DashboardGuard],
    loadChildren: () =>
      import("./main/pages/register/register.module").then(
        (m) => m.RegisterModule
      )
  },
  {
    path: "otp-verify",
    canLoad: [OtpVerifyGuard],
    canActivate: [OtpVerifyGuard],
    loadChildren: () =>
      import("./main/pages/otp-verify/otp-verify.module").then(
        (m) => m.OtpVerifyModule
      ),
    data: {
      preload: false
    }
  },
  {
    path: "forgot-password",
    canLoad: [DashboardGuard],
    canActivate: [DashboardGuard],
    loadChildren: () =>
      import("./main/pages/forgot-password/forgot-password.module").then(
        (m) => m.ForgotPasswordModule
      )
  },
  {
    path: "reset-password/:urlData",
    // canLoad: [DashboardGuard],
    // canActivate: [DashboardGuard],
    loadChildren: () =>
      import("./main/pages/reset-password/reset-password.module").then(
        (m) => m.ResetPasswordModule
      ),
    data: {
      preload: false
    }
  },
  {
    path: "actions",
    canLoad: [AuthenticationGuard],
    canActivateChild: [AuthenticationGuard],
    loadChildren: () =>
      import("./main/actions/actions.module").then((m) => m.ActionsModule)
  },
  {
    path: "orders",
    canLoad: [AuthenticationGuard],
    canActivateChild: [AuthenticationGuard],
    loadChildren: () =>
      import("./main/orders/orders.module").then((m) => m.OrdersModule)
  },
  {
    path: "reports",
    canLoad: [AuthenticationGuard],
    canActivateChild: [AuthenticationGuard],
    loadChildren: () =>
      import("./main/orders/orders.module").then((m) => m.OrdersModule)
  },
  {
    path: "transactions",
    canLoad: [AuthenticationGuard],
    canActivateChild: [AuthenticationGuard],
    loadChildren: () =>
      import("./main/transactions/transactions.module").then(
        (m) => m.TransactionsModule
      )
  },
  {
    path: "settings",
    canLoad: [AuthenticationGuard],
    canActivateChild: [AuthenticationGuard],
    loadChildren: () =>
      import("./main/settings/settings.module").then((m) => m.SettingsModule)
  },
  {
    path: "vas",
    canLoad: [AuthenticationGuard],
    canActivate: [VasGuard],
    canActivateChild: [AuthenticationGuard],
    loadChildren: () => import("./main/vas/vas.module").then((m) => m.VasModule)
  },
  {
    path: "batch-list",
    canLoad: [AuthenticationGuard],
    canActivateChild: [AuthenticationGuard, BatchListGuard],
    loadChildren: () =>
      import("./main/miscellaneous/batch-list/batch-list.module").then(
        (m) => m.BatchListModule
      )
  },
  {
    path: "o-auth",
    canLoad: [OAuthGuard],
    canActivateChild: [OAuthGuard],
    loadChildren: () =>
      import("./main/miscellaneous/o-auth/o-auth.module").then(
        (m) => m.OauthModule
      ),
    data: {
      preload: false
    }
  },
  {
    path: "api/track/:orderId/:awb",
    canActivate: [CustomTrackingRedirectGuard],
    loadChildren: () =>
      import("./main/pages/404/error-404.module").then((m) => m.Error404Module),
    data: {
      preload: false
    }
  },
  {
    path: "pages",
    loadChildren: () =>
      import("./main/pages/pages.module").then((m) => m.PagesModule),
    data: {
      preload: false
    }
  },
  {
    path: "onboarding",
    canLoad: [OnboardingGuard],
    canActivateChild: [OnboardingGuard],
    loadChildren: () =>
      import("./main/miscellaneous/onboarding/onboarding.module").then(
        (m) => m.OnboardingModule
      ),
    data: {
      preload: false
    }
  },
  {
    path: "actuator/health",
    component: ActuatorHealthComponent
  },
  {
    path: "**",
    loadChildren: () =>
      import("./main/pages/404/error-404.module").then((m) => m.Error404Module)
  }
];

@NgModule({
  imports: [
    QuicklinkModule,
    RouterModule.forRoot(routes, {
      preloadingStrategy: QuicklinkStrategy,
      relativeLinkResolution: "legacy"
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
