import { NgModule } from "@angular/core";
import { FuseSharedModule } from "@fuse/shared.module";
import { ErrorMessageModule } from "../../modules/error-message/error-message.module";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { CreateTicketComponent } from "./component/create-ticket.component";
import { DirectivesModule } from "../../directives/directives.module";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatSelectModule } from "@angular/material/select";

@NgModule({
  declarations: [CreateTicketComponent],
  imports: [
    // fuse
    FuseSharedModule,

    // shared
    ErrorMessageModule,
    DirectivesModule,

    // material
    MatToolbarModule,
    MatSelectModule,
    MatButtonModule,
    MatIconModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule
  ]
})
export class CreateTicketModule {}
