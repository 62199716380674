import { NgModule } from "@angular/core";
import { ViewBroadcastComponent } from "./component/view-broadcast.component";
import { FuseSharedModule } from "@fuse/shared.module";
import { MatDialogModule } from "@angular/material/dialog";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatToolbarModule } from "@angular/material/toolbar";
import { QuillModule } from "ngx-quill";

@NgModule({
  declarations: [ViewBroadcastComponent],
  imports: [
    FuseSharedModule,
    QuillModule,
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    MatToolbarModule
  ],
  exports: [ViewBroadcastComponent]
})
export class ViewBroadcastModule {}
