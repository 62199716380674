import { HttpClient } from "@angular/common/http";
import { Component, Inject, ViewEncapsulation } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { FullScreenLoaderService } from "app/main/shared/modules/full-screen-loader/full-screen-loader.service";
import { NotificationService } from "app/main/shared/services/notification.service";

@Component({
  selector: "app-query-modal",
  templateUrl: "./query-modal.component.html",
  styleUrls: [
    "./query-modal.component.scss",
    "./../../../scss/responsive-modals.scss"
  ],
  encapsulation: ViewEncapsulation.None
})
export class QueryModalComponent {
  constructor(
    public _dialogRef: MatDialogRef<QueryModalComponent>,
    private _notification: NotificationService,
    private _http: HttpClient,
    private _loader: FullScreenLoaderService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  submit() {
    this._loader.show();

    this._http
      .post("user/order/ndrAction", {
        orderId: this.data.ids,
        option: "no",
        query: "User has rejected Weight Discrepancy"
      })
      .subscribe((res: any) => {
        this._loader.hide();
        if (res.success) {
          this._notification.show("Request submitted successfully.");
          this._dialogRef.close(true);
        } else {
          this._notification.show(res.msg);
        }
      });
  }
}
