import {
  Directive,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  Renderer2
} from "@angular/core";

@Directive({
  selector: "[ProgressStatus]"
})
export class ProgressStatusDirective implements OnInit, OnChanges {
  @Input() value;
  @Input() step;

  circularProgress(maxPercent, percent, colorBar, colorBack): any {
    const increment = 360 / maxPercent;
    const half = Math.floor(maxPercent / 2);
    let background = "";

    if (percent < half) {
      const next = 90 + increment * percent;
      const nextdeg = `${next}deg`;

      background = `linear-gradient(90deg, ${colorBack} 50%, transparent 50%, transparent),
      linear-gradient(${nextdeg}, ${colorBar} 50%, ${colorBack} 50%, ${colorBack})`;

      // linear-gradient(90deg, #E0E0E0 50%, transparent 50%, transparent), linear-gradient(180deg, #03A9F4 50%, #E0E0E0 50%, #E0E0E0)
    } else {
      const next = -90 + increment * (percent - half);
      const nextdeg = `${next}deg`;

      background = `linear-gradient(${nextdeg}, ${colorBar} 50%, transparent 50%, transparent),
        linear-gradient(270deg, ${colorBar} 50%, ${colorBack} 50%, ${colorBack})`;
    }
    return background;
  }

  constructor(private ele: ElementRef, private renderer: Renderer2) {}

  ngOnInit(): void {
    // this.ele.nativeElement.style.background = this.circularProgress(100, 50, '#03A9F4', '#E0E0E0');

    this.renderer.setStyle(
      this.ele.nativeElement,
      "background",
      this.circularProgress(100, this.value, "#C0C0C0", "#E0E0E0")
    );

    // console.log( new Date(), __filename, this.circularProgress(100, 60, '#03A9F4', '#E0E0E0'))
    // console.log( new Date(), __filename, this.ele.nativeElement.style.background);
    // console.log( new Date(), __filename, this.ele.nativeElement.style);
  }

  ngOnChanges(): void {
    if (this.step === 1) {
      this.renderer.setStyle(
        this.ele.nativeElement,
        "background",
        this.circularProgress(100, this.value, "#C0C0C0", "#E0E0E0")
      );
    } else if (this.step === 2) {
      this.renderer.setStyle(
        this.ele.nativeElement,
        "background",
        this.circularProgress(100, this.value, "#03A9F4", "#E0E0E0")
      );
    } else {
      this.renderer.setStyle(
        this.ele.nativeElement,
        "background",
        this.circularProgress(100, this.value, "#388e3c", "#E0E0E0")
      );
    }
  }
}
