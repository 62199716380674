import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { NotificationService } from "./notification.service";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class BoxesDataService {
  boxes: BehaviorSubject<any> = new BehaviorSubject([]);

  constructor(
    private _http: HttpClient,
    private _notification: NotificationService
  ) {}

  addBox = (body) => this._http.post("user/config/createBox", body);
  deleteBox = (body) => this._http.post("user/config/deleteBox", body);

  getBoxes(showNotification: boolean = true) {
    this._http.get("user/config/getBoxes").subscribe(
      (res: any) => {
        if (res.success) {
          this.boxes.next(res.boxes);
        } else {
          this.boxes.next([]);
          if (showNotification) {
            this._notification.show(res.msg);
          }
        }
      },
      (err) => {
        this.boxes.next([]);
        throw err;
      }
    );
  }
}
