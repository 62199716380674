import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FuseConfirmDialogModule } from "@fuse/components/confirm-dialog/confirm-dialog.module";
import { ToolbarHeadingModule } from "./components/toolbar-heading/toolbar-heading.module";
import { AddInstructionModule } from "./modals/add-instruction/add-instruction.module";
import { AddWarehouseModule } from "./modals/add-warehouse/add-warehouse.module";
import { CreateTicketModule } from "./modals/create-ticket/create-ticket.module";
import { DisputeDiscrepancyModule } from "./modals/dispute-discrepancy-modal/dispute-discrepancy.module";
import { DateRangeModule } from "./modals/date-range/date-range.module";
import { NdrReattemptDetailsModule } from "./modals/ndr-reattempt-details/ndr-reattempt-details.module";
import { QueryModalModule } from "./modals/query-modal/query-modal.module";
import { RequestFeedbackModule } from "./modals/request-feedback/request-feedback.module";
import { ShowHistoryForWeightDiscrepancyModule } from "./modals/show-history-for-weight-dicrepancy/show-history-for-weight-discrepancy.module";
import { ShowMessageDetailsModule } from "./modals/show-message-details/show-message-details.module";
import { SingleOrderDetailsModule } from "./modals/single-order-details/single-order-details.module";
import { SupportedBrowserListModule } from "./modals/supported-browser-list/supported-browser-list.module";
import { TrackSingleOrderModule } from "./modals/track-single-order/track-single-order.module";
import { ViewBroadcastModule } from "./modals/view-broadcast/view-broadcast.module";
import { AcceptDiscrepancyModule } from "./modals/accept-discrepancy/accept-discrepancy/accept-discrepancy.module";
import { ViewImageModule } from "./modals/view-image-modal/view-image/view-image.module";
import { DownloadWeightDiscrepancyOrdersModule } from "./modals/download-weight-discrepancy-orders/download-weight-discrepancy-orders.module";
import { DateRangeModalModule } from "./modals/date-range-modal/date-range-modal.module";
import { NdrReattemptDeliveryModule } from "./modals/ndr-reattempt-delivery/ndr-reattempt-delivery.module";
import { NdrLogModule } from "./modals/ndr-log/ndr-log.module";
import { NdrNotificationLogModule } from "./modals/ndr-notification-log/ndr-notification-log.module";
import { TrackingRemarkModule } from "./modals/tracking-remark/tracking-remark.module";
import { ConfirmRtoRequestModule } from "./modals/confirm-rto-request/confirm-rto-request.module";
import { DownloadNDROrdersModule } from "./modals/download-ndr-orders/download-ndr-orders.module";
import { ItemsModalForWdModule } from "./modals/items-modal-for-wd/items-modal-for-wd.module";
import { B2bBoxModule } from "./modals/b2b-box/b2b-box.module";
import { AddDetailsForBoxModule } from "./modals/add-details-for-box/add-details-for-box.module";
import { BoxDetailsTableModule } from "./modals/add-details-for-box/add-details-for-box/child-component/box-details-table/box-details-table.module";
import { ReuseableBoxDetailsTableModule } from "./modals/reuseable-box-details-table/reuseable-box-details-table.module";
import { AppointmentDeliveryNotificationModule } from "./modals/appointment-delivery-notification/appointment-delivery-notification.module";
import { OrderLogModule } from "./modals/order-log/order-log.module";
import { ActionConfirmationModule } from "./modals/action-confirmation/action-confirmation.module";
import { KycNotificationModule } from "./modals/kyc-notification/kyc-notification.module";
import { CustomAlertModule } from "./modals/custom-alert/custom-alert.module";
import { ActuatorHealthComponent } from './components/actuator-health/actuator-health.component';

@NgModule({
  declarations: [
    ActuatorHealthComponent
  ],
  imports: [
    CommonModule,
    // fuse
    FuseConfirmDialogModule,
    TrackSingleOrderModule,
    SingleOrderDetailsModule,
    RequestFeedbackModule,
    ToolbarHeadingModule,
    CreateTicketModule,
    AddInstructionModule,
    QueryModalModule,
    SupportedBrowserListModule,
    ShowMessageDetailsModule,
    AddWarehouseModule,
    NdrReattemptDetailsModule,
    ViewBroadcastModule,
    DisputeDiscrepancyModule,
    ShowHistoryForWeightDiscrepancyModule,
    AcceptDiscrepancyModule,
    ViewImageModule,
    DateRangeModule,
    DownloadWeightDiscrepancyOrdersModule,
    DateRangeModalModule,
    NdrReattemptDeliveryModule,
    NdrLogModule,
    NdrNotificationLogModule,
    TrackingRemarkModule,
    ConfirmRtoRequestModule,
    DownloadNDROrdersModule,
    ItemsModalForWdModule,
    B2bBoxModule,
    AddDetailsForBoxModule,
    BoxDetailsTableModule,
    ReuseableBoxDetailsTableModule,
    AppointmentDeliveryNotificationModule,
    OrderLogModule,
    ActionConfirmationModule,
    KycNotificationModule,
    CustomAlertModule
  ],
  exports: []
})
export class SharedModule {}
