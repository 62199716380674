import { Component, OnInit } from "@angular/core";
import { FuseConfigService } from "@fuse/services/config.service";

@Component({
  selector: "app-actuator-health",
  templateUrl: "./actuator-health.component.html",
  styleUrls: ["./actuator-health.component.scss"]
})
export class ActuatorHealthComponent implements OnInit {
  constructor(private _fuseConfigService: FuseConfigService) {
    this._fuseConfigService.config = {
      layout: {
        navbar: {
          hidden: true
        },
        toolbar: {
          hidden: true
        },
        footer: {
          hidden: true
        },
        sidepanel: {
          hidden: true
        }
      }
    };
  }

  ngOnInit(): void {}
}
