import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DownloadNDROrdersComponent } from "./download-NDR-orders/download-ndr-orders/download-ndr-orders.component";
import { MatButtonModule } from "@angular/material/button";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatIconModule } from "@angular/material/icon";
import { MatDialogModule } from "@angular/material/dialog";

@NgModule({
  declarations: [DownloadNDROrdersComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    MatToolbarModule,
    MatIconModule,
    MatDialogModule
  ]
})
export class DownloadNDROrdersModule {}
