<div fxLayout="column" class="my-12">
  <div>
    <div
      *ngIf="!!bulkResponse?.bookedCount; else unprocessed"
      fxLayout="column"
      fxLayoutAlign="start center"
    >
      <mat-icon class="green-fg status-icon">done</mat-icon>

      <h2 class="green-fg status-text">Orders Processed!</h2>

      <ng-container *ngTemplateOutlet="reBookMsg"></ng-container>
    </div>

    <ng-template #unprocessed>
      <div fxLayout="column" fxLayoutAlign="start center">
        <mat-icon class="warn-fg status-icon">close</mat-icon>

        <h2 class="warn-fg status-text">Order Processing Failed!</h2>

        <ng-container *ngTemplateOutlet="reBookMsg"></ng-container>
      </div>
    </ng-template>
  </div>

  <ng-template #reBookMsg>
    <p>
      To restart booking Unbooked/Unserviceable orders. Go to
      <a (click)="navigateToBatch()" class="cursor-pointer">batch</a>
      and Click on <span class="font-weight-600">Book this Batch</span> again.
    </p>
  </ng-template>

  <div fxLayout="column" class="mt-4">
    <!-- booked order info -->
    <div
      fxLayout="row"
      fxLayoutAlign="space-evenly center"
      fxLayout.lt-sm="column"
      class="mb-24 w-100-p"
    >
      <div>
        <h3>
          <span>Total Orders: </span>
          <span>{{ summaryTotalOrder }}</span>
        </h3>
        <h3>
          <span>Pickup Not Generated: </span>
          <span>{{ noPickUp?.length }}</span>
          <mat-icon
            color="primary"
            class="ml-8 cursor-pointer download-icons"
            *ngIf="noPickUp?.length"
            (click)="downloadCsv('noPickUp')"
            >get_app</mat-icon
          >
        </h3>
        <h3>
          <span>Total Shipping Charges: </span>
          <span>
            {{ totalShippingCharge | RupeePaise: "rupee" | currency: "INR" }}
          </span>
        </h3>
      </div>

      <div>
        <h3>
          <span>Orders Not Booked: </span>
          <span>{{ notBooked?.length }}</span>
          <mat-icon
            color="primary"
            class="ml-8 cursor-pointer download-icons"
            *ngIf="notBooked?.length"
            (click)="downloadCsv('notBooked')"
            >get_app</mat-icon
          >
        </h3>
        <h3>
          <span>Unserviceable Orders: </span>
          <span>{{ unservicable?.length }}</span>
          <mat-icon
            color="primary"
            class="ml-8 cursor-pointer download-icons"
            *ngIf="unservicable?.length"
            (click)="downloadCsv('unservicable')"
            >get_app</mat-icon
          >
        </h3>
        <h3>
          Total Weight Charged:
          <span>{{ totalWeight | weightConversionTo: "kg" }} Kg</span>
        </h3>
      </div>
    </div>

    <!-- download buttons -->
    <div
      fxFlex
      fxLayout="row"
      fxLayout.lt-sm="column"
      fxLayoutAlign="space-evenly center"
      fxLayoutGap="20px"
      class="w-100-p"
    >
      <button
        class="download-button"
        mat-flat-button
        color="primary"
        [disabled]="!bulkResponse?.bookedCount"
        (click)="downloadMedia('manifest')"
      >
        <span> Manifest </span>
        <mat-icon aria-hidden="false" aria-label="download">get_app</mat-icon>
      </button>

      <button
        class="download-button"
        mat-flat-button
        color="primary"
        [disabled]="!bulkResponse?.bookedCount"
        (click)="downloadMedia('label')"
      >
        <span> Label </span>
        <mat-icon aria-hidden="false" aria-label="download">get_app</mat-icon>
      </button>

      <button
        class="download-button"
        mat-flat-button
        color="primary"
        [disabled]="!bulkResponse?.bookedCount"
        (click)="downloadMedia('invoice')"
      >
        <span> Invoice </span>
        <mat-icon aria-hidden="false" aria-label="download">get_app</mat-icon>
      </button>
    </div>
  </div>
</div>
