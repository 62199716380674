import { Platform } from "@angular/cdk/platform";
import { DOCUMENT } from "@angular/common";
import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { FuseNavigationService } from "@fuse/components/navigation/navigation.service";
import { FuseSidebarService } from "@fuse/components/sidebar/sidebar.service";
import { FuseConfigService } from "@fuse/services/config.service";
import { FuseSplashScreenService } from "@fuse/services/splash-screen.service";
import { FuseTranslationLoaderService } from "@fuse/services/translation-loader.service";
import { TranslateService } from "@ngx-translate/core";
import { locale as navigationEnglish } from "app/navigation/i18n/en";
import { locale as navigationTurkish } from "app/navigation/i18n/tr";
import { navigation } from "app/navigation/navigation";
import { environment } from "environments/environment";
import * as _ from "lodash";
import * as moment from "moment";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "app",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent implements OnInit, OnDestroy {
  fuseConfig: any;
  navigation: any;

  // Private
  private _unsubscribeAll: Subject<any>;

  constructor(
    @Inject(DOCUMENT) private document: any,
    private _fuseConfigService: FuseConfigService,
    private _fuseNavigationService: FuseNavigationService,
    private _fuseSidebarService: FuseSidebarService,
    // Initialize _fuseSplashScreenService
    private _fuseSplashScreenService: FuseSplashScreenService,
    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    private _translateService: TranslateService,
    private _platform: Platform,
    private _matIconRegistry: MatIconRegistry,
    private _domSanitizer: DomSanitizer
  ) {
    // Get default navigation
    this.navigation = _.cloneDeep(navigation);

    // Register the navigation to the service
    this._fuseNavigationService.register("main", this.navigation);

    // Set the main navigation as our current navigation
    this._fuseNavigationService.setCurrentNavigation("main");

    // Add languages
    this._translateService.addLangs(["en", "tr"]);

    // Set the default language
    this._translateService.setDefaultLang("en");

    // Set the navigation translations
    this._fuseTranslationLoaderService.loadTranslations(
      navigationEnglish,
      navigationTurkish
    );

    // Use a language
    this._translateService.use("en");

    // Add is-mobile class to the body if the platform is mobile
    if (this._platform.ANDROID || this._platform.IOS) {
      this.document.body.classList.add("is-mobile");
    }

    // Set the private defaults
    this._unsubscribeAll = new Subject();

    this.loadSvgIcons();
  }

  loadSvgIcons(): void {
    const logos = [
      "calculator-alt",
      "unicommerce",
      "magento1",
      "magento2",
      "ESEC",
      "shopify",
      "woocommerce",
      "instamojo",
      "zoho",
      "clickpost",
      "custom-whatsapp"
    ];
    try {
      // Adding custom SVG icons to App

      logos.forEach((logo) => {
        this._matIconRegistry.addSvgIcon(
          logo,
          this._domSanitizer.bypassSecurityTrustResourceUrl(
            `assets/icons/svg/${logo}.svg`
          )
        );
      });
    } catch (error) {
      // ignore if error loading icons
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // setiing moment locale
    moment.locale("en-in");
    // to show app environment config
    const { mode, appVersion } = environment;
    if (mode !== "production") console.log("App config:", environment);
    console.log("App version:", appVersion);

    // Subscribe to config changes
    this._fuseConfigService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((config) => {
        this.fuseConfig = config;

        // Boxed
        if (this.fuseConfig.layout.width === "boxed") {
          this.document.body.classList.add("boxed");
        } else {
          this.document.body.classList.remove("boxed");
        }

        // Color theme - Use normal for loop for IE11 compatibility
        for (let i = 0; i < this.document.body.classList.length; i++) {
          const className = this.document.body.classList[i];

          if (className.startsWith("theme-")) {
            this.document.body.classList.remove(className);
          }
        }

        this.document.body.classList.add(this.fuseConfig.colorTheme);
      });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Toggle sidebar open
   *
   * @param key
   */
  toggleSidebarOpen(key): void {
    this._fuseSidebarService.getSidebar(key).toggleOpen();
  }
}
