<ng-container *ngIf="formControlErr?.errors?.required">
  {{ fieldName ? fieldName + " is required." : "This field is required." }}
</ng-container>

<ng-container
  *ngIf="
    !formControlErr?.errors?.minlength &&
    !formControlErr?.errors?.maxlength &&
    formControlErr?.errors?.pattern
  "
>
  {{
    patternMsg
      ? patternMsg
      : fieldName
      ? "Please enter a valid " + fieldName + "."
      : "Invalid value."
  }}
</ng-container>

<ng-container *ngIf="formControlErr?.errors?.minlength">
  {{
    minLengthMsg
      ? minLengthMsg
      : "Minimum " +
        formControlErr?.errors?.minlength?.requiredLength +
        " characters."
  }}
</ng-container>

<ng-container *ngIf="formControlErr?.errors?.maxlength">
  {{
    maxLengthMsg
      ? maxLengthMsg
      : "Maximum " +
        formControlErr?.errors?.maxlength?.requiredLength +
        " characters."
  }}
</ng-container>

<ng-container *ngIf="formControlErr?.errors?.min">
  {{ fieldName ? fieldName : "It" }} should be greater than {{ minValue }}.
</ng-container>

<ng-container *ngIf="formControlErr?.errors?.max">
  {{ fieldName ? fieldName : "It" }} couldn't be greater than
  {{ maxValue || formControlErr?.errors?.max?.max }}.
</ng-container>
