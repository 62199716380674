import { Component, ViewEncapsulation } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "fuse-confirm-dialog",
  templateUrl: "./confirm-dialog.component.html",
  styleUrls: [
    "./confirm-dialog.component.scss",
    "./../../../app/main/shared/scss/responsive-modals.scss"
  ],
  encapsulation: ViewEncapsulation.None
})
export class FuseConfirmDialogComponent {
  public confirmTitle: string;
  public confirmMessage: string;
  public confirmSummary: string;
  public confirmButtonText: string = "Confirm";
  public cancelButtonText: string = "Cancel";

  /**
   * Constructor
   *
   * @param {MatDialogRef<FuseConfirmDialogComponent>} dialogRef
   */
  constructor(public dialogRef: MatDialogRef<FuseConfirmDialogComponent>) {}
}
