import { Injectable } from "@angular/core";
import * as AES from "crypto-js/aes";
import * as enc from "crypto-js/enc-utf8";

@Injectable({
  providedIn: "root"
})
export class EncryptionService {
  private salt = "AN0tH3rr@nd0mV@IU3";

  constructor() {}

  encrypt(value: string): string {
    const encrypted = AES.encrypt(value.toString(), this.salt);
    return encrypted;
  }

  decrypt(value: string): string {
    const decrypted = AES.decrypt(value, this.salt).toString(enc);
    return decrypted;
  }
}
