import { Pipe, PipeTransform } from "@angular/core";
import { roundToTwoDecimals } from "./../ts/utilityFn";

@Pipe({
  name: "weightConversionTo"
})
export class WeightConversionPipe implements PipeTransform {
  transform(value: number | string, convertTo: "kg" | "gram"): number {
    if (value) {
      const weight = Number(value);

      if (convertTo === "kg") {
        return roundToTwoDecimals(weight / 1000);
      } else if (convertTo === "gram") {
        return roundToTwoDecimals(weight * 1000);
      }
    }

    return 0;
  }
}
