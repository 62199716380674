import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { NotificationService } from "./notification.service";

@Injectable({
  providedIn: "root"
})
export class RatesService {
  // declarations
  rates: BehaviorSubject<any> = new BehaviorSubject([]);

  constructor(
    private _http: HttpClient,
    private _notification: NotificationService
  ) {}

  getRates(): void {
    this._http.get("user/config/rates").subscribe(
      (res: any) => {
        if (res.success) {
          const { b2bRates = [], rates = [] } = res;
          this.rates.next([...b2bRates, ...rates]);
        } else {
          this.rates.next([]);
          this._notification.show(res.msg);
        }
      },
      (error) => {
        this.rates.next([]);
        throw error;
      }
    );
  }
}
