import { HttpClient } from "@angular/common/http";
import { Component, Inject, OnInit, ViewEncapsulation } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { NotificationService } from "app/main/shared/services/notification.service";
import { TenantService } from "app/main/shared/services/tenant.service";
import { OrderActionService } from "app/main/shared/services/order-action.service";

@Component({
  selector: "app-appointment-delivery-notification",
  templateUrl: "./appointment-delivery-notification.component.html",
  styleUrls: [
    "./appointment-delivery-notification.component.scss",
    "./../../../scss/responsive-modals.scss"
  ],
  encapsulation: ViewEncapsulation.None
})
export class AppointmentDeliveryNotificationComponent implements OnInit {
  order: any;
  loadingIndicator = true;
  tenantConfig = this._tenantService.getTenantConfig();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _http: HttpClient,
    public _notification: NotificationService,
    private _tenantService: TenantService,
    private _orderActionService: OrderActionService
  ) {}

  ngOnInit(): void {
    if (this.data?.orderId) {
      this.getOrder({ orderId: this.data?.orderId });
    }
  }

  getOrder(body): void {
    this._http.post("user/order/singleOrder", body).subscribe((res: any) => {
      if (res.success) {
        this.loadingIndicator = false;
        this.order = res.orders;
        // console.log(this.order);
      } else {
        this._notification.show(res.msg);
      }
    });
  }

  takeAction(): void {
    this._orderActionService.createTicket(this.order, "order-actions");
  }
}
