import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { JwtHelperService } from "@auth0/angular-jwt";
import { FuseConfirmDialogComponent } from "@fuse/components/confirm-dialog/confirm-dialog.component";
import { FuseNavigationService } from "@fuse/components/navigation/navigation.service";
import * as Sentry from "@sentry/angular";
import { ToolbarNotificationService } from "app/main/ui/toolbar-notification.service";
import { BoxesDataService } from "./boxes-data.service";
import { BrowserStorageService } from "./browser-storage.service";
import { ClientAccountsService } from "./client-accounts.service";
import { CourierService } from "./courier.service";
import { ProfileDataService } from "./profile-data.service";
import { RatesService } from "./rates.service";
import { WarehouseService } from "./warehouse.service";
import { environment } from "environments/environment";
import { NdrService } from "app/main/orders/ndr/ndr.service";
import { BannerMessageService } from "../modules/banner-message/banner-message.service";

const authTokenKey = "shippiGoToken";
const remeberMeKey = "remeberMe";
@Injectable({
  providedIn: "root"
})
export class LoginService {
  private jwtHelper = new JwtHelperService();
  confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;

  constructor(
    private _router: Router,
    private _profileDataService: ProfileDataService,
    private _warehouseService: WarehouseService,
    private _boxesDataService: BoxesDataService,
    private _courierService: CourierService,
    private _fuseNavigationService: FuseNavigationService,
    private _dialog: MatDialog,
    private _clientAccounts: ClientAccountsService,
    private _toolbarNotification: ToolbarNotificationService,
    private _browserStorage: BrowserStorageService,
    private _ratesService: RatesService,
    private _bannerMessageService: BannerMessageService,
    private _ndrService: NdrService,
    private _http: HttpClient
  ) {}

  get isRememberMeChecked(): boolean {
    const remeberMeSS = this._browserStorage.getItemFromSS(remeberMeKey);
    const remeberMeLS = this._browserStorage.getItemFromLS(remeberMeKey);

    if (remeberMeSS === "no" || remeberMeLS === "no") return false;

    return true;
  }

  setRememberMeFlag(remeberMe: boolean): void {
    const remeberMeOption = remeberMe ? "yes" : "no";

    this._browserStorage.setItemInLS(remeberMeKey, remeberMeOption);
    this._browserStorage.setItemInSS(remeberMeKey, remeberMeOption);
  }

  setToken(token: string, remeberMe: boolean = this.isRememberMeChecked): void {
    this._browserStorage.use(remeberMe).setItem(authTokenKey, token);
  }

  getToken(): string {
    const authTokenInSS = this._browserStorage.getItemFromSS(authTokenKey);
    const authTokenInLS = this._browserStorage.getItemFromLS(authTokenKey);

    if (authTokenInSS) return authTokenInSS;
    if (authTokenInLS) return authTokenInLS;

    return "";
  }

  clearToken(): void {
    this._browserStorage.removeItem(authTokenKey);
  }

  googleLogout(): void {
    const httpOptions = {
      credentials: "include",
      headers: new HttpHeaders({
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": "true"
      }),
      withCredentials: true
    };
    this._http.get("user/registration/logout", httpOptions).subscribe();
  }

  logout(): void {
    const { socialLogin } = this.getUser();
    if (socialLogin === "google") {
      this.googleLogout();
    }

    this.clearToken();

    this._router.navigateByUrl("/login");

    // close all opened modals
    this._dialog.closeAll();

    setTimeout(() => {
      this.clearObservables();
    }, 500);

    // clear data stored in local storage
    this.clearSensitiveData();

    // clearing user fingerprint generated for sentry
    this.clearSentryScope();
  }

  goToSwiggyAuth() {
    this._router.navigateByUrl(
      "onboarding/kyc?userToken=dummy&type=unauthorised"
    );
  }

  clearSensitiveData() {
    const localStorageData = ["customFilters", "pendingBookBulk"];

    for (const key of localStorageData) {
      this._browserStorage.removeItem(key);
    }
  }

  clearObservables(): void {
    // clearing profile, platforms and integrations
    this._profileDataService.profileData.next({});
    this._profileDataService.platforms.next([]);
    this._profileDataService._integrations.next({});

    // clearing user and api permissions
    this._profileDataService._apiPermissions.next({});
    this._profileDataService._userPermissions.next({});
    this._profileDataService._orderPermissions.next({});

    // clearing warehouses
    this._warehouseService.shippigoWarehouses.next([]);
    this._warehouseService.shopifyWarehouses.next([]);
    this._warehouseService.magento2Warehouses.next([]);

    // clearing rates
    this._ratesService.rates.next([]);

    // clearing couriers
    this._courierService.couriers.next([]);

    // clearing clients
    this._clientAccounts.clients.next([]);

    //clearing boxes
    this._boxesDataService.boxes.next([]);

    //clearing tollbar notifications
    this._toolbarNotification.clear();

    //clear cards data
    this._ndrService.cardOrders.next({});

    this._bannerMessageService.showBanner.next(false);
  }

  clearNavigation(): void {
    const itemsToClear = [
      "vas",
      "customer-response",
      "custom-batch",
      "shopify-batch",
      "woocommerce-batch",
      "magento1-batch",
      "magento2-batch",
      "instamojo-batch",
      "zoho-batch",
      "channels",
      "developer",
      "support",
      "manual"
    ];

    for (const item of itemsToClear) {
      this._fuseNavigationService.removeNavigationItem(item);
    }
  }

  clearSentryScope(): void {
    Sentry.configureScope((scope) => scope.setUser(null));
  }

  checkIfSubuser(): boolean {
    const user = this.getUser();
    return user?.subUser || false;
  }

  getUser(): any {
    const user = this.jwtHelper.decodeToken(this.getToken());
    return user || {};
  }

  loginWithGoogle(): void {
    const { apiEndPoint } = environment;
    window.location.href = `${apiEndPoint}user/registration/google`;
  }

  handleSamlLogin(
    confirmAction: () => void = () => {},
    cancelAction: () => void = () => {}
  ) {
    this.confirmDialogRef = this._dialog.open(FuseConfirmDialogComponent, {
      panelClass: "small-modal"
    });

    this.confirmDialogRef.componentInstance.confirmTitle = "Saml Login";
    this.confirmDialogRef.componentInstance.confirmMessage =
      "You will be redirected to Saml Portal.";

    this.confirmDialogRef.componentInstance.confirmButtonText = "Confirm";
    this.confirmDialogRef.componentInstance.cancelButtonText = "Cancel";

    this.confirmDialogRef.afterClosed().subscribe((result) => {
      if (result) {
        confirmAction();
      } else {
        cancelAction();
      }
      this.confirmDialogRef = null;
    });
  }

  isUserLoggedIn(): boolean {
    const token = this.getToken();
    return !!token;
  }

  isOtpVerifyState(): boolean {
    return this._browserStorage.isAvailableInLS("verification");
  }

  get isMultipleAccount(): boolean {
    const user = this.getUser();
    return user?.multipleAccount || false;
  }
}
