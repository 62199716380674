// @ts-nocheck
import { shippigoConfig } from "tenants/shippigo/config";

export const environment = {
  production: true,
  apiEndPoint: "https://shippigo.emizainc.com/",
  mode: "production",
  logErr: true,
  consoleErr: false,
  appVersion: require("../../package.json").version,
  tenant: shippigoConfig,
};

environment.tenant.deliveryManagerUrl =
  "https://tracking-page.shippigo.com/delivery-manager";
