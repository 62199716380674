import { NgModule } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { MatIconModule } from "@angular/material/icon";
import { MatToolbarModule } from "@angular/material/toolbar";

import { FuseConfirmDialogComponent } from "@fuse/components/confirm-dialog/confirm-dialog.component";
import { FuseSharedModule } from "@fuse/shared.module";

@NgModule({
    declarations: [FuseConfirmDialogComponent],
    imports: [
        FuseSharedModule,
        MatDialogModule,
        MatButtonModule,
        MatIconModule,
        MatToolbarModule
    ]
})
export class FuseConfirmDialogModule {}
