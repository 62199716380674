import { HttpClient } from "@angular/common/http";
import { Component, Inject, ViewEncapsulation } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { FullScreenLoaderService } from "app/main/shared/modules/full-screen-loader/full-screen-loader.service";
import { NotificationService } from "app/main/shared/services/notification.service";

@Component({
  selector: "app-add-instruction",
  templateUrl: "./add-instruction.component.html",
  styleUrls: [
    "./add-instruction.component.scss",
    "./../../../scss/responsive-modals.scss"
  ],
  encapsulation: ViewEncapsulation.None
})
export class AddInstructionComponent {
  constructor(
    public _dialogRef: MatDialogRef<AddInstructionComponent>,
    private _notification: NotificationService,
    private _http: HttpClient,
    private _loader: FullScreenLoaderService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}
  instruction = new FormControl("", [Validators.required]);

  submit() {
    this._loader.show();

    this._http
      .post("user/order/ndrAction", {
        option: "RAD",
        awb: this.data.awb,
        comments: this.instruction.value
      })
      .subscribe((res: any) => {
        this._loader.hide();
        if (res.success) {
          this._notification.show("Request submitted successfully.");
          this._dialogRef.close(true);
        } else {
          this._notification.show(res.msg);
        }
      });
  }
}
