import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { NotificationService } from "./notification.service";

@Injectable({
  providedIn: "root"
})
export class ClientAccountsService {
  clients: BehaviorSubject<any> = new BehaviorSubject([]);

  constructor(
    private _http: HttpClient,
    private _notification: NotificationService
  ) {}

  getClients() {
    this._http.get("user/registration/fetchAccounts").subscribe(
      (res: any) => {
        if (res.success) {
          this.clients.next(res.accountDetails?.accounts);
        } else {
          this.clients.next([]);
          this._notification.show(res.msg);
        }
      },
      (err) => {
        this.clients.next([]);
        throw err;
      }
    );
  }

  switchAccounts(
    { SGID, token }: { SGID: string; token: string },
    passAction: (token: string) => void = () => {},
    failAction: () => void = () => {}
  ) {
    const body = { SGID };
    const headers = {
      "x-access-token": token
    };

    this._http
      .post("user/registration/changeAccount", body, { headers })
      .subscribe(
        (res: any) => {
          if (res.success) {
            passAction(res.token);
          } else {
            if (failAction) failAction();
            this._notification.show(res.msg);
          }
        },
        (err) => {
          if (failAction) failAction();
          throw err;
        }
      );
  }
}
