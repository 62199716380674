import { NgModule } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatToolbarModule } from "@angular/material/toolbar";
import { FuseSharedModule } from "@fuse/shared.module";
import { ErrorMessageModule } from "../../modules/error-message/error-message.module";
import { TrackSingleOrderComponent } from "./track-single-order.component";
import { PipesModule } from "../../pipes/pipes.module";
import { TransitDaysModule } from "../../pipes/transit-days/transit-days.module";

@NgModule({
    declarations: [TrackSingleOrderComponent],
    imports: [
        // fuse
        FuseSharedModule,
        // shared
        ErrorMessageModule,
        // pipes
        PipesModule,
        TransitDaysModule,
        // material
        MatIconModule,
        MatProgressSpinnerModule,
        MatToolbarModule,
        MatInputModule,
        MatButtonModule,
        MatDialogModule,
        MatProgressBarModule
    ]
})
export class TrackSingleOrderModule {}
