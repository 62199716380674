import { Injectable } from "@angular/core";
import { DataForCsvDownload } from "../interfaces/data-for-csv-download";
import { OrderSchemaForCsv } from "../interfaces/order-schema-for-csv";
import {
  orderSchema,
  transactionSchema,
  skuSchema
} from "./../ts/csvDownloadSchema";
import { DownloadCsvService } from "./download-csv.service";

@Injectable({
  providedIn: "root"
})
export class GenerateCsvService {
  constructor(public _downloadCSVService: DownloadCsvService) {}

  forOrders(data: DataForCsvDownload): any {
    const { path, orders, fileName, csvForReports } = data;
    let orderData: any[] = [];
    let columns: string[] = [];

    if (path === "reverse") {
      columns = [
        "SG_ORDERID",
        "createdAt",
        "orderNumber",
        "channel",
        "orderDate",
        "oldAwb",
        "newAwb",
        "courier_service",
        "warehouse",
        "fullName",
        "phone",
        "orderTotal",
        "paymentType",
        "codTotal",
        "destination",
        "zone",
        "status",
        "dispatchDate",
        "pickupReferenceNo",
        "pickupTime",
        "transitDays"
      ];

      orderData = orders.map((order) => {
        return this.transformOrder(columns, orderSchema, order);
      });
    } else if (path === "weight-discrepancy") {
      columns = [
        "SG_ORDERID",
        "orderNumber",
        "channel",
        "createdAt",
        "orderDate",
        "oldAwb",
        "newAwb",
        "courier_service",
        "warehouse",
        "fullName",
        "email",
        "phone",
        "orderTotal",
        "paymentType",
        "weight",
        "chargedWeight",
        "volWeight",
        "length",
        "breadth",
        "height",
        "codTotal",
        "destination",
        "zone",
        "status",
        "weightDescCreated",
        "autoDeductOn",
        "acceptedOn",
        "weightDescOldWeight",
        "weightDescOldCharge",
        "weightDescNewWeight",
        "weightDescNewCharge",
        "weightDescStatus",
        "transitDays"
      ];

      orderData = orders.map((order) => {
        return this.transformOrder(columns, orderSchema, order);
      });
    } else if (csvForReports && ["pending", "closed"].includes(path)) {
      columns = [
        "SG_ORDERID",
        "createdAt",
        "orderNumber",
        "channel",
        "orderDate",
        "awb",
        "courier_service",
        "warehouse",
        "fullName",
        "phone",
        "orderTotal",
        "paymentType",
        "codTotal",
        "destination",
        "zone",
        "status",
        "ndrReason",
        "dispatchDate",
        "pickupReferenceNo",
        "pickupTime"
      ];

      orderData = orders.map((order) => {
        return this.transformOrder(columns, orderSchema, order);
      });
    } else if (path === "flagged") {
      columns = [
        "SG_ORDERID",
        "createdAt",
        "orderDate",
        "orderNumber",
        "channel",
        "paymentType",
        "warehouse",
        "fullName",
        "orderTotal",
        "codTotal",
        "awb",
        "courier_service",
        "statusFlaggedOrder",
        "flaggedReason",
        "destination",
        "zone",
        "dispatchDate",
        "transitDays"
      ];

      orderData = orders.map((order) => {
        return this.transformOrder(columns, orderSchema, order);
      });
    } else if (path === "summary") {
      columns = [
        "orderNumber",
        "createdAt",
        "description",
        "awb",
        "courier_service",
        "referenceNumber",
        "refundReason",
        "credit",
        "debit",
        "balance"
      ];

      orderData = orders.map((order) => {
        return this.transformOrder(columns, transactionSchema, order);
      });
    } else if (path === "codremit") {
      columns = [
        "SG_ORDERID",
        "remitDeliveryDate",
        "remittanceDate",
        "orderNumber",
        "remitCodAmount",
        "awb",
        "remitOrderStatus",
        "remittedAmount",
        "remitBankUTR"
      ];

      orderData = orders.map((order) => {
        return this.transformOrder(columns, orderSchema, order);
      });
    } else if (["request", "feedback", "customer-response"].includes(path)) {
      columns = [
        "SG_ORDERID",
        "orderNumber",
        "channel",
        "createdAt",
        "orderDate",
        "awb",
        "courier_service",
        "fullName",
        "orderTotal",
        "paymentType",
        "codTotal",
        "responseCsvDestination",
        "status",
        "question",
        "response",
        "responseDate",
        "dispatchDate"
      ];

      orders.forEach((order) => {
        order?.responses.forEach((response, index: number) => {
          if (!!index) {
            order = {};
          }

          const row = this.transformOrder(
            columns,
            orderSchema,
            order,
            response
          );
          orderData.push(row);
        });
      });
    } else {
      columns = [
        "SG_ORDERID",
        "createdAt",
        "channel",
        "orderNumber",
        "orderDate",
        "awb",
        "courier_service",
        "warehouse",
        "fullName",
        "email",
        "phone",
        "addressLine1",
        "addressLine2",
        "origin_city",
        "origin_state",
        "origin_pincode",
        "destination_city",
        "destination_state",
        "destination_pincode",
        "zone",
        "country",
        "itemName",
        "sku",
        "itemPrice",
        "quantity",
        "totalAmount",
        "orderTotal",
        "paymentType",
        "weight",
        "chargedWeight",
        "volWeight",
        "shippingCharge",
        "length",
        "breadth",
        "height",
        "codTotal",
        "destination",
        "status",
        "deliveredOn",
        "trackingLink",
        "dispatchDate",
        "pickupReferenceNo",
        "pickupTime",
        "transitDays"
      ];

      orders.forEach((order) => {
        order?.items.forEach((item, index: number) => {
          if (!!index) {
            order = {};
          }
          const row = this.transformOrder(columns, orderSchema, order, item);

          orderData.push(row);
        });
      });
    }
    this._downloadCSVService.downloadCSV(orderData, fileName);
  }

  forBulkProcessed(data: DataForCsvDownload): any {
    const { orders, fileName } = data;
    let orderData: any[] = [];
    const columns = [
      "firstName",
      "lastName",
      "email",
      "phone",
      "addressLine1",
      "addressLine2",
      "city",
      "state",
      "pincode",
      "country",
      "orderNumber",
      "bulkOrderDate",
      "bulkItemName",
      "bulkSku",
      "bulkItemPrice",
      "bulkQuantity",
      "paymentType",
      "currencyType",
      "weight",
      "length",
      "breadth",
      "height",
      "codTotal",
      "orderTotal"
    ];

    orderData = orders.map((order) => {
      return this.transformOrder(columns, orderSchema, order);
    });

    this._downloadCSVService.downloadCSV(orderData, fileName);
  }

  forPackingList(data: DataForCsvDownload): any {
    const { orders, fileName } = data;
    const orderData: any[] = [];
    const columns = [
      "SG_ORDERID",
      "createdAt",
      "orderNumber",
      "orderDate",
      "awb",
      "courier_service",
      "warehouse",
      "fullName",
      "email",
      "phone",
      "addressLine1",
      "addressLine2",
      "city",
      "state",
      "pincode",
      "zone",
      "country",
      "itemName",
      "sku",
      "itemPrice",
      "quantity",
      "totalAmount",
      "orderTotal",
      "paymentType",
      "weight",
      "chargedWeight",
      "volWeight",
      "length",
      "breadth",
      "height",
      "codTotal",
      "destination",
      "status",
      "deliveredOn",
      "trackingLink",
      "transitDays"
    ];

    orders.forEach((order) => {
      order?.items.forEach((item, index: number) => {
        if (!!index) {
          order = {};
        }

        const row = this.transformOrder(columns, orderSchema, order, item);
        orderData.push(row);
      });
    });

    this._downloadCSVService.downloadCSV(orderData, fileName);
  }

  forSku(data: DataForCsvDownload): any {
    const { orders: skuList, fileName } = data;
    const skuData: any[] = [];
    const columns = [
      "sku",
      "productName",
      "productDescription",
      "length",
      "breadth",
      "height",
      "weight",
      "variantIdName",
      "variantUnitPrice"
    ];

    skuList.forEach((sku) => {
      const row = this.transformOrder(columns, skuSchema, sku);
      skuData.push(row);
    });

    this._downloadCSVService.downloadCSV(skuData, fileName);
  }

  transformOrder(
    columns: string[],
    schema: OrderSchemaForCsv,
    order,
    iterated = {}
  ) {
    return columns.reduce((transformedOrder, column) => {
      const { name, value } = schema[column];

      transformedOrder[name] = value(order, iterated);

      return transformedOrder;
    }, {});
  }
}
