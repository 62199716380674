import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "reducedText"
})
export class ReducedTextPipe implements PipeTransform {
  transform(value: string, limit: number): any {
    if (!value) {
      return null;
    } else {
      if (value.length >= limit) {
        return value.substr(0, limit).trim() + "...";
      } else {
        return value;
      }
    }
  }
}
