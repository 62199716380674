import { Injectable } from "@angular/core";
import { EncryptionService } from "./encryption.service";

@Injectable({
  providedIn: "root"
})
export class BrowserStorageService {
  constructor(private _encryptionService: EncryptionService) {}

  private local = window.localStorage;
  private session = window.sessionStorage;

  use(remeberMe: boolean): any {
    return remeberMe ? this.local : this.session;
  }

  getItem(key: string): any {
    const valueInSS = this.getItemFromSS(key);
    const valueInLS = this.getItemFromLS(key);

    if (valueInSS) return valueInSS;
    if (valueInLS) return valueInLS;

    return "";
  }

  removeItem(key: string): void {
    this.removeItemFromSS(key);
    this.removeItemFromLS(key);
  }

  // Functions to access Local Storage
  isAvailableInLS(key: string): boolean {
    return !!this.local.getItem(key);
  }

  setItemInLS(key: string, value: string): void {
    this.local.setItem(key, value);
  }

  getItemFromLS(key: string): string {
    return this.local.getItem(key);
  }

  removeItemFromLS(key: string): void {
    this.local.removeItem(key);
  }

  clearLS(): void {
    this.local.clear();
  }

  encryptAndSaveInLS(key: string, value: string): void {
    const encryptedValue = this._encryptionService.encrypt(value);
    this.setItemInLS(key, encryptedValue);
  }

  getDecryptedValueFromLS(key: string): string {
    const encryptedValue = this.getItemFromLS(key);

    if (!encryptedValue) return "";

    return this._encryptionService.decrypt(encryptedValue);
  }

  // Functions to access Session Storage
  isAvailableInSS(key: string): boolean {
    return !!this.session.getItem(key);
  }

  setItemInSS(key: string, value: string): void {
    this.session.setItem(key, value);
  }

  getItemFromSS(key: string): string {
    return this.session.getItem(key);
  }

  removeItemFromSS(key: string): void {
    this.session.removeItem(key);
  }

  clearSS(): void {
    this.session.clear();
  }
}
