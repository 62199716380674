import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { NotificationService } from "./notification.service";

@Injectable({
  providedIn: "root"
})
export class CourierService {
  // declarations
  couriers: BehaviorSubject<any> = new BehaviorSubject([]);
  customCouriers: BehaviorSubject<any> = new BehaviorSubject([]);

  constructor(
    private _http: HttpClient,
    private _notification: NotificationService
  ) {}

  getCouriers(): void {
    this._http.get("user/config/getCourier").subscribe(
      (res: any) => {
        if (res.success) {
          const sortedCouriers = res.courier.sort((a, b) => {
            const A = a.courierPartner?.toUpperCase();
            const B = b.courierPartner?.toUpperCase();

            if (A > B) {
              return 1;
            } else if (A < B) {
              return -1;
            } else {
              return 0;
            }
          });
          this.customCouriers.next(res?.allCustomCourier);
          this.couriers.next(sortedCouriers);
        } else {
          this.customCouriers.next([]);
          this.couriers.next([]);
          this._notification.show(res.msg);
        }
      },
      (error) => {
        this.couriers.next([]);
        throw error;
      }
    );
  }
}
