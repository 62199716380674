<div class="dialog-content-wrapper">
  <mat-toolbar matDialogTitle class="mat-accent m-0">
    <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
      <span class="title dialog-title">
        Reject Discrepancy
      </span>
      <button
        mat-icon-button
        [mat-dialog-close]="false"
        aria-label="Close dialog"
      >
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar-row>
  </mat-toolbar>

  <div mat-dialog-content class="p-24 pb-0 m-0" fusePerfectScrollbar>
    <p>
      To complete the flagging process and initiate investigation, Kindly Share
      - Product Details, Shipment Invoice and Packaging Images via email to
      <a href="mailto:support@shippigo.com">support@shippigo.com</a> from your
      registered email address. Please mention the AWB number and Order Number
      in the subject line.
    </p>
    <p>
      <span style="color: #e53935;">Please Note:</span> In case we don't recieve
      an email within 7 working days, the same will be treated as accepted.
    </p>
  </div>

  <div
    mat-dialog-actions
    class="m-0 p-16"
    fxLayout="row"
    fxLayoutAlign="end center"
  >
    <button mat-flat-button class="mat-accent" (click)="submit()">
      Accept
    </button>

    <button mat-stroked-button color="primary" mat-dialog-close>
      Back
    </button>
  </div>
</div>
