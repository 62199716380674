import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree
} from "@angular/router";
import { Observable } from "rxjs";
import { map, skipWhile, take, tap } from "rxjs/operators";
import { NotificationService } from "../services/notification.service";
import { ProfileDataService } from "../services/profile-data.service";

@Injectable({
  providedIn: "root"
})
export class VasGuard implements CanLoad, CanActivate {
  constructor(
    private _router: Router,
    private _profileDataService: ProfileDataService,
    private _notification: NotificationService
  ) {}
  canLoad(
    route: Route,
    segments: UrlSegment[]
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.guardLogic();
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.guardLogic();
  }

  guardLogic(): Observable<boolean> | boolean {
    const userPermissions = this._profileDataService._userPermissions.getValue();

    if (!Object.keys(userPermissions).length) {
      this._profileDataService.getPermissions();
    }

    return this._profileDataService.userPermissions.pipe(
      skipWhile((userPermissions) => !Object.keys(userPermissions).length),
      take(1),
      map((value) => {
        return !!(
          value.sms ||
          value.tracking ||
          value.email ||
          value.codVerify
        );
      }),
      tap((val) => {
        if (!val) {
          this.navigateToHome();
        }
      })
    );

    // return of(
    //   !!(
    //     userPermissions.sms ||
    //     userPermissions.tracking ||
    //     userPermissions.email ||
    //     userPermissions.codVerify
    //   )
    // ).pipe(
    //   tap((val) => {
    //     if (!val) {
    //       this.navigateToHome();
    //     }
    //   })
    // );
  }

  navigateToHome(): void {
    this._notification.show("You are not authorised to access this feature.");
    this._router.navigate(["/"]);
  }
}
