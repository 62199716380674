export const status = {
  "-3": "Deleted",
  "-2": "Non - Servicable",
  "-1": "Cancelled",
  0: "Delivered",
  1: "Pending",
  2: "Booked (AWB Generated)",
  3: "Shipping Initiated", // rts
  // "4": "Order picked up",
  4: "In Transit",
  5: "In Transit",
  6: "NDR",
  7: "RTO",
  8: "Reverse", // New Order is created
  9: "Flagged (General)",
  10: "Flagged (Weight Descripency)",
  11: "Status Awaited",
  12: "Re-attempt Delivery",
  13: "Out for delivery",
  14: "Out for pickup",
  15: "Processing",
  21: "Splitted Order",
  // "51": "Weight Issue (above 7 kg)"
  52: "Others (Closed by admin)",
  53: "Shipment Lost/Damaged",
  100: "RTO Delivered"
};
