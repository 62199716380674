import { HttpClient } from "@angular/common/http";
import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewEncapsulation
} from "@angular/core";
import { FuseNavigationService } from "@fuse/components/navigation/navigation.service";
import { FullScreenLoaderService } from "app/main/shared/modules/full-screen-loader/full-screen-loader.service";
import { IntegratedStoresPipe } from "app/main/shared/pipes/integrated-stores.pipe";
import { LoginService } from "app/main/shared/services/login.service";
import { NotificationService } from "app/main/shared/services/notification.service";
import { OrderActionService } from "app/main/shared/services/order-action.service";
import { ProfileDataService } from "app/main/shared/services/profile-data.service";
import { Observable, Subject } from "rxjs";
import { map, skipWhile, take, takeUntil } from "rxjs/operators";
import { noBatchStore, noChannelStore } from "app/main/shared/ts/storeConfig";
import { TenantService } from "app/main/shared/services/tenant.service";
import { SidebarStatsService } from "app/main/shared/services/sidebar-stats.service";

@Component({
  selector: "navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class NavbarComponent implements OnInit, OnDestroy {
  // Private
  _variant: string;
  integratedStoresPipe = new IntegratedStoresPipe();
  private _unsubscribeAll: Subject<any> = new Subject();

  tenantConfig = this._tenantService.getTenantConfig();

  constructor(
    private _elementRef: ElementRef,
    private _renderer: Renderer2,
    private _fuseNavigationService: FuseNavigationService,
    private _loginService: LoginService,
    private _profileDataService: ProfileDataService,
    private _orderActionService: OrderActionService,
    private _http: HttpClient,
    private _notification: NotificationService,
    private _fullScreenLoder: FullScreenLoaderService,
    private _tenantService: TenantService,
    private _sidebarStatsService: SidebarStatsService
  ) {
    // Set the private defaults
    this._variant = "vertical-style-1";
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Variant
   */
  get variant(): string {
    return this._variant;
  }

  @Input()
  set variant(value: string) {
    // Remove the old class name
    this._renderer.removeClass(this._elementRef.nativeElement, this.variant);

    // Store the variant value
    this._variant = value;

    // Add the new class name
    this._renderer.addClass(this._elementRef.nativeElement, value);
  }

  ngOnInit(): void {
    const isUserLoggedIn = this._loginService.isUserLoggedIn();

    if (isUserLoggedIn) {
      this.handlePermissionSub();
      this.handleIntegrationSub();
      this.handleProfileSub();
      this.handleOrderSub();
      this._sidebarStatsService.getCounts();
    }
  }

  handleProfileSub(): void {
    this._profileDataService.profileData
      .pipe(
        takeUntil(this._unsubscribeAll),
        skipWhile((val) => !Object.keys(val || {}).length),
        take(1)
      )
      .subscribe(({ profile }) => {
        this.pushDeveloperSectionToVas(profile);
      });
  }

  pushDeveloperSectionToVas(profile: any) {
    const devNavigation = {
      id: "developer",
      title: "Developer",
      type: "item",
      icon: "code",
      url: "settings/developer"
    };

    if (profile.signUpPlatform === "swiggy") return;

    const devNavigationAvailable =
      this._fuseNavigationService.getNavigationItem("developer");

    if (!devNavigationAvailable) {
      this._fuseNavigationService.addNavigationItem(devNavigation, "settings");
    }
  }

  handlePermissionSub(): void {
    this._profileDataService.userPermissions
      .pipe(
        takeUntil(this._unsubscribeAll),
        skipWhile((val) => !Object.keys(val || {}).length),
        take(1)
      )
      .subscribe((userPermissions) => {
        this.pushVasToNav(userPermissions);

        if (userPermissions.support) {
          this.pushSupportToNav();
        }
      });
  }

  handleIntegrationSub(): void {
    this._profileDataService._integrations
      .pipe(
        takeUntil(this._unsubscribeAll),
        skipWhile((val) => !Object.keys(val || {}).length)
      )
      .subscribe((allIntegrations) => {
        this.pushBatchesToNav(allIntegrations);
        this.pushChannelsToNav(allIntegrations);
      });
  }

  handleOrderSub(): void {
    this._profileDataService._orderPermissions
      .pipe(
        takeUntil(this._unsubscribeAll),
        skipWhile((val) => !Object.keys(val || {}).length)
      )
      .subscribe((orderPermission) => {
        this.pushActionToNav(orderPermission);
      });
  }

  // adding value added services based on user permission
  pushVasToNav({ sms, tracking, filter, email, codVerify, whatsapp }): void {
    const vasNavigation = {
      id: "vas",
      title: "Value Added Service",
      type: "collapsable",
      icon: "people",
      children: []
    };

    if (sms) {
      vasNavigation.children.push({
        id: "sms",
        title: "SMS Service",
        type: "item",
        icon: "textsms",
        url: "vas/sms"
      });
    }

    if (tracking) {
      vasNavigation.children.push({
        id: "customTracking",
        title: "Custom Tracking",
        type: "item",
        icon: "web",
        url: "vas/custom-tracking"
      });
      this.pushCustomerResponseToNav();
    }

    if (email) {
      vasNavigation.children.push({
        id: "customEmails",
        title: "Custom Emails",
        type: "item",
        icon: "email",
        url: "vas/custom-emails"
      });
    }

    if (codVerify) {
      vasNavigation.children.push({
        id: "codVerification",
        title: "COD Verification",
        type: "item",
        icon: "sms_failed",
        url: "vas/cod-verification"
      });
    }

    if (whatsapp) {
      vasNavigation.children.push({
        id: "customWhatsapp",
        title: "Custom Whatsapp",
        type: "item",
        svgIcon: "custom-whatsapp",
        url: "vas/custom-whatsapp"
      });
    }

    const vasNavigationAvailable =
      this._fuseNavigationService.getNavigationItem("vas");

    // adding parent to navbar
    if ((sms || tracking || email || codVerify) && !vasNavigationAvailable) {
      this._fuseNavigationService.addNavigationItem(vasNavigation, "dashboard");
    }

    if (filter) {
      this.pushCustomBatchToNav();
    }

    this.handleModuleCountSub();
  }

  handleModuleCountSub(): void {
    this._sidebarStatsService.moduleDataCount
      .pipe(
        takeUntil(this._unsubscribeAll),
        skipWhile((val) => !Object.keys(val || {}).length)
      )
      .subscribe((countObj) => this.patchCountToSidenav(countObj));
  }

  patchCountToSidenav(countObj: any): any {
    for (const [navigationItem, count] of Object.entries(countObj)) {
      this._fuseNavigationService.updateNavigationItem(navigationItem, {
        badge: {
          title: count || 0,
          bg: "#525e8a",
          fg: "#FFFFFF"
        }
      });
    }
  }

  // if custom batch filters is enabled
  pushCustomBatchToNav(): void {
    const batchFilters = {
      id: "custom-batch",
      title: "Custom Batch",
      type: "item",
      url: "batch-list/custom-batches"
    };

    const batchFiltersAvailable =
      this._fuseNavigationService.getNavigationItem("custom-batch");

    if (!batchFiltersAvailable) {
      this._fuseNavigationService.addNavigationItem(batchFilters, "batches");
    }
  }

  // if Custom tracking is enabled ,enable customer Response as well
  pushCustomerResponseToNav(): void {
    const customerResponse = {
      id: "customer-response",
      title: "Customer Response",
      type: "collapsable",
      icon: "record_voice_over",
      children: [
        {
          id: "request",
          title: "Request",
          type: "item",
          icon: "pan_tool",
          url: "orders/customer-response/request"
        },
        {
          id: "feedback",
          title: "Feedback",
          type: "item",
          icon: "grade",
          url: "orders/customer-response/feedback"
        }
      ]
    };

    const customerResponseAvailable =
      this._fuseNavigationService.getNavigationItem("customer-response");

    if (!customerResponseAvailable) {
      this._fuseNavigationService.addNavigationItem(
        customerResponse,
        "reports"
      );
    }
  }

  pushBatchesToNav(allIntegrations: { [key: string]: number }): void {
    for (const storeName in allIntegrations) {
      if (!!allIntegrations[storeName]) {
        const batchItem = {
          id: `${storeName}-batch`,
          title: this.integratedStoresPipe.transform(storeName),
          type: "item",
          url: `batch-list/${storeName}`
        };

        const batchAvailable = this._fuseNavigationService.getNavigationItem(
          `${storeName}-batch`
        );

        if (!noBatchStore.includes(storeName) && !batchAvailable) {
          this._fuseNavigationService.addNavigationItem(batchItem, "batches");
        }
      }
    }
  }

  pushChannelsToNav(allIntegrations: { [key: string]: number }): void {
    const channels = {
      id: "channels",
      title: "Channels",
      type: "collapsable",
      icon: "store",
      children: []
    };
    const channelsAvailable =
      this._fuseNavigationService.getNavigationItem("channels");

    const dynamicRoutes = {
      ESEC: "orders/channel/easyecom"
    };

    for (const storeName in allIntegrations) {
      if (!!allIntegrations[storeName]) {
        const channelItem = {
          id: `${storeName}-channel`,
          title: this.integratedStoresPipe.transform(storeName),
          type: "item",
          svgIcon: storeName
        };

        if (!noChannelStore.includes(storeName)) {
          if (!!dynamicRoutes[storeName]) {
            channelItem["url"] = dynamicRoutes[storeName];
          } else {
            channelItem["url"] = `orders/channel/${storeName}`;
          }
        }

        if (!channelsAvailable) {
          channels.children.push(channelItem);
        } else {
          const channelAvailable =
            this._fuseNavigationService.getNavigationItem(
              `${storeName}-channel`
            );

          if (!channelAvailable) {
            this._fuseNavigationService.addNavigationItem(
              channelItem,
              "channels"
            );
          }
        }
      }
    }

    if (!channelsAvailable) {
      this._fuseNavigationService.addNavigationItem(channels, "dashboard", 3);
    }
  }

  pushActionToNav({ b2bOrder }): void {
    let addManualOrder = {};
    if (b2bOrder) {
      addManualOrder = {
        id: "manual",
        title: "Add Manual Order",
        type: "collapsable",
        icon: "add",
        children: [
          {
            id: "b2b",
            title: "B2B (Beta)",
            type: "item",
            icon: "work_outline",
            url: "actions/manual-order/b2b"
          },
          {
            id: "b2c",
            title: "B2C",
            type: "item",
            icon: "person_outline",
            url: "actions/manual-order/b2c"
          }
        ]
      };
    } else {
      addManualOrder = {
        id: "manual",
        title: "Add Manual Order",
        type: "item",
        url: "actions/manual-order/b2c",
        icon: "add"
      };
    }
    const manualAvailable =
      this._fuseNavigationService.getNavigationItem("manual");

    if (!manualAvailable) {
      this._fuseNavigationService.addNavigationItem(
        addManualOrder,
        "actions",
        "start"
      );
    }
  }

  pushSupportToNav(): void {
    const support = {
      id: "support",
      title: "Support",
      type: "collapsable",
      icon: "headset_mic",
      children: [
        {
          id: "add-tickets",
          title: "Raise an Issue",
          type: "item",
          icon: "contact_support",
          function: () => {
            this.openCreateTicketModal();
          }
        }
        // {
        //   id: "view-tickets",
        //   title: "View Tickets",
        //   type: "item",
        //   icon: "local_play",
        //   function: () => {
        //     this.redirectToZohoLogin();
        //   }
        // }
      ]
    };

    const supportAvailable =
      this._fuseNavigationService.getNavigationItem("support");

    if (!supportAvailable) {
      this._fuseNavigationService.addNavigationItem(support, "dashboard");
    }
  }

  openCreateTicketModal() {
    this._orderActionService.createTicket({}, "side-bar");
  }

  // redirectToZohoLogin() {
  //   this._fullScreenLoder.show();

  //   this._http.get("user/registration/saml/forward").subscribe((res: any) => {
  //     this._fullScreenLoder.hide();
  //     if (res.success) {
  //       this._loginService.handleSamlLogin(() => window.open(res.postUrl));
  //     } else {
  //       this._notification.show(res.msg);
  //     }
  //   });
  // }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();

    this._loginService.clearNavigation();
  }
}
