import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AppointmentDeliveryNotificationComponent } from "./appointment-delivery-notification/appointment-delivery-notification.component";
import { MatIconModule } from "@angular/material/icon";
import { MatDialogModule } from "@angular/material/dialog";
import { MatButtonModule } from "@angular/material/button";
import { FuseSharedModule } from "@fuse/shared.module";
import { PipesModule } from "../../pipes/pipes.module";
import { MatToolbarModule } from "@angular/material/toolbar";
import { DataLoaderModule } from "../../modules/dynamic-table/table-components/data-loader/data-loader.module";

@NgModule({
  declarations: [AppointmentDeliveryNotificationComponent],
  imports: [
    CommonModule,
    FuseSharedModule,
    PipesModule,
    MatIconModule,
    MatToolbarModule,
    MatButtonModule,
    MatDialogModule,
    DataLoaderModule
  ]
})
export class AppointmentDeliveryNotificationModule {}
