import { Pipe, PipeTransform } from "@angular/core";
import { zones } from "../ts/courierRates";

@Pipe({
  name: "zoneDetails"
})
export class ZoneDetailsPipe implements PipeTransform {
  transform(value: string): string {
    let zoneDetails: any = zones.find((zone) => zone?.zone === value);
    if (zoneDetails) {
      zoneDetails = `${zoneDetails?.zone} - ${zoneDetails?.label}`;
    }
    return zoneDetails;
  }
}
