const integrationImagesLinkPrefix = "assets/images/integrations";
const integrationIconLinkPrefix = "assets/images/channels";

const allIntegrations = [
  {
    name: "INSTAMOJO",
    value: "instamojo",
    image: `${integrationImagesLinkPrefix}/instamojo.png`,
    icon: `${integrationIconLinkPrefix}/instamojo.png`,
    isEditable: false
  },
  {
    name: "SHOPIFY",
    value: "shopify",
    image: `${integrationImagesLinkPrefix}/shopify.png`,
    icon: `${integrationIconLinkPrefix}/shopify.png`,
    isEditable: false
  },
  {
    name: "WOOCOMMERCE",
    value: "woocommerce",
    image: `${integrationImagesLinkPrefix}/woocommerce.png`,
    icon: `${integrationIconLinkPrefix}/woocommerce.png`,
    isEditable: false
  },
  {
    name: "MAGENTO 1.x",
    value: "magento1",
    image: `${integrationImagesLinkPrefix}/magento.png`,
    icon: `${integrationIconLinkPrefix}/magento.png`,
    isEditable: false
  },
  {
    name: "MAGENTO 2.x",
    value: "magento2",
    image: `${integrationImagesLinkPrefix}/magento.png`,
    icon: `${integrationIconLinkPrefix}/magento.png`,
    isEditable: false
  },
  {
    name: "UNICOMMERCE",
    value: "unicommerce",
    image: `${integrationImagesLinkPrefix}/unicommerce.png`,
    icon: `${integrationIconLinkPrefix}/unicommerce.png`,
    isEditable: false
  },
  {
    name: "EASYECOM",
    value: "ESEC",
    image: `${integrationImagesLinkPrefix}/easyecom.png`,
    icon: `${integrationIconLinkPrefix}/easyecom.png`,
    isEditable: false
  },
  {
    name: "ZOHO INVENTORY",
    value: "zoho",
    image: `${integrationImagesLinkPrefix}/zoho-inventory.png`,
    icon: `${integrationIconLinkPrefix}/zoho-inventory.png`,
    isEditable: true
  },
  {
    name: "CLICKPOST",
    value: "clickpost",
    image: `${integrationImagesLinkPrefix}/clickpost.png`,
    icon: `${integrationIconLinkPrefix}/clickpost.png`,
    isEditable: false
  }
  // {
  //   name: "AMAZON",
  //   value: "amazon",
  //   image: `${integrationImagesLinkPrefix}/amazon.png`
  // }
];

const integrationUrls = {
  shopify: {
    addStore: "user/config/addShopifyStore",
    getStore: "user/order/getShopifyStores",
    autoSync: "user/config/shopify/sync-manage",
    syncOrder: "user/order/fetchShopifyOrders"
  },
  woocommerce: {
    addStore: "user/config/addWooStore",
    getStore: "user/order/woo/getStores",
    syncOrder: "user/order/woo/fetchOrders"
  },
  magento2: {
    addStore: "user/config/mag/add/2",
    getStore: "user/order/mag/2/getStores",
    syncOrder: "user/order/mag/2/fetchOrders"
  },
  magento1: {
    addStore: "user/config/mag/add/1",
    getStore: "user/order/mag/1/getStores",
    syncOrder: "user/order/mag/1/fetchOrders"
  },
  unicommerce: {
    addStore: "user/config/unicommerce/add",
    getStore: "user/config/unicommerce/list/unicommerce"
  },
  clickpost: {
    addStore: "user/config/unicommerce/add",
    getStore: "user/config/unicommerce/list/clickpost"
  },
  easyecom: {
    addStore: "user/config/unicommerce/add",
    getStore: "user/config/unicommerce/list/ESEC"
  },
  amazon: {
    addStore: "user/config/addAmzStore",
    getStore: "user/order/amzn/list-stores",
    syncOrder: "user/order/amzn/orders"
  },
  zoho: {
    authLink: "user/config/zoho/getLink",
    addStore: "user/config/zoho/addStore",
    getStore: "user/order/zoho/list-stores",
    syncOrder: "user/order/zoho/orders",
    oAuthRoute: "o-auth/zoho-inventory"
  },
  instamojo: {
    authLink: "user/config/instaMojo/createAuthLink",
    addStore: "user/config/instaMojo/addStore",
    getStore: "user/config/instaMojo/getStore",
    syncOrder: "user/order/mojo/fetchOrders",
    oAuthRoute: "o-auth/instamojo",
    createUserAddStore: "user/config/instaMojo/createUserAddStore"
  }
};

function getRedirectURI(channel: string): string {
  const oAuthRoute = integrationUrls[channel]?.oAuthRoute;

  if (!oAuthRoute) {
    throw new Error(`No o-Auth Route for ${channel} found.`);
  }

  return `${window.location.origin}/${oAuthRoute}`;
}

export { allIntegrations, integrationUrls, getRedirectURI };
