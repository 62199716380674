import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { B2bBoxComponent } from "./b2b-box.component";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { ReactiveFormsModule } from "@angular/forms";
import { MatIconModule } from "@angular/material/icon";
import { ErrorMessageModule } from "../../modules/error-message/error-message.module";
import { MatInputModule } from "@angular/material/input";
import { FuseSharedModule } from "@fuse/shared.module";
import { MatButtonModule } from "@angular/material/button";
import { DirectivesModule } from "../../directives/directives.module";

@NgModule({
  declarations: [B2bBoxComponent],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatCheckboxModule,
    ReactiveFormsModule,
    MatIconModule,
    ErrorMessageModule,
    MatInputModule,
    FuseSharedModule,
    MatButtonModule,
    DirectivesModule
  ],
  exports: [B2bBoxComponent]
})
export class B2bBoxModule {}
