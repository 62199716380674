import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { UploadCsvComponent } from "./upload-csv.component";
import { FuseSharedModule } from "@fuse/shared.module";
import { MatInputModule } from "@angular/material/input";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";

@NgModule({
  declarations: [UploadCsvComponent],
  imports: [
    CommonModule,

    // shared
    FuseSharedModule,

    // material
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatProgressSpinnerModule
  ],
  exports: [UploadCsvComponent]
})
export class UploadCsvModule {}
