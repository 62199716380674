import { Component, OnInit } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { map, skipWhile, takeUntil } from "rxjs/operators";
import { ProfileDataService } from "../../services/profile-data.service";

@Component({
  selector: "toolbar-shortcuts",
  templateUrl: "./toolbar-shortcuts.component.html",
  styleUrls: ["./toolbar-shortcuts.component.scss"]
})
export class ToolbarShortcutsComponent implements OnInit {
  private _unsubscribeAll: Subject<any> = new Subject();
  b2b: Boolean = false;
  b2bPermission$: Observable<boolean> =
    this._profileDataService.orderPermissions.pipe(
      map((userPermissions) => !!userPermissions["b2bOrder"])
    );
  constructor(private _profileDataService: ProfileDataService) {}

  ngOnInit(): void {}
  gOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
