import { Component, Input } from "@angular/core";
import { FormControl } from "@angular/forms";

@Component({
  selector: "error-message",
  templateUrl: "./error-message.component.html",
  styleUrls: ["./error-message.component.scss"]
})
export class ErrorMessageComponent {
  @Input() formControlErr: FormControl;
  @Input() fieldName: string;
  @Input() patternMsg: string;
  @Input() minLengthMsg: string;
  @Input() maxLengthMsg: string;
  @Input() minValue: number;
  @Input() maxValue: number;
}
