<div
  class="navbar-header"
  [ngClass]="fuseConfig.layout.navbar.secondaryBackground"
>
  <div class="logo cursor-pointer" routerLink="/" title="Home">
    <!-- 
    <img class="logo-icon" [src]="tenantConfig.squareWhiteLogoUrl" />
    <span class="logo-text">{{ tenantConfig.sName }}</span> 
    -->

    <img class="logo-icon" [src]="tenantConfig.lightLogos.square" />
    <img class="logo-image" [src]="tenantConfig.lightLogos.main" />
  </div>

  <button
    mat-icon-button
    class="toggle-sidebar-folded"
    (click)="toggleSidebarFolded()"
    fxHide.lt-lg
  >
    <mat-icon>menu</mat-icon>
  </button>

  <button
    mat-icon-button
    class="toggle-sidebar-opened"
    (click)="toggleSidebarOpened()"
    fxHide.gt-md
  >
    <mat-icon>arrow_back</mat-icon>
  </button>
</div>

<div
  class="navbar-content"
  fusePerfectScrollbar
  [fusePerfectScrollbarOptions]="{ suppressScrollX: true }"
  [ngClass]="fuseConfig.layout.navbar.primaryBackground"
>
  <fuse-navigation layout="vertical"></fuse-navigation>
</div>
