import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivateChild,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree
} from "@angular/router";
import { Observable, of } from "rxjs";
import { tap } from "rxjs/operators";
import { LoginService } from "../services/login.service";
import { NotificationService } from "../services/notification.service";

@Injectable({
  providedIn: "root"
})
export class OnboardingGuard implements CanActivateChild, CanLoad {
  constructor(
    private _loginService: LoginService,
    private _router: Router,
    private _notification: NotificationService
  ) {}
  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.onboardingGuardLogic();
  }

  canLoad(
    route: Route,
    segments: UrlSegment[]
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.onboardingGuardLogic();
  }

  onboardingGuardLogic(): Observable<boolean> | boolean {
    const isUserLoggedIn = this._loginService.isUserLoggedIn();

    if (!isUserLoggedIn) {
      return true;
    }

    return of(false).pipe(
      tap(() => {
        this._notification.show(
          "Onboarding is only for an unregistered user.",
          "Okay",
          NaN
        );
        this._router.navigateByUrl("/orders");
      })
    );
  }
}
