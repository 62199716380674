import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AddDetailsForBoxComponent } from "./add-details-for-box/add-details-for-box.component";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { MatStepperModule } from "@angular/material/stepper";
import { MatDialogModule } from "@angular/material/dialog";
import { FooterNavigationModule } from "../../modules/footer-navigation/footer-navigation.module";
import { FuseSharedModule } from "@fuse/shared.module";
import { MatRadioModule } from "@angular/material/radio";
import { B2bBoxModule } from "../b2b-box/b2b-box.module";
import { MatToolbarModule } from "@angular/material/toolbar";
import { UploadCsvModule } from "../../modules/upload-csv/upload-csv.module";
import { LargePreviewFormModule } from "../../modules/large-preview-form/large-preview-form.module";
import { BoxDetailsTableModule } from "./add-details-for-box/child-component/box-details-table/box-details-table.module";
import { MatCardModule } from "@angular/material/card";

@NgModule({
  declarations: [AddDetailsForBoxComponent],
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    MatStepperModule,
    MatToolbarModule,
    MatDialogModule,
    MatCardModule,
    FooterNavigationModule,
    FuseSharedModule,
    MatRadioModule,
    B2bBoxModule,
    UploadCsvModule,
    LargePreviewFormModule,
    BoxDetailsTableModule
  ]
})
export class AddDetailsForBoxModule {}
