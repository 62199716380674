import { HttpClient } from "@angular/common/http";
import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewEncapsulation
} from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { LoginService } from "app/main/shared/services/login.service";
import { NotificationService } from "app/main/shared/services/notification.service";
import { OrdersDataService } from "app/main/shared/services/orders-data.service";
import { ProfileDataService } from "app/main/shared/services/profile-data.service";
import { Subscription, timer } from "rxjs";
import { delay, retryWhen, share, switchMap, take } from "rxjs/operators";

@Component({
  selector: "app-bulk-order-status",
  templateUrl: "./bulk-order-status.component.html",
  styleUrls: [
    "./bulk-order-status.component.scss",
    "./../../../scss/responsive-modals.scss"
  ],
  encapsulation: ViewEncapsulation.None
})
export class BulkOrderStatusComponent implements OnInit, OnDestroy {
  // declaration
  private checkBulkSub: Subscription;
  bulkProcessStatus: string = "processing";
  closeIcon: string = "minimize";

  // Progress Value
  progressValue = 1;
  orderSteps = 1;
  totalOrder = 10;
  processedOrder = 1;

  // Order summary
  summaryTotalOrder = "";
  totalShippingCharge = "";
  totalWeight = "";
  unservicable = [];
  noPickUp = [];
  notBooked = [];
  pickedAWB = [];
  response: any;

  constructor(
    public _dialogRef: MatDialogRef<BulkOrderStatusComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _http: HttpClient,
    private _notification: NotificationService,
    private _profileDataService: ProfileDataService,
    private _orderDataService: OrdersDataService,
    private _loginService: LoginService
  ) {}

  ngOnInit(): void {
    this.getProcessingStatus();
  }

  ngOnDestroy(): void {
    this.stopCallingAPI();
  }

  stopCallingAPI(): void {
    // console.log("kill subscription");
    this.checkBulkSub.unsubscribe();
  }

  getProcessingStatus(): void {
    const batchId = this.data?.batchId || this.data?.lastBatchId;
    const tenSeconds = 10 * 1000;
    const body = { batchId };

    this.checkBulkSub = timer(1, tenSeconds)
      .pipe(
        switchMap(() => this._http.post("user/order/checkBulk", body)),
        retryWhen((errors) => errors.pipe(delay(tenSeconds * 3), take(3))),
        share()
      )
      .subscribe(
        (res: any) => {
          // console.log("subscription active");
          // console.log("response from checkBulk api: ", res);
          if (res.success) {
            if (!res.done) {
              this.orderSteps = res.data.step;
              if (res.data.step === 1) {
                // Updating No. Of Orders
                this.totalOrder = res.data.toSave;
                this.processedOrder = res.data.saved;

                // Calculating Percentage
                this.progressValue = (res.data.saved / res.data.toSave) * 100;
              } else if (res.data.step === 2) {
                // Updating No. Of Orders
                this.totalOrder = res.data.toBook;
                this.processedOrder = res.data.bookProcessed;

                // Calculating Percentage
                this.progressValue =
                  (res.data.bookProcessed / res.data.toBook) * 100;
              } else if (res.data.step === 3) {
                // Updating No. Of Orders
                this.totalOrder = res.data.toPick;
                this.processedOrder = res.data.pickUpProcessed;

                // Calculating Percentage
                this.progressValue =
                  (res.data.pickUpProcessed / res.data.toPick) * 100;
              }
            } else {
              this.stopCallingAPI();
              // // Update Transactions
              // this.newOrderService.updateTransactionTable();

              // Updating Wallet Balance
              this._profileDataService.getProfileData();

              // Updating Orders In Orders Table
              this._orderDataService.refreshOrders();

              // if on Shopify Batches
              // this.newOrderService.updateShopifyOrders(true);

              this.bulkProcessStatus = "processed";
              this.closeIcon = "close";

              // Pickup Not genrated
              this.noPickUp = res.data.noPickUp;

              //  Orders Not Booked
              this.notBooked = res.data.notBooked;

              // Pickup Orders AWB array
              this.pickedAWB = res.data.pickedAWB;

              this.response = res.data;

              //  Total Orders
              this.summaryTotalOrder = res.data.totalOrders;

              // Total Shipping Charge
              this.totalShippingCharge = res.data.totalShippingCharge;

              //  Total weight Charged
              this.totalWeight = res.data.totalWeight;

              // Unservicable Orders
              this.unservicable = res.data.unservicable;

              // If Not Sufficient Balance
              if (res.data.err) {
                this._notification.show(res.data.err, "Close", NaN);
              }
            }
          } else {
            this.stopCallingAPI();
            this._notification.show(res.msg);
          }
        },
        (err) => {
          this.stopCallingAPI();
          this._loginService.logout();
          this._notification.show("User logged out due to network issue.");
        }
      );
  }

  closeDialog() {
    this._dialogRef.close();
    if (this.bulkProcessStatus === "processing")
      this._notification.show(
        "You have minimised the processing modal. You can open it from the notifications tab.",
        "Close",
        5000
      );
  }
}
