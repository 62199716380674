import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree
} from "@angular/router";
import { Observable, of } from "rxjs";
import { tap } from "rxjs/operators";
import { LoginService } from "../services/login.service";

@Injectable({
  providedIn: "root"
})
export class LoginGuard implements CanActivate {
  constructor(private _loginService: LoginService, private _router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.guardLogic(next.queryParams);
  }

  guardLogic(queryParams): Observable<boolean> | boolean {
    const { token: redirectedUser, SAMLRequest, RelayState } = queryParams;
    const SAMLLogin = SAMLRequest && RelayState;
    const otpVerifyState = this._loginService.isOtpVerifyState();
    const userLoggedIn = this._loginService.isUserLoggedIn();

    // User redirected from old dashboard: Clear token so that we can save the new one we got from queryParams
    if (redirectedUser) {
      return of(true).pipe(
        tap(() => {
          this._loginService.clearToken();
          this._loginService.clearSensitiveData();
        })
      );
    }

    if (!userLoggedIn || SAMLLogin) return true;

    if (otpVerifyState) {
      return of(false).pipe(
        tap(() => this._router.navigateByUrl("/otp-verify"))
      );
    }

    return of(false).pipe(
      tap(() => {
        this._router.navigateByUrl("/orders");
      })
    );
  }
}
