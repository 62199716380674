import { DatePipe } from "@angular/common";
import { RupeePaisePipe } from "../pipes/paise-to-rupee.pipe";
import { StatusPipe } from "../pipes/status.pipe";
import { WeightConversionPipe } from "../pipes/weight-conversion.pipe";
import { OrderSchemaForCsv } from "./../interfaces/order-schema-for-csv";
import { TenantService } from "./../../shared/services/tenant.service";
import { TransitDaysPipe } from "../pipes/transit-days/transit-days.pipe";
import { ZoneDetailsPipe } from "../pipes/zone-details.pipe";

const statusPipe = new StatusPipe();
const datePipe = new DatePipe("en-US");
const rupeePaisePipe = new RupeePaisePipe();
const weightConversionPipe = new WeightConversionPipe();
const transitDaysPipe = new TransitDaysPipe();
const tenantService = new TenantService();
const zoneLabelPipe = new ZoneDetailsPipe();
const tenantConfig = tenantService.getTenantConfig();

export const orderSchema: OrderSchemaForCsv = {
  SG_ORDERID: {
    name: `${tenantConfig.sName} Shipping ID`,
    value: (order) => order?.SG_ORDERID || ""
  },
  createdAt: {
    name: "Created At",
    value: (order) =>
      order?.createdAt ? datePipe.transform(order.createdAt, "medium") : ""
  },
  orderNumber: {
    name: "Order Number",
    value: (order) => order?.orderNumber || ""
  },
  orderDate: {
    name: "Order Date",
    value: (order) =>
      order?.orderDate ? datePipe.transform(order.orderDate, "medium") : ""
  },
  awb: {
    name: "AWB",
    value: (order) => order?.awb || ""
  },
  oldAwb: {
    name: "Old AWB",
    value: (order) => order?.originalOrderAWB || ""
  },
  newAwb: {
    name: "New AWB",
    value: (order) => order?.awb || ""
  },
  courier_service: {
    name: "Courier",
    value: (order) => order?.shipping_info?.courier?.courier_service || ""
  },
  warehouse: {
    name: "Warehouse",
    value: (order) => order?.shipping_info?.pickup_address?.name || ""
  },
  fullName: {
    name: "Customer Name",
    value: (order) => order?.fullName || ""
  },
  firstName: {
    name: "First Name",
    value: (order) => order?.firstName || ""
  },
  lastName: {
    name: "Last Name",
    value: (order) => order?.lastName || ""
  },
  email: {
    name: "Email",
    value: (order) => order?.email || ""
  },
  phone: {
    name: "Phone",
    value: (order) => order?.phone || ""
  },
  addressLine1: {
    name: "Address Line 1",
    value: (order) => order?.addressLine1 || ""
  },
  addressLine2: {
    name: "Address Line 2",
    value: (order) => order?.addressLine2 || ""
  },
  city: {
    name: "City",
    value: (order) => order?.city || ""
  },
  state: {
    name: "State",
    value: (order) => order?.state || ""
  },
  pincode: {
    name: "Pincode",
    value: (order) => order?.pincode || ""
  },
  origin_city: {
    name: "Origin City",
    value: (order) => order?.shipping_info?.pickup_address?.city || ""
  },
  origin_state: {
    name: "Origin State",
    value: (order) => order?.shipping_info?.pickup_address?.state || ""
  },
  origin_pincode: {
    name: "Origin Pincode",
    value: (order) => order?.shipping_info?.pickup_address?.pincode || ""
  },
  destination_city: {
    name: "Destination City",
    value: (order) => order?.city || ""
  },
  destination_state: {
    name: "Destination State",
    value: (order) => order?.state || ""
  },
  destination_pincode: {
    name: "Destination Pincode",
    value: (order) => order?.pincode || ""
  },
  country: {
    name: "Country",
    value: (order) => order?.country || ""
  },
  transitDays: {
    name: "Transit Days",
    value: (order) => transitDaysPipe.transform(order, "")
  },
  zone: {
    name: "Zone",
    value: (order) => zoneLabelPipe.transform(order?.zone)
  },
  bulkItemName: {
    name: "Item Name",
    value: (order) => order?.items[0]?.name || ""
  },
  bulkSku: {
    name: "SKU",
    value: (order) => order?.items[0]?.sku || ""
  },
  bulkOrderDate: {
    name: "Order Date (DD-MM-YYYY)",
    value: (order) =>
      order?.orderDate ? datePipe.transform(order.orderDate, "dd-MM-yyyy") : ""
  },
  bulkItemPrice: {
    name: "Item Price",
    value: (order) =>
      order?.items[0]
        ? rupeePaisePipe.transform(order.items[0].price, "rupee")
        : ""
  },
  bulkQuantity: {
    name: "No. of Items",
    value: (order) => order?.items[0]?.quantity || ""
  },
  itemName: {
    name: "Item Name",
    value: (order, item) => item?.name || ""
  },
  sku: {
    name: "SKU",
    value: (order, item) => item?.sku || ""
  },
  itemPrice: {
    name: "Item Price",
    value: (order, item) =>
      item?.price || item?.price === 0
        ? rupeePaisePipe.transform(item.price, "rupee")
        : ""
  },
  quantity: {
    name: "No. of Items",
    value: (order, item) => item?.quantity || ""
  },
  totalAmount: {
    name: "Total Amount",
    value: (order, item) =>
      (item?.price || item?.price === 0) && item?.quantity
        ? rupeePaisePipe.transform(item.price * item.quantity, "rupee")
        : ""
  },
  orderTotal: {
    name: "Order Total",
    value: (order) =>
      order?.orderTotal || order?.orderTotal === 0
        ? rupeePaisePipe.transform(order.orderTotal, "rupee")
        : ""
  },
  paymentType: {
    name: "Payment Type",
    value: (order) => order?.paymentType || ""
  },
  currencyType: {
    name: "Currency Type",
    value: (order) => order?.currency || ""
  },
  weight: {
    name: "Weight (kg)",
    value: (order) =>
      order?.shipping_info?.weight
        ? weightConversionPipe.transform(order.shipping_info.weight, "kg")
        : ""
  },
  chargedWeight: {
    name: "Charged Weight (kg)",
    value: (order) =>
      order?.shipping_info?.chargedWeight
        ? weightConversionPipe.transform(
            order.shipping_info.chargedWeight,
            "kg"
          )
        : ""
  },
  volWeight: {
    name: "Vol Weight (kg)",
    value: (order) =>
      order?.shipping_info?.volWeight
        ? weightConversionPipe.transform(order.shipping_info.volWeight, "kg")
        : ""
  },
  length: {
    name: "Length (cm)",
    value: (order) => order?.shipping_info?.size?.length || ""
  },
  breadth: {
    name: "Breadth (cm)",
    value: (order) => order?.shipping_info?.size?.breadth || ""
  },
  height: {
    name: "Height (cm)",
    value: (order) => order?.shipping_info?.size?.height || ""
  },
  codTotal: {
    name: "Collectable Value",
    value: (order) =>
      order?.codTotal || order?.codTotal === 0
        ? rupeePaisePipe.transform(order?.codTotal, "rupee")
        : ""
  },
  destination: {
    name: "Destination",
    value: (order) =>
      order?.city && order?.state ? `${order?.city}, ${order?.state}` : ""
  },
  status: {
    name: "Status",
    value: (order) => statusPipe.transform(order?.status)
  },
  ndrReason: {
    name: "NDR Reason",
    value: (order) =>
      order?.ndr?.open ? order.ndr?.openReason : order.ndr?.closeReason
  },
  weightDescCreated: {
    name: "RaisedOn",
    value: (order) =>
      order?.weightDesc?.created
        ? datePipe.transform(order.weightDesc.created)
        : ""
  },
  weightDescOldWeight: {
    name: "Initial Weight Charged (kg)",
    value: (order) =>
      order?.weightDesc?.oldWeight
        ? weightConversionPipe.transform(order.weightDesc.oldWeight, "kg")
        : ""
  },
  weightDescOldCharge: {
    name: "Initial Shipping Charged",
    value: (order) =>
      order?.weightDesc?.oldCharge
        ? rupeePaisePipe.transform(order.weightDesc.oldCharge, "rupee")
        : ""
  },
  weightDescNewWeight: {
    name: "New Weight",
    value: (order) =>
      order?.weightDesc?.newWeight
        ? weightConversionPipe.transform(order.weightDesc.newWeight, "kg")
        : ""
  },
  weightDescNewCharge: {
    name: "New Charge",
    value: (order) =>
      order?.weightDesc?.newCharge
        ? rupeePaisePipe.transform(order.weightDesc.newCharge, "rupee")
        : ""
  },
  weightDescStatus: {
    name: "Weight Discrepancy Status",
    value: (order) => order?.weightDesc?.status
    // value: (order) => (order?.weightDesc?.accepted ? "Accepted" : "Rejected")
  },
  statusFlaggedOrder: {
    name: "Status",
    value: (order) =>
      order?.trackshipment_log?.length
        ? order.trackshipment_log[order.trackshipment_log.length - 1]
            ?.UpdateDescription
        : ""
  },
  flaggedReason: {
    name: "Reason",
    value: (order) => order?.flagged?.reason || ""
  },
  deliveredOn: {
    name: "Delivered On",
    value: (order) =>
      order?.deliveredOn ? datePipe.transform(order.deliveredOn, "medium") : ""
  },
  remitDeliveryDate: {
    name: "Delivered On",
    value: (order) =>
      order?.deliveryDate
        ? datePipe.transform(order.deliveryDate, "medium")
        : ""
  },
  remittanceDate: {
    name: "Remittance Date",
    value: (order) =>
      order?.remittedToSG?.date
        ? datePipe.transform(order.remittedToSG.date, "medium")
        : ""
  },
  remitCodAmount: {
    name: "Collectable Value",
    value: (order) =>
      order?.collectable || order?.collectable === 0
        ? rupeePaisePipe.transform(order.collectable, "rupee")
        : ""
  },
  remitOrderStatus: {
    name: "Status",
    value: (order) => (order?.status > 3 ? "Remitted" : "Pending")
  },
  autoDeductOn: {
    name: "Auto Deduct On",
    value: (order) =>
      order?.weightDesc?.toResolveOn
        ? datePipe.transform(order?.weightDesc?.toResolveOn)
        : ""
  },
  acceptedOn: {
    name: "Deducted On",
    value: (order) =>
      order?.weightDesc?.acceptedOn
        ? datePipe.transform(order?.weightDesc?.toResolveOn)
        : ""
  },
  remittedAmount: {
    name: "Remitted Amount",
    value: (order) =>
      order?.remittedToUser?.amount
        ? rupeePaisePipe.transform(order.remittedToUser.amount, "rupee")
        : ""
  },
  remitBankUTR: {
    name: "Bank UTR",
    value: (order) => order?.remittedToUser?.refNumber || ""
  },
  trackingLink: {
    name: "Tracking link",
    value: (order) => {
      const trackingUrl = order?.shortUrl || order?.longUrl;

      if (!trackingUrl) return "";

      if (trackingUrl.includes("localhost")) return `http://${trackingUrl}`;

      if (trackingUrl.includes("https")) {
        return trackingUrl;
      } else {
        return `https://${trackingUrl}`;
      }
    }
  },
  dispatchDate: {
    name: "Dispatch Date",
    value: (order) =>
      order.pickedDate ? datePipe.transform(order.pickedDate, "mediumDate") : ""
  },

  pickupTime: {
    name: "Pickup Time",
    value: (order) =>
      order?.pickedDate
        ? datePipe.transform(order.pickedDate, "mediumTime")
        : ""
  },

  pickupReferenceNo: {
    name: "Pickup reference number",
    value: (order) =>
      order?.pickup ? order?.pickup[0]?.processed_pickup?.manifest_id || "" : ""
  },

  responseCsvDestination: {
    name: "Destination",
    value: (order) => order?.city || ""
  },
  question: {
    name: "Request / Feedback",
    value: (order, response) => response?.question || ""
  },
  response: {
    name: "Response / Rating (Out of 5)",
    value: (order, response) => response?.response || ""
  },
  responseDate: {
    name: "Date",
    value: (order, response) =>
      response?.date ? datePipe.transform(response.date, "medium") : ""
  },
  shippingCharge: {
    name: "Shipping Charge",
    value: (order) =>
      order?.shippingAmount
        ? rupeePaisePipe.transform(order.shippingAmount, "rupee")
        : ""
  },
  channel: {
    name: "Channel",
    value: (order) => order?.onlinePlatformName || "manual"
  }
};

export const transactionSchema: OrderSchemaForCsv = {
  createdAt: {
    name: "Transaction Date",
    value: (order) =>
      order?.createdAt ? datePipe.transform(order.createdAt, "medium") : ""
  },
  awb: {
    name: "AWB",
    value: (order) => order?.transaction_details?.awb || ""
  },
  courier_service: {
    name: "Courier",
    value: (order) => order?.transaction_details?.courier?.courier_service || ""
  },
  orderNumber: {
    name: "Order Number",
    value: (order) => order?.transaction_details?.orderNumber || ""
  },
  description: {
    name: "Description",
    value: (order) => order?.transaction_type || ""
  },
  referenceNumber: {
    name: "Reference Number",
    value: (order) => order?.referenceNumber || ""
  },
  refundReason: {
    name: "Refund Reason",
    value: (order) => order?.rechargeReason || ""
  },
  credit: {
    name: "Credit",
    value: (order) =>
      order?.type === "credit"
        ? rupeePaisePipe.transform(order.transaction_details?.amount, "rupee")
        : ""
  },
  debit: {
    name: "Debit",
    value: (order) =>
      order?.type === "debit"
        ? rupeePaisePipe.transform(order.transaction_details?.amount, "rupee")
        : ""
  },
  balance: {
    name: "Balance",
    value: (order) =>
      order?.balance || order?.balance === 0
        ? rupeePaisePipe.transform(order.balance, "rupee")
        : ""
  }
};

export const skuSchema: OrderSchemaForCsv = {
  sku: {
    name: "SKU",
    value: (sku) => sku?.sku || ""
  },
  productName: {
    name: "Product Name",
    value: (sku) => sku?.productName || ""
  },
  productDescription: {
    name: "Product Description",
    value: (sku) => sku?.productDescription || ""
  },
  length: {
    name: "Length (cm)",
    value: (sku) => sku?.length || ""
  },
  breadth: {
    name: "Breadth (cm)",
    value: (sku) => sku?.breadth || ""
  },
  height: {
    name: "Height (cm)",
    value: (sku) => sku?.height || ""
  },
  weight: {
    name: "Weight (kg)",
    value: (sku) =>
      sku?.weight ? weightConversionPipe.transform(sku.weight, "kg") : ""
  },
  variantIdName: {
    name: "Variant Id Name",
    value: (sku) => (sku?.variantId?.length ? sku.variantId[0]?.idName : "")
  },
  variantUnitPrice: {
    name: "Variant Unit Price",
    value: (sku) =>
      sku?.variantId?.length && sku.variantId[0]?.unitPrice
        ? rupeePaisePipe.transform(sku.variantId[0].unitPrice, "rupee")
        : ""
  }
};
