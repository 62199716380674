import * as moment from "moment";

function findBox(boxId: string, boxes: any[]): object | undefined {
  return boxes.find((box) => box._id === boxId);
}

function findWarehouse(
  warehouseCode: string,
  warehousesList: any[]
): object | undefined {
  return warehousesList.find(
    (warehouse) => warehouse.warehouseCode === warehouseCode
  );
}

/**
 * https://www.jacklmoore.com/notes/rounding-in-javascript/
 * https://www.delftstack.com/howto/javascript/javascript-round-to-2-decimal-places/
 */

function roundToTwoDecimals(num: number) {
  return Number(Math.round(Number(num + "e+2")) + "e-2");
}

function roundUp(value: number) {
  return Math.ceil(value);
}

function handleDeleteForLastItemOnPage(
  pageNumber: number,
  dataCount: number
): number {
  if (pageNumber === 0) {
    return pageNumber;
  }

  if (dataCount === 1) {
    return --pageNumber;
  }

  return pageNumber;
}

function convertBufferIntoFile(bufferData: any) {
  const { type, body, name } = bufferData;
  const blob = new Blob([new Uint8Array(body.data)], {
    type: type
  });
  const file = new File([blob], name, {
    type: type
  });
  return file;
}

function floorList(
  start: number = -5,
  end: number = 99
): { name: string; value: number }[] {
  const floors = [];

  for (let currentFloor = start; currentFloor <= end; currentFloor++) {
    floors.push({ name: getFloorName(currentFloor), value: currentFloor });
  }

  return floors;
}

function getFloorName(floor: number): string {
  if (floor === 0) {
    return "Ground Floor";
  } else if (floor < 0) {
    return `Basement ${Math.abs(floor)}`;
  } else {
    return `${moment.localeData().ordinal(floor)} Floor`;
  }
}

export {
  findBox,
  findWarehouse,
  roundToTwoDecimals,
  handleDeleteForLastItemOnPage,
  convertBufferIntoFile,
  roundUp,
  floorList,
  getFloorName
};
