import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "environments/environment";
import { Observable, of, throwError, timer } from "rxjs";
import { mergeMap, retryWhen, tap } from "rxjs/operators";

@Injectable()
export class HttpRequestRetryInterceptor implements HttpInterceptor {
  retryDelay = 10 * 1000;
  retryMaxAttempts = 3;
  constructor() {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(this.retryAfterDelay());
  }

  retryAfterDelay(): any {
    return retryWhen((errors) => {
      return errors.pipe(
        mergeMap((err, count) => {
          // throw error when we've retried ${retryMaxAttempts} number of times and still get an error
          if (count === this.retryMaxAttempts) {
            return throwError(err);
          }

          // retry when reponse error status is 0
          if (err.status === 0) {
            return of(err).pipe(
              tap((error) => {
                if (environment.mode !== "production")
                  console.log(
                    `Retrying ${error.url}. Retry count ${count + 1}`
                  );
              }),
              mergeMap(() => timer(this.retryDelay))
            );
          }

          return throwError(err);
        })
      );
    });
  }
}
