import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { SupportedBrowserListComponent } from "../../modals/supported-browser-list/supported-browser-list.component";
import { bannerContent, BannerMessageService } from "./banner-message.service";

@Component({
  selector: "app-banner-message",
  templateUrl: "./banner-message.component.html",
  styleUrls: ["./banner-message.component.scss"]
})
export class BannerMessageComponent implements OnInit, OnDestroy {
  showBanner: boolean = false;
  bannerContent: bannerContent;
  private _unsubscribeAll: Subject<any> = new Subject();
  constructor(
    private _bannerService: BannerMessageService,
    private _dialog: MatDialog,
    private _router: Router
  ) {}

  ngOnInit(): void {
    this._bannerService.showBanner
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((showBanner) => {
        this.showBanner = showBanner;
      });

    this._bannerService.bannerContent
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((banner) => {
        this.bannerContent = banner;
      });
  }

  hideBanner(): void {
    this._bannerService.hideBannerMessage();
  }

  takeAction(action: string) {
    switch (action) {
      case "close":
        this.hideBanner();
        break;

      case "navigate":
        this._router.navigateByUrl(this.bannerContent?.url);
        break;

      case "openModal":
        this._dialog.open(SupportedBrowserListComponent, {
          panelClass: "small-modal"
        });
        break;

      default:
        break;
    }
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next({});
    this._unsubscribeAll.complete();
  }
}
