import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivateChild,
  CanLoad,
  Router,
  RouterStateSnapshot,
  UrlTree
} from "@angular/router";
import { Observable, of } from "rxjs";
import { tap } from "rxjs/operators";
import { BrowserStorageService } from "../services/browser-storage.service";
import { LoginService } from "../services/login.service";

@Injectable({
  providedIn: "root"
})
export class OAuthGuard implements CanLoad, CanActivateChild {
  constructor(
    private _loginService: LoginService,
    private _router: Router,
    private _browserStorage: BrowserStorageService
  ) {}

  canLoad(): boolean | Observable<boolean> | Promise<boolean> {
    return this.oAuthGuardLogic();
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.oAuthGuardLogic();
  }

  oAuthGuardLogic(): Observable<boolean> | boolean {
    const isUserLoggedIn = this._loginService.isUserLoggedIn();
    const isOAuthVerifyState = this._browserStorage.isAvailableInLS("oAuth");
    const isOnboardingState =
      this._browserStorage.isAvailableInLS("onBoarding");

    if (isOAuthVerifyState || isOnboardingState) {
      return true;
    }

    if (!isUserLoggedIn) {
      return of(false).pipe(
        tap(() => {
          this._router.navigateByUrl("/");
        })
      );
    }

    return of(false).pipe(
      tap(() => {
        this._router.navigate(["settings", "developer"]);
      })
    );
  }
}
