import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NotificationModalComponent } from "./notification-modal.component";
import { FuseSharedModule } from "@fuse/shared.module";
import { MatDialogModule } from "@angular/material/dialog";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatToolbarModule } from "@angular/material/toolbar";

@NgModule({
    declarations: [NotificationModalComponent],
    imports: [
        CommonModule,
        FuseSharedModule,
        MatDialogModule,
        MatButtonModule,
        MatIconModule,
        MatToolbarModule
    ]
})
export class NotificationModalModule {}
