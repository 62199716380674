import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BannerMessageComponent } from "./banner-message.component";
import { FuseSharedModule } from "@fuse/shared.module";

@NgModule({
  declarations: [BannerMessageComponent],
  imports: [FuseSharedModule],
  exports: [BannerMessageComponent]
})
export class BannerMessageModule {}
