import { NgModule } from "@angular/core";
import { QueryModalComponent } from "./component/query-modal.component";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatToolbarModule } from "@angular/material/toolbar";
import { FuseSharedModule } from "@fuse/shared.module";

@NgModule({
  declarations: [QueryModalComponent],
  imports: [
    // fuse
    FuseSharedModule,

    // material
    MatButtonModule,
    MatIconModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatToolbarModule
  ]
})
export class QueryModalModule {}
