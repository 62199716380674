import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject } from "rxjs";
import { NotificationService } from "./notification.service";

@Injectable({
  providedIn: "root"
})
export class BatchService {
  batchAllOrders: BehaviorSubject<any> = new BehaviorSubject([]);

  constructor(
    private _http: HttpClient,
    private _notification: NotificationService
  ) {}

  getBatchOrders(body) {
    this._http.post("user/order/fetchBatchOrders/all", body).subscribe(
      (res: any) => {
        if (res.success) {
          this.batchAllOrders.next(res.orders);
        } else {
          this.batchAllOrders.next([]);
          this._notification.show(res.msg);
        }
      },
      (error) => {
        this.batchAllOrders.next([]);
        throw error;
      }
    );
  }
}
