import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TransitDaysPipe } from "./transit-days.pipe";

@NgModule({
  declarations: [TransitDaysPipe],
  imports: [CommonModule],
  exports: [TransitDaysPipe]
})
export class TransitDaysModule {}
