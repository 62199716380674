import { NgModule } from "@angular/core";
import { FuseSharedModule } from "@fuse/shared.module";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { ToolbarHeadingComponent } from "./component/toolbar-heading.component";

@NgModule({
  declarations: [ToolbarHeadingComponent],
  imports: [
    // fuse
    FuseSharedModule,

    //material
    MatButtonModule,
    MatIconModule
  ],
  exports: [ToolbarHeadingComponent]
})
export class ToolbarHeadingModule {}
