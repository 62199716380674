import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RupeePaisePipe } from "./paise-to-rupee.pipe";
import { WeightConversionPipe } from "./weight-conversion.pipe";
import { ReducedTextPipe } from "./reduced-text.pipe";
import { StatusPipe } from "./status.pipe";
import { PaymentTypePipe } from "./payment-type.pipe";
import { ReasonSummaryPipe } from "./reason-summary.pipe";
import { SummaryPipePipe } from "./summary-pipe.pipe";
import { IntegratedStoresPipe } from "./integrated-stores.pipe";
import { WeightDetailsPipe } from "./weight-details.pipe";
import { BreakupDetailsPipe } from "./breakup-details.pipe";
import { LostRefundPipe } from "./lost-refund.pipe";
import { TatPipe } from "./tat.pipe";
import { StatusColorPipe } from "./status-color.pipe";
import { PriceBreakdownPipe } from "./price-breakdown.pipe";
import { WeightDiscStatusPipe } from "./weight-disc-status.pipe";
import { RemainingDaysPipe } from "./remaining-days.pipe";
import { PerUnitConversionPipe } from "./per-unit-conversion.pipe";
import { GetFloorDataPipe } from "./get-floor-data.pipe";
import { CamelToStartCasePipe } from "./camel-to-start-case.pipe";
import { ZoneDetailsPipe } from "./zone-details.pipe";

@NgModule({
  declarations: [
    RupeePaisePipe,
    WeightConversionPipe,
    ReducedTextPipe,
    SummaryPipePipe,
    StatusPipe,
    PaymentTypePipe,
    ReasonSummaryPipe,
    IntegratedStoresPipe,
    WeightDetailsPipe,
    BreakupDetailsPipe,
    LostRefundPipe,
    TatPipe,
    StatusColorPipe,
    PriceBreakdownPipe,
    WeightDiscStatusPipe,
    RemainingDaysPipe,
    PerUnitConversionPipe,
    GetFloorDataPipe,
    CamelToStartCasePipe,
    ZoneDetailsPipe
  ],
  imports: [CommonModule],
  exports: [
    RupeePaisePipe,
    WeightConversionPipe,
    ReducedTextPipe,
    SummaryPipePipe,
    StatusPipe,
    PaymentTypePipe,
    ReasonSummaryPipe,
    IntegratedStoresPipe,
    WeightDetailsPipe,
    BreakupDetailsPipe,
    LostRefundPipe,
    TatPipe,
    StatusColorPipe,
    PriceBreakdownPipe,
    WeightDiscStatusPipe,
    RemainingDaysPipe,
    PerUnitConversionPipe,
    GetFloorDataPipe,
    CamelToStartCasePipe,
    ZoneDetailsPipe
  ]
})
export class PipesModule {}
