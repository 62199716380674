import { Injectable } from "@angular/core";
import { Tenant } from "app/main/shared/interfaces/tenant";
import { environment } from "environments/environment";
import { BrowserStorageService } from "./browser-storage.service";
import { EncryptionService } from "./encryption.service";
import { LogoByThemeService } from "./logo-by-theme.service";

const encryption = new EncryptionService();
const browserStorage = new BrowserStorageService(encryption);
const logoByTheme = new LogoByThemeService(browserStorage);

@Injectable({
  providedIn: "root"
})
export class TenantService {
  constructor() {}

  getTenantConfig(): Tenant {
    const { tenant } = environment;
    return {
      ...tenant,
      getLogosByTheme: logoByTheme.getLogosByTheme
    };
  }

  // directAccess -> tenantConfig.lightLogos.main
  // according to theme -> tenantConfig.getTenantLogos("main")
}
