import { NgModule } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatDialogModule } from "@angular/material/dialog";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { MatToolbarModule } from "@angular/material/toolbar";
import { FuseSharedModule } from "@fuse/shared.module";
import { DirectivesModule } from "../../directives/directives.module";
import { ErrorMessageModule } from "../../modules/error-message/error-message.module";
import { AddWarehouseComponent } from "./component/add-warehouse.component";
import { StringFilterByModule } from "app/main/shared/pipes/string-filter-by/string-filter-by.module";
import { NgxMatSelectSearchModule } from "ngx-mat-select-search";

@NgModule({
  declarations: [AddWarehouseComponent],
  imports: [
    // fuse
    FuseSharedModule,

    // shared
    ErrorMessageModule,
    DirectivesModule,
    StringFilterByModule,
    NgxMatSelectSearchModule,

    // material
    MatToolbarModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatDialogModule,
    MatSelectModule,
    MatCheckboxModule
  ]
})
export class AddWarehouseModule {}
