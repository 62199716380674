import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "environments/environment";

@Injectable()
export class TenantInterceptor implements HttpInterceptor {
  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const { tenant } = environment;
    const duplicateReq: HttpRequest<any> = request.clone({
      headers: request.headers.set("tenant", tenant.name)
    });

    return next.handle(duplicateReq);
  }
}
