import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { KycNotificationComponent } from "./component/kyc-notification.component";
import { FuseSharedModule } from "@fuse/shared.module";
import { MatIconModule } from "@angular/material/icon";
import { MatDialogModule } from "@angular/material/dialog";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatButtonModule } from "@angular/material/button";

@NgModule({
  declarations: [KycNotificationComponent],
  imports: [
    FuseSharedModule,
    MatIconModule,
    MatDialogModule,
    MatToolbarModule,
    MatButtonModule
  ]
})
export class KycNotificationModule {}
