import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DataLoaderComponent } from "./data-loader.component";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatIconModule } from "@angular/material/icon";

@NgModule({
  declarations: [DataLoaderComponent],
  imports: [CommonModule, MatProgressSpinnerModule, MatIconModule],
  exports: [DataLoaderComponent]
})
export class DataLoaderModule {}
