import { Injectable } from "@angular/core";
import {
  CanActivate,
  CanLoad,
  Route,
  UrlSegment,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router
} from "@angular/router";
import { Observable } from "rxjs";
import { LoginService } from "../services/login.service";
import { tap } from "rxjs/operators";
import { of } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class OtpVerifyGuard implements CanActivate, CanLoad {
  constructor(private _loginService: LoginService, private _router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.commonGuardLogic();
  }
  canLoad(
    route: Route,
    segments: UrlSegment[]
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.commonGuardLogic();
  }

  commonGuardLogic(): Observable<boolean> | boolean {
    const userLoggedIn = this._loginService.isUserLoggedIn();
    const otpVerifyState = this._loginService.isOtpVerifyState();

    if (userLoggedIn && !otpVerifyState) {
      return of(false).pipe(
        tap(() => {
          this._router.navigateByUrl("/");
        })
      );
    } else if (userLoggedIn && otpVerifyState) {
      return true;
    } else {
      return of(false).pipe(
        tap(() => {
          this._router.navigateByUrl("/login");
        })
      );
    }
  }
}
