import { Injectable } from "@angular/core";
import * as Papa from "papaparse";
import * as FileSaver from "file-saver";
import * as moment from "moment";

@Injectable({
  providedIn: "root"
})
export class DownloadCsvService {
  sampleCsvOrders(): void {
    const orderList = [
      {
        "First Name": "Rahul",
        "Last Name": "Kumar",
        Email: "Rahul.kumar@xyz.com",
        "Phone Number": "9876543210",
        "Address Line 1": "house no 213 A",
        "Address Line 2": "pqr colony",
        City: "Gurgaon",
        State: "Haryana",
        Pincode: "122001",
        Country: "India",
        "Order Number": "1234560",
        "Order Date (DD-MM-YYYY)": moment().format("DD-MM-YYYY"),
        "Item Name": "Basketball",
        SKU: "FGVBH",
        "Item Price": "100",
        "No. of Items": "10",
        "Item Tax (%)": "0",
        "Item Discount (%)": "0",
        "Invoice Value": "1000",
        "Invoice Number": "1234560",
        "Ewaybill Number": "",
        "Payment Type": "COD",
        "Currency Type": "INR",
        "Weight (kg)": "2",
        "Length (cm)": "12",
        "Breadth (cm)": "12",
        "Height (cm)": "12",
        "Shipping Charge": "0",
        "Transaction Charge": "0",
        "Giftwrap Charge": "0",
        HSN: "0",
        "Total Discount": "0",
        "Collectable Value": "1000",
        "Order Total": "1000"
      },
      {
        "First Name": "Rahul",
        "Last Name": "Kumar",
        Email: "Rahul.kumar@xyz.com",
        "Phone Number": "9876543210",
        "Address Line 1": "house no 213 A",
        "Address Line 2": "pqr colony",
        City: "Gurgaon",
        State: "Haryana",
        Pincode: "122001",
        Country: "India",
        "Order Number": "1234561",
        "Order Date (DD-MM-YYYY)": moment().format("DD-MM-YYYY"),
        "Item Name": "Basketball",
        SKU: "FGVBH",
        "Item Price": "150",
        "No. of Items": "10",
        "Item Tax (%)": "0",
        "Item Discount (%)": "0",
        "Invoice Value": "1500",
        "Invoice Number": "1234561",
        "Ewaybill Number": "",
        "Payment Type": "PPD",
        "Currency Type": "INR",
        "Weight (kg)": "2",
        "Length (cm)": "12",
        "Breadth (cm)": "12",
        "Height (cm)": "12",
        "Shipping Charge": "0",
        "Transaction Charge": "0",
        "Giftwrap Charge": "0",
        HSN: "0",
        "Total Discount": "0",
        "Collectable Value": "0",
        "Order Total": "1500"
      }
    ];

    this.downloadCSV(orderList, "sampleOrders.csv");
  }

  sampleCsvSku(): void {
    const skuList = [
      {
        SKU: "DUMMY-1",
        "Product Name": "Dummy Product-1",
        "Product Description": "dummy Product-1",
        "Length (cm)": 10,
        "Breadth (cm)": 10,
        "Height (cm)": 10,
        "Weight (kg)": 10,
        "Variant Id Name": "dummyVariant1",
        "Variant Unit Price": 500
      },
      {
        SKU: "DUMMY-2",
        "Product Name": "Dummy Product-2",
        "Product Description": "dummy Product-2",
        "Length (cm)": 10,
        "Breadth (cm)": 10,
        "Height (cm)": 10,
        "Weight (kg)": 10,
        "Variant Id Name": "dummyVariant2",
        "Variant Unit Price": 500
      }
    ];

    this.downloadCSV(skuList, "sampleSku.csv");
  }

  downloadSampleUserReachOut(): void {
    const userReachOutList = [
      {
        "Order Number": "123456",
        "Order Date (DD-MM-YYYY)": moment().format("DD-MM-YYYY"),
        "First Name": "Rahul",
        "Last Name": "Kumar",
        "Phone Number": "9876543210",
        Email: "Rahul.kumar@xyz.com",
        Items: "Mobile Wallet"
      },
      {
        "Order Number": "123457",
        "Order Date (DD-MM-YYYY)": moment().format("DD-MM-YYYY"),
        "First Name": "Rahul",
        "Last Name": "Kumar",
        "Phone Number": "9876543210",
        Email: "Rahul.kumar@xyz.com",
        Items: "Cards"
      }
    ];

    this.downloadCSV(userReachOutList, "sampleUserReachOut.csv");
  }

  downloadDisputeBulkCsv(): void {
    const disputeList = [
      {
        "AWB No": "rery124125",
        "Order No": "123456",
        "Length (cm)": "100",
        "Width (cm)": "100",
        "Height (cm)": "100",
        "Gross Weight (kg)": "1",
        "Length Image (url)": "https://url",
        "Width Image (url)": "https://url",
        "Height Image (url)": "https://url",
        "Packed Image (url)": "https://url",
        "Unpacked Image (url)": "https://url",
        SG_ORDERID: "AWB1234"
      },
      {
        "AWB No": "rery124125",
        "Order No": "123456",
        "Length (cm)": "100",
        "Width (cm)": "100",
        "Height (cm)": "100",
        "Gross Weight (kg)": "1",
        "Length Image (url)": "https://url",
        "Width Image (url)": "https://url",
        "Height Image (url)": "https://url",
        "Packed Image (url)": "https://url",
        "Unpacked Image (url)": "https://url",
        SG_ORDERID: "AWB1234"
      }
    ];
    this.downloadCSV(disputeList, "disputeBulk.csv");
  }

  downloadAcceptBulkCsv(): void {
    const acceptList = [
      {
        "AWB No": "rery124125",
        "Order No": "test123"
      },
      {
        "AWB No": "rery124125",
        "Order No": "test123"
      }
    ];
    this.downloadCSV(acceptList, "acceptBulk.csv");
  }

  downloadSampleBulkNdrCSV(): void {
    const bulkNdrList = [
      {
        "Last Tracking Date": moment().format("DD-MM-YYYY"),
        "NDR Date": moment().format("DD-MM-YYYY"),
        "Order ID": "1234567890",
        "Order Number": "1234560",
        AWB: "AWB1234567",
        "Courier Name": "Delhivery",
        "Courier Type": "Dummy Type",
        "Courier Service": "Surface",
        Mode: "Dummy Mode",
        "Payment Type": "PPD or COD",
        "Customer Name": "Rahul Kumar",
        "Address Line 1": "house no 213 A",
        "Address Line 2": "pqr colony",
        Pincode: "112233",
        City: "New Delhi",
        State: "New Delhi",
        Phone: "9898989800",
        Email: "abc@xyz.com",
        Status: "NDR",
        "Last Tracking Remark": "Dummy Remark",
        "Count of Days in Destination": "1",
        "Number of Attempts": "2",
        "Carrier Call Recording URL(If Available)": "https://audio.mp3",
        "Carrier Geo Location Date(If Available)": "https://location.date",
        // "Action(RTO/ Reattempt)": "RTO/RAD",
        "Shipper’s Comment / Remark": "Dummy Comment",
        "Alternate Contact Number": "9090909900",
        "Additional Address Info": "Near HDFC Bank",
        "Address Google Location URL": "https://google.location/url",
        "Consignee Call Audio Recording URL": "https://www.call.url",
        "Consignee Chat / Email Image URL": "https://image.url"
      },
      {
        "Last Tracking Date": moment().format("DD-MM-YYYY"),
        "NDR Date": moment().format("DD-MM-YYYY"),
        "Order ID": "1234567890",
        "Order Number": "1234560",
        AWB: "AWB1234567",
        "Courier Name": "Delhivery",
        "Courier Type": "Dummy Type",
        "Courier Service": "Surface",
        Mode: "Dummy Mode",
        "Payment Type": "PPD or COD",
        "Customer Name": "Rahul Kumar",
        "Address Line 1": "house no 213 A",
        "Address Line 2": "pqr colony",
        Pincode: "112233",
        City: "New Delhi",
        State: "New Delhi",
        Phone: "9898989800",
        Email: "abc@xyz.com",
        Status: "NDR",
        "Last Tracking Remark": "Dummy Remark",
        "Count of Days in Destination": "1",
        "Number of Attempts": "2",
        "Carrier Call Recording URL(If Available)": "https://audio.mp3",
        "Carrier Geo Location Date(If Available)": "https://location.date",
        // "Action(RTO/ Reattempt)": "RTO/RAD",
        "Shipper’s Comment / Remark": "Dummy Comment",
        "Alternate Contact Number": "9090909900",
        "Additional Address Info": "Near HDFC Bank",
        "Address Google Location URL": "https://google.location/url",
        "Consignee Call Audio Recording URL": "https://www.call.url",
        "Consignee Chat / Email Image URL": "https://image.url"
      }
    ];

    this.downloadCSV(bulkNdrList, "sampleBulkNdr.csv");
  }

  downloadCSV(data, fileName: string): void {
    const orderCsv: any = Papa.unparse(data, { header: true });
    const blob = new Blob([orderCsv], { type: "text/csv;charset=utf-8" });
    FileSaver.saveAs(blob, fileName);
  }
}
