import { Component, OnInit, Input, OnChanges } from "@angular/core";

@Component({
  selector: "bulk-processing",
  templateUrl: "./processing.component.html",
  styleUrls: ["./processing.component.scss"]
})
export class ProcessingComponent implements OnChanges {
  // Completed
  @Input() progressValue;

  // Bulk Processing Steps
  @Input() step;

  // No. Of Procesed Orders
  @Input() processedOrder;

  // Total Number Of Orders
  @Input() totalOrder;

  constructor() {}

  ngOnChanges(): void {
    // console.log('% Completed', this.progressValue);
    // console.log('Bulk Processing Steps', this.step);
    // console.log('No. Of Procesed Orders', this.processedOrder);
    // console.log('Total Number Of Orders', this.totalOrder);

    this.processedOrder =
      this.processedOrder <= this.totalOrder
        ? this.processedOrder
        : this.totalOrder;
    this.progressValue = this.progressValue <= 100 ? this.progressValue : 100;
  }
}
