import { NgModule } from "@angular/core";
import { FuseSharedModule } from "@fuse/shared.module";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatToolbarModule } from "@angular/material/toolbar";
import { FooterNavigationComponent } from "./component/footer-navigation.component";
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
  declarations: [FooterNavigationComponent],
  imports: [
    // shared
    FuseSharedModule,
    // material
    MatButtonModule,
    MatIconModule,
    MatToolbarModule,
    MatProgressSpinnerModule
  ],
  exports: [FooterNavigationComponent]
})
export class FooterNavigationModule {}
