import { Pipe, PipeTransform } from "@angular/core";
import { roundToTwoDecimals } from "./../ts/utilityFn";

@Pipe({
  name: "RupeePaise"
})
export class RupeePaisePipe implements PipeTransform {
  transform(value: number | string, convertTo: "rupee" | "paise"): number {
    if (value) {
      const money = Number(value);

      if (convertTo === "rupee") {
        return roundToTwoDecimals(money / 100);
      } else if (convertTo === "paise") {
        return roundToTwoDecimals(money * 100);
      }
    }

    return 0;
  }
}
