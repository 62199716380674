import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree
} from "@angular/router";
import { Observable, of } from "rxjs";
import { environment } from "environments/environment";
import { delay } from "rxjs/operators";

@Injectable({
  providedIn: "root"
})
export class CustomTrackingRedirectGuard implements CanActivate {
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const { orderId, awb } = next.params || {};
    const { apiEndPoint } = environment;

    if (apiEndPoint && orderId && awb) {
      const redirectUrl = `${apiEndPoint}api/track/${orderId}/${awb}`;
      window.location.replace(redirectUrl);

      return of(false).pipe(delay(3000));
    }

    return true;
  }
}
