import { Component, Inject, OnInit, ViewEncapsulation } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { FetchFilesService } from "../../services/fetch-files.service";
import { OrdersDataService } from "../../services/orders-data.service";
import { SidebarStatsService } from "../../services/sidebar-stats.service";
@Component({
  selector: "app-request-feedback",
  templateUrl: "./request-feedback.component.html",
  styleUrls: [
    "./request-feedback.component.scss",
    "./../../scss/responsive-modals.scss"
  ],
  encapsulation: ViewEncapsulation.None
})
export class RequestFeedbackComponent implements OnInit {
  order: any;
  loadingIndicator = true;

  performedBy = {
    0: "Consignee",
    1: "Merchant",
    2: "Admin"
  };

  trackType = {
    0: "In Transit",
    1: "NDR",
    2: "RTO",
    3: "Return"
  };

  status = {
    0: "Approved",
    "-1": "Reject",
    1: "Pending"
  };

  type = {
    neighbour: "Neighbour",
    alternativeaddress: "Alternative Address",
    security: "Security",
    relative: "Relative"
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _sidebarStatsService: SidebarStatsService,
    private _dialogRef: MatDialogRef<RequestFeedbackComponent>,
    private _fetchFilesService: FetchFilesService,
    private _orderDataService: OrdersDataService
  ) {}

  ngOnInit(): void {
    this.order = this.data;
    this._sidebarStatsService.viewFeedbackRequest({
      type: this.data?.type,
      SG_ORDERID: this.data?.SG_ORDERID
    });
  }

  fetchRequestDocument(file, sgid) {
    const body = {
      SGID: sgid,
      fileName: file,
      type: "buffer"
    };

    this._fetchFilesService.fetchAndDownloadFile(body);
  }

  closeModal() {
    if (this.data?.unread) {
      this._orderDataService.refreshOrders();
    }
    this._dialogRef.close(true);
  }
}
