<div class="dialog-content-wrapper">
  <mat-toolbar matDialogTitle class="mat-accent m-0">
    <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
      <span class="title dialog-title">{{ data.heading }}</span>
      <button mat-icon-button (click)="closeModal()" aria-label="Close dialog">
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar-row>
  </mat-toolbar>

  <div
    mat-dialog-content
    [ngSwitch]="data.heading"
    class="p-24 pb-12 m-0"
    fusePerfectScrollbar
  >
    <ng-container *ngSwitchCase="'Request'">
      <div *ngFor="let request of order?.responses" class="cs-border">
        <div
          fxLayout="row"
          fxLayout.lt-sm="column"
          fxLayoutGap="0px"
          fxLayoutGap.gt-xs="20px"
        >
          <div fxFlex>
            <p *ngIf="request?.performedBy">
              <span class="font-weight-900">Performed By: </span
              >{{ performedBy[request?.performedBy] || "N/A" }}
            </p>
            <p *ngIf="request?.question">
              <span class="font-weight-900">Request: </span
              >{{ request?.question }}
            </p>
            <p *ngIf="request?.date">
              <span class="font-weight-900">Date: </span
              >{{ request?.date | date: "medium" }}
            </p>
            <p *ngIf="request?.preferredDate">
              <span class="font-weight-900">Preferred Delivery Date: </span
              >{{ request?.preferredDate | date: "mediumDate" }}
            </p>
            <p *ngIf="request?.attemptCounts">
              <span class="font-weight-900">Attempts: </span
              >{{ request?.attemptCounts }}
            </p>
            <p *ngIf="request?.fullName">
              <span class="font-weight-900">Name: </span>{{ request?.fullName }}
            </p>
            <p *ngIf="request?.addressLine1 || request?.addressLine2">
              <span class="font-weight-900">Address: </span
              >{{ request?.addressLine1 }}, {{ request?.addressLine2 }}
            </p>
            <p *ngIf="request?.state">
              <span class="font-weight-900">State: </span>{{ request?.state }}
            </p>
            <p *ngIf="request?.landmark">
              <span class="font-weight-900">Landmark: </span
              >{{ request?.landmark }}
            </p>
          </div>

          <div fxFlex>
            <p *ngIf="request?.trackType">
              <span class="font-weight-900">Track Type: </span
              >{{ trackType[request?.trackType] || "N/A" }}
            </p>
            <p *ngIf="request?.response">
              <span class="font-weight-900">Response: </span
              >{{ request?.response || "NA" }}
            </p>
            <p
              *ngIf="
                request?.status === 0 ||
                request?.status === -1 ||
                request?.status === 1
              "
            >
              <span class="font-weight-900">Status: </span
              >{{ status[request?.status] || "N/A" }}
            </p>
            <p *ngIf="request?.type">
              <span class="font-weight-900">Type: </span
              >{{ type[request?.type] || request?.type | camelToStartCase }}
            </p>
            <p *ngIf="request?.remarks">
              <span class="font-weight-900">Remarks: </span
              >{{ request?.remarks }}
            </p>
            <p *ngIf="request?.mobileNumber">
              <span class="font-weight-900">Phone Number: </span
              >{{ request?.mobileNumber }}
            </p>
            <p *ngIf="request?.additionalMobileNumber">
              <span class="font-weight-900">Alternate Phone No.: </span
              >{{ request?.additionalMobileNumber }}
            </p>
            <p *ngIf="request?.pincode">
              <span class="font-weight-900">Pincode: </span
              >{{ request?.pincode }}
            </p>
            <p *ngIf="request?.location">
              <span class="font-weight-900">Location: </span
              >{{ request?.location }}
            </p>
          </div>
        </div>

        <ng-container
          *ngIf="
            request?.video ||
            request?.image1 ||
            request?.image2 ||
            request?.image3 ||
            request?.image4
          "
        >
          <h3 class="accent-fg text-uppercase font-weight-600 text-italic">
            Documents:
          </h3>

          <div class="border border-radius-4 py-4 px-8 table-bdr">
            <table>
              <tr>
                <th>Document Name</th>
                <th>File Name</th>
                <th>Action</th>
              </tr>
              <tr *ngIf="request?.video">
                <td>Video</td>
                <td>{{ request?.video }}</td>
                <td>
                  <button
                    mat-icon-button
                    color="primary"
                    (click)="fetchRequestDocument(request?.video, order?.SGID)"
                  >
                    <mat-icon>get_app</mat-icon>
                  </button>
                </td>
              </tr>
              <tr *ngIf="request?.image1">
                <td>Image</td>
                <td>{{ request?.image1 }}</td>
                <td>
                  <button
                    mat-icon-button
                    color="primary"
                    (click)="fetchRequestDocument(request?.image1, order?.SGID)"
                  >
                    <mat-icon>get_app</mat-icon>
                  </button>
                </td>
              </tr>
              <tr *ngIf="request?.image2">
                <td>Image</td>
                <td>{{ request?.image2 }}</td>
                <td>
                  <button
                    mat-icon-button
                    color="primary"
                    (click)="fetchRequestDocument(request?.image2, order?.SGID)"
                  >
                    <mat-icon>get_app</mat-icon>
                  </button>
                </td>
              </tr>
              <tr *ngIf="request?.image3">
                <td>Image</td>
                <td>{{ request?.image3 }}</td>
                <td>
                  <button
                    mat-icon-button
                    color="primary"
                    (click)="fetchRequestDocument(request?.image3, order?.SGID)"
                  >
                    <mat-icon>get_app</mat-icon>
                  </button>
                </td>
              </tr>
              <tr *ngIf="request?.image4">
                <td>Image</td>
                <td>{{ request?.image4 }}</td>
                <td>
                  <button
                    mat-icon-button
                    color="primary"
                    (click)="fetchRequestDocument(request?.image4, order?.SGID)"
                  >
                    <mat-icon>get_app</mat-icon>
                  </button>
                </td>
              </tr>
            </table>
          </div>
        </ng-container>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="'Feedback'">
      <div *ngFor="let request of order?.responses" class="cs-border">
        <p>
          <span class="font-weight-900">Questions: </span
          >{{ request?.question || request?.heading }}
        </p>
        <p>
          <span class="font-weight-900">Rating: </span>

          <span
            *ngIf="
              request?.responseType === 'string' ||
                request?.responseType === 'String';
              else numberType
            "
            >{{ request?.response }}</span
          >
          <ng-template #numberType>
            <span>{{ request?.response }}/5</span>
          </ng-template>
        </p>
        <p *ngIf="request?.date">
          <span class="font-weight-900">Date: </span
          >{{ request?.date | date: "medium" }}
        </p>
      </div>
    </ng-container>
  </div>
</div>
