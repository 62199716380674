import {
  HttpInterceptor,
  HttpResponse,
  HttpRequest,
  HttpHandler,
  HttpEvent
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { LoginService } from "../services/login.service";
import { of } from "rxjs";
import { environment } from "environments/environment";
import { Observable } from "rxjs";

const apiEndPoint = environment.apiEndPoint;
@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  loginError = of(
    new HttpResponse({
      status: 200,
      body: {
        success: false,
        msg: "Please Login."
      }
    })
  );

  private withoutEndpoint = ["assets/"];
  private withoutToken = [
    ...this.withoutEndpoint,
    "user/registration/register",
    "user/registration/login",
    "user/registration/checkCred",
    "user/config/forgotPassword",
    "user/config/updatePassword",
    "user/config/instaMojo/createAuthLink",
    "user/config/instaMojo/createUserAddStore",
    "user/registration/changeAccount",
    "user/registration/accept-gmail",
    "user/config/kyc",
    "user/config/updateKyc"
  ];

  constructor(private loginService: LoginService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const reqUrl = req.url;
    const urlWithEndPoint = apiEndPoint + reqUrl;
    let duplicateReq: HttpRequest<any> = req;

    if (this.isEndpointNeeded(reqUrl)) {
      duplicateReq = duplicateReq.clone({ url: urlWithEndPoint });
    }

    if (this.isTokenNeeded(reqUrl)) {
      const token = this.loginService.getToken();

      if (!token) {
        this.loginService.logout();
        return this.loginError;
      } else {
        duplicateReq = duplicateReq.clone({
          headers: req.headers.set("x-access-token", token)
        });
      }
    }

    // console.log("reqURL:", duplicateReq.url);

    return next.handle(duplicateReq);
  }

  isEndpointNeeded(reqUrl: string): boolean {
    let endpointNeeded = true;

    for (const url of this.withoutEndpoint) {
      if (reqUrl.includes(url)) {
        endpointNeeded = false;
        break;
      }
    }

    return endpointNeeded;
  }

  isTokenNeeded(reqUrl: string): boolean {
    let tokenNeeded = true;

    for (const url of this.withoutToken) {
      if (reqUrl.includes(url)) {
        tokenNeeded = false;
        break;
      }
    }

    return tokenNeeded;
  }
}
