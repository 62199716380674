import { Pipe, PipeTransform } from "@angular/core";
import { status } from "./../ts/status";

@Pipe({
  name: "statuspipe"
})
export class StatusPipe implements PipeTransform {
  transform(value: string | number): string {
    return status[value] || value;
  }
}
