import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree
} from "@angular/router";
import { Observable, of } from "rxjs";
import { tap } from "rxjs/operators";
import { LoginService } from "../services/login.service";

@Injectable({
  providedIn: "root"
})
export class DashboardGuard implements CanActivate, CanLoad {
  constructor(private _loginService: LoginService, private _router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.commonGuardLogic();
  }

  canLoad(
    route: Route,
    segments: UrlSegment[]
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.commonGuardLogic();
  }

  commonGuardLogic(): Observable<boolean> | boolean {
    const userLoggedIn = this._loginService.isUserLoggedIn();
    const otpVerifyState = this._loginService.isOtpVerifyState();

    if (userLoggedIn) {
      if (otpVerifyState) {
        return of(false).pipe(
          tap(() => this._router.navigateByUrl("/otp-verify"))
        );
      }

      return of(false).pipe(
        tap(() => {
          this._router.navigateByUrl("/orders");
        })
      );
    }

    return true;
  }
}
