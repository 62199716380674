import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { NotificationService } from "./notification.service";
import { shippigoConfig } from "tenants/shippigo/config";
import { emizaConfig } from "tenants/emiza/config";

@Injectable({
  providedIn: "root"
})
export class SwitchPanelService {
  constructor(
    private _http: HttpClient,
    private _notification: NotificationService
  ) {}

  switchToAnotherPanel(switchTo: string, token: string) {
    this._http
      .post("user/registration/switchPanel", token)
      .subscribe((res: any) => {
        if (res.success) {
          const { token } = res;
          if (switchTo === "shippigo") {
            const shippigoLoginUrl = `${shippigoConfig.origin}/login?token=${token}`;
            window.open(shippigoLoginUrl);
          } else if (switchTo === "emiza") {
            const emizaLoginUrl = `${emizaConfig.origin}/login?token=${token}`;
            window.open(emizaLoginUrl);
          }
        } else {
          this._notification.show(res.msg);
        }
      });
  }
}
