import { NgModule } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatToolbarModule } from "@angular/material/toolbar";
import { FuseSharedModule } from "@fuse/shared.module";
import { ErrorMessageModule } from '../../modules/error-message/error-message.module';
import { AddInstructionComponent } from "./component/add-instruction.component";

@NgModule({
    declarations: [AddInstructionComponent],
    imports: [
        // fuse
        FuseSharedModule,
        //shared
        ErrorMessageModule,
        // material
        MatButtonModule,
        MatIconModule,
        MatDialogModule,
        MatFormFieldModule,
        MatInputModule,
        MatToolbarModule
    ]
})
export class AddInstructionModule {}
