<mat-toolbar class="p-0 mat-elevation-z1">
  <div fxFlex fxFill fxLayout="row" fxLayoutAlign="start center">
    <div fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="start center">
      <button
        mat-icon-button
        class="navbar-toggle-button"
        *ngIf="!hiddenNavbar && !rightNavbar"
        (click)="toggleSidebarOpen('navbar')"
        fxHide.gt-md
      >
        <mat-icon class="secondary-text">menu</mat-icon>
      </button>

      <div
        class="toolbar-separator"
        *ngIf="!hiddenNavbar && !rightNavbar"
        fxHide.gt-md
      ></div>

      <div fxLayout="row" fxLayoutAlign="start center" *ngIf="horizontalNavbar">
        <div class="logo ml-16">
          <img class="logo-icon" [src]="tenantConfig.darkLogos.square" />
        </div>
      </div>

      <div class="px-8" fxLayout="row" fxLayoutAlign="start center">
        <button
          mat-button
          class="h-40"
          routerLink="/settings/accounting"
          [queryParams]="{ tab: 'stamps' }"
          title="Shipping Points Balance"
          *ngIf="profileAvailable"
        >
          <div fxLayout="row" fxLayoutAlign="start center">
            <mat-icon
              fxShow
              fxHide.lt-sm
              [ngClass]="{
                'warn-fg': profile?.balance <= 0,
                'secondary-text': profile?.balance > 0
              }"
              class="mr-4 wallet-balance-icon"
            >
              account_balance_wallet
            </mat-icon>
            <span
              fxLayout.lt-sm="column"
              [ngClass]="{ 'warn-fg': profile?.balance <= 0 }"
            >
              <span>
                {{ profile?.balance | RupeePaise: "rupee" | currency: "INR" }}
              </span>
              <span class="ml-4" *ngIf="profile?.balance <= 0">
                Please recharge!
              </span>
            </span>
          </div>
        </button>

        <button
          *ngIf="showRefreshBalanceButton && profile?.balance > 0"
          mat-icon-button
          type="button"
          title="Refresh balance"
          (click)="refreshProfile()"
          [class.loading]="refreshingBalance"
          [disabled]="refreshingBalance"
        >
          <mat-icon class="secondary-text">autorenew</mat-icon>
        </button>
      </div>

      <!-- <div class="px-8 px-md-16">
        <fuse-shortcuts [navigation]="navigation"></fuse-shortcuts>
      </div> -->
    </div>

    <div fxFlex="0 1 auto" fxLayout="row" fxLayoutAlign="start center">
      <!-- for device less than or equal to 425px show Action menu -->

      <div fxHide.gt-xs class="toolbar-separator"></div>

      <button mat-icon-button [matMenuTriggerFor]="actionMenu" fxHide.gt-xs>
        <mat-icon title="Shortcuts" class="secondary-text"
          >star_border</mat-icon
        >
      </button>

      <mat-menu
        #actionMenu="matMenu"
        xPosition="before"
        [overlapTrigger]="false"
        fxHide.gt-xs
      >
        <button mat-menu-item routerLink="/actions/track-order">
          Track Order
        </button>

        <button mat-menu-item routerLink="/actions/rate-calculator">
          Rate Calculator
        </button>

        <button mat-menu-item routerLink="/actions/manual-order">
          Add Manual Order
        </button>

        <button mat-menu-item routerLink="/actions/import-orders">
          Import Orders
        </button>

        <button mat-menu-item [matMenuTriggerFor]="bulkMenu">
          Bulk Orders
        </button>

        <mat-menu #bulkMenu="matMenu" xPosition="before">
          <button mat-menu-item routerLink="/actions/bulk-orders">
            New Bulk-Order
          </button>

          <button mat-menu-item routerLink="/batch-list/bulk">
            Bulk Batch
          </button>
        </mat-menu>
      </mat-menu>

      <!-- for devices greater than 425px show below icons -->
      <div class="px-8 px-md-16" fxHide.lt-sm>
        <toolbar-shortcuts></toolbar-shortcuts>
      </div>
      <div class="toolbar-separator"></div>

      <!-- List all active notifications -->
      <toolbar-notification *ngIf="isUserLoggedIn"></toolbar-notification>

      <div class="toolbar-separator"></div>

      <button mat-button [matMenuTriggerFor]="userMenu" class="user-button">
        <div fxLayout="row" fxLayoutAlign="center center">
          <!-- <img class="avatar mr-0 mr-sm-16" src="assets/images/avatars/Velazquez.jpg"> -->
          <span class="avatar avatar-circle mr-0 mr-sm-8 text-uppercase">
            {{ profile?.firstName?.charAt(0)
            }}{{ profile?.lastName?.charAt(0) }}
          </span>
          <!-- <span class="username mr-12" fxHide fxShow.gt-sm>Charlie Adams</span> -->
          <mat-icon class="s-16" fxHide.xs>keyboard_arrow_down</mat-icon>
        </div>
      </button>

      <mat-menu #userMenu="matMenu" xPosition="before" [overlapTrigger]="false">
        <button mat-menu-item routerLink="settings">
          <mat-icon>settings</mat-icon>
          <span>Settings</span>
        </button>

        <button mat-menu-item [matMenuTriggerFor]="colorMenu">
          <mat-icon>color_lens</mat-icon>
          <span>Theme</span>
        </button>

        <mat-menu #colorMenu="matMenu">
          <button mat-menu-item (click)="setTheme('theme-default')">
            Light
            <mat-icon color="accent" class="align-icon" *ngIf="lightTheme"
              >check_circle_outline</mat-icon
            >
          </button>
          <button mat-menu-item (click)="setTheme('theme-blue-gray-dark')">
            Dark
            <mat-icon color="accent" class="align-icon" *ngIf="!lightTheme"
              >check_circle_outline</mat-icon
            >
          </button>
        </mat-menu>

        <ng-container *ngIf="clients.length">
          <button mat-menu-item [matMenuTriggerFor]="clientMenu">
            <mat-icon>people</mat-icon>
            <span>Switch Account</span>
          </button>

          <mat-menu #clientMenu="matMenu">
            <button
              mat-menu-item
              *ngFor="let client of clients"
              (click)="switchToAnotherUser(client.SGID)"
            >
              {{ client.brandName }} - {{ client.email }}
            </button>
          </mat-menu>
        </ng-container>

        <!-- Switch Panel -->
        <ng-container *ngIf="isSwitchPanelVisible">
          <button mat-menu-item [matMenuTriggerFor]="clientMenu">
            <mat-icon>dashboard</mat-icon>
            <span>Switch Dashboard</span>
          </button>

          <mat-menu #clientMenu="matMenu">
            <button
              mat-menu-item
              (click)="switchToAnotherPanel('emiza')"
              *ngIf="tenantConfig?.name === 'shippigo'"
            >
              Emiza
            </button>

            <button
              mat-menu-item
              (click)="switchToAnotherPanel('shippigo')"
              *ngIf="tenantConfig?.name === 'emiza'"
            >
              Shippigo
            </button>
          </mat-menu>
        </ng-container>

        <!-- <button mat-menu-item (click)="goToOldDashboard()">
          <mat-icon>eject</mat-icon>
          <span>Go to old Dashboard</span>
        </button> -->

        <button mat-menu-item (click)="logout()">
          <mat-icon>exit_to_app</mat-icon>
          <span>Logout</span>
        </button>
      </mat-menu>

      <!-- <fuse-search-bar (inputChanged)="search($event)"></fuse-search-bar>

      <div class="toolbar-separator"></div>

      <button
        mat-button
        fxHide
        fxShow.gt-xs
        class="language-button"
        [matMenuTriggerFor]="languageMenu"
      >
        <div fxLayout="row" fxLayoutAlign="center center">
          <img
            class="flag mr-8"
            [src]="'assets/icons/flags/' + selectedLanguage.flag + '.png'"
          />
          <span class="iso text-uppercase">{{ selectedLanguage.id }}</span>
        </div>
      </button>

      <mat-menu #languageMenu="matMenu" [overlapTrigger]="false">
        <button
          mat-menu-item
          *ngFor="let lang of languages"
          (click)="setLanguage(lang)"
        >
          <span fxLayout="row" fxLayoutAlign="start center">
            <img
              class="flag mr-16"
              [src]="'assets/icons/flags/' + lang.flag + '.png'"
            />
            <span class="iso">{{ lang.title }}</span>
          </span>
        </button>
      </mat-menu> -->

      <!-- <div class="toolbar-separator"></div> -->

      <!-- <button
        mat-icon-button
        class="quick-panel-toggle-button"
        (click)="toggleSidebarOpen('quickPanel')"
        aria-label="Toggle quick panel"
      >
        <mat-icon class="secondary-text">format_list_bulleted</mat-icon>
      </button> -->

      <!-- <div
        class="toolbar-separator"
        *ngIf="!hiddenNavbar && rightNavbar"
        fxHide
        fxShow.gt-xs
      ></div>

      <button
        mat-icon-button
        class="navbar-toggle-button"
        *ngIf="!hiddenNavbar && rightNavbar"
        (click)="toggleSidebarOpen('navbar')"
        fxHide.gt-md
      >
        <mat-icon class="secondary-text">menu</mat-icon>
      </button> -->
    </div>
  </div>
</mat-toolbar>
