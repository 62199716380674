<div class="dialog-content-wrapper">
  <mat-toolbar matDialogTitle class="mat-accent m-0">
    <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
      <span class="title dialog-title">Supported Browsers</span>
      <button
        mat-icon-button
        [mat-dialog-close]="false"
        aria-label="Close dialog"
      >
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar-row>
  </mat-toolbar>
  <div mat-dialog-content class="p-8 m-0" fusePerfectScrollbar>
    <ul>
      <li>Google Chrome version 90 and above.</li>
      <li>Mozilla Firefox version 88 and above.</li>
      <li>Microsoft Edge version 90 and above.</li>
      <li>Apple Safari version 13 and above.</li>
    </ul>

    <!-- <p class="text-italic ml-24">
      Support for more browsers will be added soon.
    </p> -->
  </div>
</div>
