import { FuseNavigation } from "@fuse/types";

export const navigation: FuseNavigation[] = [
  {
    id: "dashboard",
    title: "Dashboard",
    type: "group",
    children: [
      // {
      //   id: "analytics",
      //   title: "Analytics",
      //   type: "item",
      //   icon: "insert_chart_outlined",
      //   url: "/dashboards/analytics"
      // },
      {
        id: "actions",
        title: "Actions",
        type: "collapsable",
        icon: "open_in_new",
        children: [
          // {
          //   id: "manual",
          //   title: "Add Manual Order",
          //   type: "collapsable",
          //   // url: "actions/manual-order/b2c",
          //   icon: "add",
          //   children: [
          //     {
          //       id: "b2b",
          //       title: "B2B",
          //       type: "item",
          //       icon: "contact_support",
          //       url: "actions/manual-order/b2b"
          //     },
          //     {
          //       id: "b2c",
          //       title: "B2C",
          //       type: "item",
          //       icon: "contact_support",
          //       url: "actions/manual-order/b2c"
          //     }
          //   ]
          // },
          {
            id: "import",
            title: "Import Orders",
            type: "item",
            url: "actions/import-orders",
            icon: "add_to_queue"
          },
          {
            id: "bulk",
            title: "Bulk Orders",
            type: "item",
            url: "actions/bulk-orders",
            icon: "queue_play_next"
          },
          {
            id: "calculator",
            title: "Rate Calculator",
            type: "item",
            url: "actions/rate-calculator",
            svgIcon: "calculator-alt"
          },
          {
            id: "track",
            title: "Track Order",
            type: "item",
            url: "actions/track-order",
            icon: "room"
          },
          {
            id: "download-csv",
            title: "Download CSV",
            type: "item",
            url: "actions/download-csv",
            icon: "get_app"
          }
        ]
      },
      {
        id: "orders",
        title: "Orders",
        type: "collapsable",
        icon: "shopping_basket",
        children: [
          {
            id: "all",
            title: "All",
            type: "item",
            url: "orders/all",
            icon: "spellcheck"
          },
          {
            id: "pending",
            title: "Pending",
            type: "item",
            icon: "access_time",
            url: "orders/pending"
          },
          {
            id: "booked",
            title: "Booked",
            type: "item",
            icon: "shopping_cart",
            url: "orders/booked"
          },
          {
            id: "ready-to-ship",
            title: "Ready to Ship",
            type: "item",
            icon: "markunread_mailbox",
            url: "orders/ready-to-ship"
          },
          {
            id: "shipped",
            title: "Shipped",
            type: "item",
            icon: "local_shipping",
            url: "orders/shipped"
          },
          {
            id: "ndr",
            title: "NDR (Beta)",
            type: "item",
            icon: "report",
            url: "orders/ndr"
            // children: [
            //   {
            //     id: "pending",
            //     title: "Pending",
            //     type: "item",
            //     icon: "assignment_late",
            //     url: "orders/ndr/pending"
            //   },
            //   {
            //     id: "closed",
            //     title: "Closed",
            //     type: "item",
            //     icon: "assignment_turned_in",
            //     url: "orders/ndr/closed"
            //   }
            // ]
          },
          {
            id: "delivered",
            title: "Delivered",
            type: "item",
            icon: "check_box",
            url: "orders/delivered"
          }
        ]
      },
      {
        id: "reports",
        title: "Reports",
        type: "collapsable",
        icon: "list_alt",
        children: [
          {
            id: "return-to-origin",
            title: "Return to Origin",
            type: "item",
            icon: "reply_all",
            url: "reports/rto"
          },
          {
            id: "reverse",
            title: "Reverse",
            type: "item",
            icon: "swap_horiz",
            url: "reports/reverse"
          },
          {
            id: "delivery",
            title: "Delivery",
            type: "item",
            icon: "playlist_add_check",
            url: "reports/delivery"
          },
          {
            id: "weight-discrepancy",
            title: "Weight Discrepancy (Beta)",
            type: "item",
            icon: "disc_full",
            url: "reports/weight-discrepancy"
          },
          {
            id: "flagged",
            title: "Flagged",
            type: "item",
            icon: "flag",
            url: "reports/flagged"
          }
        ]
      },
      {
        id: "batches",
        title: "Batches",
        type: "collapsable",
        icon: "dns",
        children: [
          {
            id: "bulk",
            title: "Bulk Batch",
            type: "item",
            url: "batch-list/bulk"
          }
        ]
      },
      {
        id: "transactions",
        title: "Transactions",
        type: "collapsable",
        icon: "monetization_on",
        children: [
          {
            id: "summary",
            title: "Transactions Summary",
            type: "item",
            icon: "assignment",
            url: "transactions/summary"
          },
          {
            id: "codremit",
            title: "COD Remittance",
            type: "item",
            icon: "payment",
            url: "transactions/codremit"
          }
          // {
          //   id: "transactionsStatus",
          //   title: "Transaction Status",
          //   type: "item",
          //   icon: "payment",
          //   url: "transactions/transaction-status"
          // }
        ]
      },
      {
        id: "settings",
        title: "Settings",
        type: "item",
        icon: "settings",
        url: "settings"
        // children: [
        //   {
        //     id: "profile",
        //     title: "Profile",
        //     type: "item",
        //     icon: "account_circle",
        //     url: "settings/profile"
        //   },
        //   {
        //     id: "shipping",
        //     title: "Shipping",
        //     type: "item",
        //     icon: "shopping_cart",
        //     url: "settings/shipping"
        //   },
        //   {
        //     id: "accounting",
        //     title: "Accounting",
        //     type: "item",
        //     icon: "account_balance",
        //     url: "settings/accounting"
        //   }
        //   // {
        //   //   id: "developer",
        //   //   title: "Developer",
        //   //   type: "item",
        //   //   icon: "code",
        //   //   url: "settings/developer"
        //   // }
        // ]
      }
      // {
      //   id: "support",
      //   title: "Support",
      //   type: "collapsable",
      //   icon: "headset_mic",
      //   children: [
      //     {
      //       id: "add-tickets",
      //       title: "Raise an Issue",
      //       type: "item",
      //       icon: "contact_support",
      //       url: "support/ticket"
      //     },
      //     {
      //       id: "view-tickets",
      //       title: "View Tickets",
      //       type: "item",
      //       icon: "local_play",
      //       url: "support/ticket"
      //     }
      //   ]
      // }
    ]
  }
];
