<div
  *ngIf="showBanner"
  fxLayout="row"
  fxLayoutAlign="center center"
  class="background-color"
>
  <!-- // blank div for alignment fix -->
  <!-- <div></div> -->
  <!-- <div fxLayout="row" fxLayoutAlign="center center"> -->
  <p class="my-4 py-4 text-black">
    {{ bannerContent?.content }}
    <span
      *ngIf="bannerContent?.action"
      class="action"
      (click)="takeAction(bannerContent?.action)"
      >{{ bannerContent?.actionContent }}</span
    >
  </p>
  <!-- </div> -->
  <!-- <button mat-icon-button color="primary" (click)="closeBanner()">
      <mat-icon>close</mat-icon>
    </button> -->
</div>
