import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { FullScreenLoaderService } from "../modules/full-screen-loader/full-screen-loader.service";
import { convertBufferIntoFile } from "../ts/utilityFn";
import * as FileSaver from "file-saver";
import { NotificationService } from "./notification.service";

@Injectable({
  providedIn: "root"
})
export class FetchFilesService {
  constructor(
    private _http: HttpClient,
    private _fullScreenLoader: FullScreenLoaderService,
    private _notification: NotificationService
  ) {}

  fetchFilesAPI = (body) => this._http.post("user/config/fetchFiles", body);

  fetchAndDownloadFile(body) {
    this._fullScreenLoader.show();
    this.fetchFilesAPI(body).subscribe((res: any) => {
      this._fullScreenLoader.hide();
      if (res.success) {
        let documentFile = convertBufferIntoFile(res?.data);
        FileSaver.saveAs(documentFile);
        this._notification.show("Document downloaded successfully");
      } else {
        this._notification.show(res?.msg);
      }
    });
  }
}
