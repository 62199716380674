import { NgModule } from "@angular/core";
import { CustomAlertComponent } from "./component/custom-alert.component";
import { FuseSharedModule } from "@fuse/shared.module";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatDialogModule } from "@angular/material/dialog";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";

@NgModule({
  declarations: [CustomAlertComponent],
  imports: [
    FuseSharedModule,
    MatToolbarModule,
    MatDialogModule,
    MatIconModule,
    MatButtonModule
  ]
})
export class CustomAlertModule {}
