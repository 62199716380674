import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

export interface bannerContent {
  content: string;
  action?: "close" | "navigate" | "openModal";
  actionContent?: string;
  url?: string;
  logout?: boolean;
}

@Injectable({
  providedIn: "root"
})
export class BannerMessageService {
  showBanner: BehaviorSubject<boolean> = new BehaviorSubject(false);
  bannerContent: BehaviorSubject<any> = new BehaviorSubject({});
  constructor() {}

  showBannerMessage(content: bannerContent): void {
    this.showBanner.next(true);
    this.bannerContent.next(content);
  }

  hideBannerMessage(): void {
    this.showBanner.next(false);
  }
}
