import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { NotificationService } from "./notification.service";
@Injectable({
  providedIn: "root"
})
export class SidebarStatsService {
  moduleDataCount: BehaviorSubject<any> = new BehaviorSubject({});

  constructor(
    private _http: HttpClient,
    private _notification: NotificationService
  ) {}

  refreshCounts() {
    this.getCounts();
  }

  getCounts(): void {
    this._http.get("user/deliveryManager/reqfeedstats").subscribe(
      (res: any) => {
        if (res.success && !!res.count) {
          this.moduleDataCount.next(res.count);
        } else {
          this.moduleDataCount.next({});
        }
      },
      () => {
        this.moduleDataCount.next({});
      }
    );
  }

  viewFeedbackRequest(body) {
    this._http
      .post("user/deliveryManager/statsViewUpdate", body)
      .subscribe((res: any) => {
        if (res.success) {
          this.refreshCounts();
        } else {
          this._notification.show(res?.msg || res?.message);
        }
      });
  }
}
