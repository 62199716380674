import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ItemsModalForWdComponent } from "./items-modal-for-wd/items-modal-for-wd.component";
import { FuseSharedModule } from "@fuse/shared.module";
import { MatDialogModule } from "@angular/material/dialog";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { PipesModule } from "../../pipes/pipes.module";

@NgModule({
  declarations: [ItemsModalForWdComponent],
  imports: [
    CommonModule,
    FuseSharedModule,
    MatDialogModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    PipesModule
  ]
})
export class ItemsModalForWdModule {}
