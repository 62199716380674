<div class="dialog-content-wrapper">
  <mat-toolbar matDialogTitle class="mat-accent m-0">
    <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
      <span class="title dialog-title">KYC Notification</span>
      <button
        mat-icon-button
        [mat-dialog-close]="false"
        aria-label="Close dialog"
      >
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar-row>
  </mat-toolbar>

  <div mat-dialog-content class="p-24 m-0" fusePerfectScrollbar>
    <p>
      Please verify your KYC status to get full access to the dashboard.
      <!-- <span class="mat-accent mx-4" (click)="navigationUrl()"> Click Here</span> -->
    </p>
  </div>

  <div
    mat-dialog-actions
    class="m-0 p-16"
    fxLayout="row"
    fxLayoutAlign="end center"
  >
    <button mat-stroked-button color="primary" (click)="navigationUrl()">
      Verify Now
    </button>
  </div>
</div>
