import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { OrderLogComponent } from "./order-log/order-log.component";
import { FuseSharedModule } from "@fuse/shared.module";
import { MatInputModule } from "@angular/material/input";
import { MatIconModule } from "@angular/material/icon";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatDialogModule } from "@angular/material/dialog";
import { MatButtonModule } from "@angular/material/button";
import { PipesModule } from "../../pipes/pipes.module";

@NgModule({
  declarations: [OrderLogComponent],
  imports: [
    CommonModule,
    FuseSharedModule,
    MatInputModule,
    MatIconModule,
    MatToolbarModule,
    MatButtonModule,
    MatDialogModule,
    PipesModule
  ]
})
export class OrderLogModule {}
