import { Pipe, PipeTransform } from "@angular/core";
import * as moment from "moment";

@Pipe({
  name: "transitDays"
})
export class TransitDaysPipe implements PipeTransform {
  transform(order: any, notFoundSpecifier = "N/A"): string {
    const noTransitDaysStatus = [-1, 2, 3, 14, 7, 21, 52, 53, 100];

    const today = moment();
    let { pickedDate, status, deliveredOn } = order || {};
    let transitDays: number;

    if (noTransitDaysStatus.includes(status)) {
      return notFoundSpecifier;
    }

    if (pickedDate) {
      // changing picked up time to midnight
      pickedDate = moment(pickedDate).endOf("day");

      // if order not delivered
      if (status !== 0) {
        transitDays = today.diff(pickedDate, "days");
      }

      // if order delivered
      if (deliveredOn) {
        transitDays = moment(deliveredOn).diff(pickedDate, "days");
      }

      // if transitDays less than 1
      if (transitDays < 1) {
        return "< 1";
      }

      return transitDays ? transitDays.toString() : notFoundSpecifier;
    }

    return notFoundSpecifier;
  }
}
