import { NgModule } from "@angular/core";
import { MatBadgeModule } from "@angular/material/badge";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatRippleModule } from "@angular/material/core";
import { MatDividerModule } from "@angular/material/divider";
import { MatIconModule } from "@angular/material/icon";
import { FuseSharedModule } from "@fuse/shared.module";
import { DirectivesModule } from "app/main/shared/directives/directives.module";
import { DateAgoModule } from "app/main/shared/pipes/date-ago/date-ago.module";
import { PipesModule } from "app/main/shared/pipes/pipes.module";
import { ToolbarNotificationComponent } from "./component/toolbar-notification.component";

@NgModule({
  declarations: [ToolbarNotificationComponent],
  imports: [
    FuseSharedModule,
    PipesModule,
    DirectivesModule,

    // DateAgoModule,

    // material modules
    MatIconModule,
    MatButtonModule,
    MatCardModule,
    MatDividerModule,
    MatBadgeModule,
    MatRippleModule
  ],
  exports: [ToolbarNotificationComponent]
})
export class ToolbarNotificationModule {}
