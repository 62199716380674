<div class="dialog-content-wrapper">
  <mat-toolbar matDialogTitle class="mat-accent m-0">
    <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
      <span class="title dialog-title">Appointment Delivery</span>
      <button
        mat-icon-button
        [mat-dialog-close]="false"
        aria-label="Close dialog"
      >
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar-row>
  </mat-toolbar>
  <ng-container *ngIf="loadingIndicator; else showOrder">
    <h2 class="error-msg">Fetching details...</h2>
  </ng-container>
  <ng-template #showOrder>
    <div mat-dialog-content class="p-24 pt-12 m-0" fusePerfectScrollbar>
      <p class="mb-24">
        Hi, You have an appointment delivery today. Please find the details
        below:
      </p>
      <div
        fxLayout="row"
        fxLayout.lt-sm="column"
        fxLayoutGap="0px"
        fxLayoutGap.gt-xs="20px"
        class="border border-radius-4 p-12"
      >
        <div fxFlex>
          <p *ngIf="order?.SG_ORDERID">
            <span>{{ tenantConfig.sName }} Shipping ID: </span>
            <span>{{ order?.SG_ORDERID }}</span>
          </p>
          <p *ngIf="order?.orderNumber">
            <span>Order Number: </span>
            <span>{{ order?.orderNumber }}</span>
          </p>
          <p *ngIf="order.awb">
            <span>AWB Number: </span>
            <span>{{ order.awb }}</span>
          </p>
          <p *ngIf="order?.b2b?.appointmentDetails?.purchaseNumber">
            <span>Purchase Order Number: </span>
            <span>
              {{ order?.b2b?.appointmentDetails?.purchaseNumber }}
            </span>
          </p>
          <p *ngIf="order?.shipping_info?.courier?.courier_service">
            <span>Courier Service: </span>
            <span>
              {{ order?.shipping_info?.courier?.courier_service }}
            </span>
          </p>
          <p
            *ngIf="
              order?.b2b?.appointmentDetails?.startDate &&
              order?.b2b?.appointmentDetails?.endDate
            "
          >
            <span>Appointment Timings: </span>
            <span
              *ngIf="
                order?.b2b?.appointmentDetails?.startDate &&
                order?.b2b?.appointmentDetails?.endDate
              "
              >{{ order?.b2b?.appointmentDetails?.startDate | date: "medium" }}
              to
              {{ order?.b2b?.appointmentDetails?.endDate | date: "medium" }}
            </span>
          </p>
          <p *ngIf="order?.b2b?.appointmentDetails?.alternateLandline">
            <span>Alternate Landline Number: </span>
            <span
              >{{ order?.b2b?.appointmentDetails?.alternateLandline }}
            </span>
          </p>
        </div>
        <div fxFlex>
          <p *ngIf="order?.b2b?.appointmentDetails?.name">
            <span>Name: </span>
            <span>
              {{ order?.b2b?.appointmentDetails?.name }}
            </span>
          </p>
          <p *ngIf="order?.b2b?.appointmentDetails?.email">
            <span>Email: </span>
            <span>
              {{ order?.b2b?.appointmentDetails?.email }}
            </span>
          </p>
          <p
            *ngIf="
              order?.b2b?.appointmentDetails?.addressLine1 &&
              order?.b2b?.appointmentDetails?.addressLine2
            "
          >
            <span>Address Line: </span>
            <span
              >{{ order?.b2b?.appointmentDetails?.addressLine1 }},
              {{ order?.b2b?.appointmentDetails?.addressLine2 }}</span
            >
          </p>
          <p *ngIf="order?.b2b?.appointmentDetails?.city">
            <span>City: </span>
            <span>{{ order?.b2b?.appointmentDetails?.city }}</span>
          </p>
          <p *ngIf="order?.b2b?.appointmentDetails?.state">
            <span>State: </span>
            <span>
              {{ order?.b2b?.appointmentDetails?.state }}
            </span>
          </p>
          <p *ngIf="order?.b2b?.appointmentDetails?.pincode">
            <span>Pincode: </span>
            <span>
              {{ order?.b2b?.appointmentDetails?.pincode }}
            </span>
          </p>
          <p *ngIf="order?.b2b?.appointmentDetails?.phone">
            <span>Phone: </span>
            <span>
              {{ order?.b2b?.appointmentDetails?.phone }}
            </span>
          </p>
          <p *ngIf="order?.b2b?.appointmentDetails?.landline">
            <span>Landline Number: </span>
            <span>
              {{ order?.b2b?.appointmentDetails?.landline }}
            </span>
          </p>
        </div>
      </div>
      <p class="mt-24 mb-0">
        Feel free to contact
        <span class="font-weight-600">{{ tenantConfig.sName }}</span
        >, in case of any issues or queries.
        <span class="accent-fg">{{ tenantConfig.email }}</span>
      </p>
    </div>
    <div class="m-0 p-24 btn-div">
      <button mat-stroked-button color="primary" (click)="takeAction()">
        Raise an Issue
      </button>
      <button mat-flat-button color="primary" mat-dialog-close>Okay</button>
    </div>
  </ng-template>
</div>
