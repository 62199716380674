import { NgModule } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatToolbarModule } from "@angular/material/toolbar";
import { FuseSharedModule } from "@fuse/shared.module";
import { PipesModule } from "../../pipes/pipes.module";
import { RequestFeedbackComponent } from "./request-feedback.component";

@NgModule({
  declarations: [RequestFeedbackComponent],
  imports: [
    FuseSharedModule,
    MatInputModule,
    MatIconModule,
    MatToolbarModule,
    MatButtonModule,
    MatDialogModule,
    PipesModule
  ]
})
export class RequestFeedbackModule {}
