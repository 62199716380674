import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { PlatformLogoPipe } from "./platform-logo.pipe";

@NgModule({
  declarations: [PlatformLogoPipe],
  imports: [CommonModule],
  exports: [PlatformLogoPipe]
})
export class PlatformLogoModule {}
