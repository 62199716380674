import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ShowHistoryForWeightDiscrepancyComponent } from "./component/show-history-for-weight-discrepancy/show-history-for-weight-discrepancy.component";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { FuseSharedModule } from "@fuse/shared.module";
import { MatDialogModule } from "@angular/material/dialog";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { FuseNavigationModule } from "@fuse/components";

@NgModule({
  declarations: [ShowHistoryForWeightDiscrepancyComponent],
  imports: [
    CommonModule,
    FuseSharedModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    MatDialogModule,
    MatProgressBarModule,
    FuseNavigationModule
  ]
})
export class ShowHistoryForWeightDiscrepancyModule {}
