import { ToolbarNotification } from "../interfaces/toolbar-notificaton";

export const allNotifications: { [key: string]: ToolbarNotification } = {
  accountBalance: {
    _id: "accountBalance",
    icon: "account_balance_wallet",
    title: "Wallet balance is below the set limit",
    description: "Minimum balance required is ₹500",
    url: "settings/accounting?tab=stamps",
    action: "changeRoute"
  },
  companyDetails: {
    _id: "companyData",
    icon: "business",
    title: "Add company details",
    description: "Mandatory for adding Warehouses",
    url: "settings/profile?tab=company-details",
    action: "changeRoute"
  },
  accountDetails: {
    _id: "accountData",
    icon: "account_balance",
    title: "Add Accounting and KYC details",
    description: "Mandatory for using the dashboard and COD Remittance.",
    url: "settings/accounting?tab=accounting-details",
    action: "changeRoute"
  },
  warehouse: {
    _id: "warehouseData",
    icon: "local_shipping",
    title: "Add Warehouse details",
    description: "Mandatory to process new order",
    url: "settings/shipping?tab=warehouses",
    action: "changeRoute"
  },
  bulkProcessFailed: {
    _id: "bulkProcess",
    icon: "queue_play_next",
    title: "Bulk process failed",
    action: "changeRoute"
  }
};
