import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { OrderActionService } from "app/main/shared/services/order-action.service";
import { NotificationService } from "app/main/shared/services/notification.service";
import { HttpClient } from "@angular/common/http";
import { GenerateCsvService } from "app/main/shared/services/generate-csv.service";
import { DataForCsvDownload } from "app/main/shared/interfaces/data-for-csv-download";
import { FullScreenLoaderService } from "app/main/shared/modules/full-screen-loader/full-screen-loader.service";
import { Router } from "@angular/router";

@Component({
  selector: "bulk-processed",
  templateUrl: "./processed.component.html",
  styleUrls: ["./processed.component.scss"]
})
export class ProcessedComponent {
  // declaration
  @Input() summaryTotalOrder = "";
  @Input() totalShippingCharge = "";
  @Input() totalWeight = "";
  @Input() unservicable = [];
  @Input() notBooked = [];
  @Input() noPickUp = [];
  @Input() pickedAWB = [];
  @Input() bulkResponse: any;
  @Output() closeDialog = new EventEmitter();

  constructor(
    private _orderActionService: OrderActionService,
    private _generateCSV: GenerateCsvService,
    private _notification: NotificationService,
    private _http: HttpClient,
    private _fullScreenLoader: FullScreenLoaderService,
    private _router: Router
  ) {}

  downloadCsv(option: string): void {
    this._fullScreenLoader.show();

    let orderIds: any[] = [];

    switch (option) {
      case "notBooked":
        orderIds = this.notBooked;
        break;
      case "unservicable":
        orderIds = this.unservicable;
        break;
      case "noPickUp":
        orderIds = this.noPickUp;
        break;

      default:
        break;
    }

    const body = { orderIds, option };

    this._http.post("user/order/specificOrders", body).subscribe((res: any) => {
      this._fullScreenLoader.hide();
      if (res.success) {
        const dataForCSV: DataForCsvDownload = {
          fileName: `${option}-orders.csv`,
          orders: res.orders
        };
        this._generateCSV.forBulkProcessed(dataForCSV);
      } else {
        this._notification.show(res.msg);
      }
    });
  }

  navigateToBatch() {
    this.closeDialog.emit();

    const { batchId } = this.bulkResponse;
    this._router.navigate(["orders", "batch", batchId]);
  }

  downloadMedia(option: "label" | "invoice" | "manifest") {
    switch (option) {
      case "label":
        this._orderActionService.generateLabel(
          [],
          "bulkOrders",
          this.bulkResponse
        );
        break;

      case "invoice":
        this._orderActionService.generateShippingInvoice(
          [],
          "bulkOrders",
          this.bulkResponse
        );
        break;

      case "manifest":
        this._orderActionService.generateManifest(
          [],
          "bulkOrders",
          this.bulkResponse
        );
        break;

      default:
        break;
    }
  }
}
