import { NgModule } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatToolbarModule } from "@angular/material/toolbar";
import { FuseSharedModule } from "@fuse/shared.module";
import { MatChipsModule } from "@angular/material/chips";
import { PipesModule } from "../../pipes/pipes.module";
import { SingleOrderDetailsComponent } from "./single-order-details.component";
import { PlatformLogoModule } from "../../pipes/platform-logo/platform-logo.module";
import { DocumentDetailsModule } from "../../modules/document-details/document-details.module";
import { MatDividerModule } from "@angular/material/divider";

@NgModule({
  declarations: [SingleOrderDetailsComponent],
  imports: [
    // fuse
    FuseSharedModule,
    // shared
    PipesModule,
    PlatformLogoModule,
    // material
    MatIconModule,
    MatChipsModule,
    MatInputModule,
    MatToolbarModule,
    MatButtonModule,
    MatDialogModule,
    DocumentDetailsModule,
    MatDividerModule
  ]
})
export class SingleOrderDetailsModule {}
