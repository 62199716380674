import { Component, OnInit, Inject, ViewEncapsulation } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import {
  FormGroup,
  FormBuilder,
  Validators,
  AbstractControl
} from "@angular/forms";
import { HttpClient } from "@angular/common/http";
import { NotificationService } from "app/main/shared/services/notification.service";
import { FullScreenLoaderService } from "app/main/shared/modules/full-screen-loader/full-screen-loader.service";

@Component({
  selector: "app-create-ticket",
  templateUrl: "./create-ticket.component.html",
  styleUrls: [
    "./create-ticket.component.scss",
    "./../../../scss/responsive-modals.scss"
  ],
  encapsulation: ViewEncapsulation.None
})
export class CreateTicketComponent implements OnInit {
  constructor(
    private _http: HttpClient,
    private _notification: NotificationService,
    private _dialogRef: MatDialogRef<CreateTicketComponent>,
    private _fb: FormBuilder,
    private _fullScreenLoader: FullScreenLoaderService,
    @Inject(MAT_DIALOG_DATA) public orderData: any
  ) {}

  // declaration
  createTicketForm: FormGroup;
  showReasonField: boolean;
  issueTypes = [
    "Order Related",
    "Unpicked Shipment",
    "Undelivered Shipment",
    "Tracking Related",
    "COD to Prepaid Convert",
    "COD Related",
    "Invoice Related",
    "Tech Related",
    "Other"
  ];
  // priorityTypes = ["Low", "Normal", "Urgent"];

  // For File Validation
  allowedFileSize = 10240; // 10 MB
  allowedFileFormats: Array<string> = [
    "png",
    "jpg",
    "jpeg",
    "pdf",
    "csv",
    "doc",
    "docx",
    "xls",
    "xlsx "
  ];
  uploadedDocuments = {};

  ngOnInit(): void {
    this.createForm();
  }

  createForm() {
    this.createTicketForm = this._fb.group({
      queryType: ["", [Validators.required]],
      orderNo: [{ value: "", disabled: this.orderData.orderNumber }],
      awb: [
        {
          value: "",
          disabled: this.orderData.awb
        }
      ],
      subject: ["", [Validators.required]],
      msg: ["", [Validators.required]],

      // document
      attachments1: ""
    });

    this.patchOrderDetails();
  }

  patchOrderDetails() {
    const { awb, orderNumber } = this.orderData;

    if (this.orderData) {
      this.createTicketForm.patchValue({
        awb: awb,
        orderNo: orderNumber
      });
    }

    // else {
    //   this.createTicketForm.get("queryType").clearValidators();
    //   this.createTicketForm.get("subject").clearValidators();
    // }
    // this.createTicketForm.get("queryType").updateValueAndValidity();
    // this.createTicketForm.get("subject").updateValueAndValidity();
  }

  uploadDocument(
    event,
    control: AbstractControl,
    fileControlName: string
  ): void {
    control.markAsTouched();
    control.markAsDirty();

    if (event.target.files.length > 0) {
      const file = event.target.value;
      const containsFile = event.target.files[0];
      const fileName = event.target.files[0].name;
      const fileSize = event.target.files[0].size / 1024;
      const fileExtention = file
        .substring(file.lastIndexOf(".") + 1)
        .toLowerCase();

      control.setValue(fileName);

      if (this.allowedFileFormats.includes(fileExtention)) {
        if (fileSize < this.allowedFileSize) {
          this.uploadedDocuments[fileControlName] = containsFile;
        } else {
          control.setErrors({ largeFile: true });
        }
      } else {
        control.setErrors({ invalidFile: true });
      }
    }
  }

  // reasonField({ value }) {
  //   this.createTicketForm.reset();
  //   this.createTicketForm.controls["queryType"].setValue(value);

  //   if (value.toLowerCase() === "other") {
  //     this.showReasonField = true;
  //     this.setValidation();
  //   } else {
  //     this.showReasonField = false;
  //     this.clearValidaton();
  //   }
  //   this.createTicketForm.get("subject").updateValueAndValidity();
  //   this.createTicketForm.get("orderNo").updateValueAndValidity();
  // }

  // clearValidaton() {
  //   this.createTicketForm.get("subject").clearValidators();
  //   this.createTicketForm.get("subject").reset();

  //   this.createTicketForm.get("orderNo").setValidators([Validators.required]);
  //   this.patchOrderDetails();
  // }

  // setValidation() {
  //   this.createTicketForm.get("subject").setValidators([Validators.required]);

  //   this.createTicketForm.get("orderNo").clearValidators();
  //   this.createTicketForm.get("orderNo").reset();
  // }

  onSubmit(): void {
    this._fullScreenLoader.show();

    const { SG_ORDERID, openedFrom } = this.orderData;

    const { attachments1, ...remaingContols } =
      this.createTicketForm.getRawValue();

    const formData = new FormData();

    formData.append("SG_ORDERID", SG_ORDERID || "");
    formData.append("ticketRaisedFrom", openedFrom);

    Object.keys(remaingContols).forEach((key) => {
      formData.append(key, remaingContols[key]);
    });

    formData.append("attachments1", this.uploadedDocuments["attachments1"]);

    this._http
      .post("user/tickets/createDash", formData)
      .subscribe((res: any) => {
        this._fullScreenLoader.hide();
        if (res.success) {
          this._notification.show(res.msg);
          this._dialogRef.close(true);
        } else {
          this._notification.show(res.msg);
        }
      });
  }
}
