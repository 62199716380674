<h2 class="title">
  Sit back and relax!<br />
  Your orders are getting processed...
</h2>

<!-- Circular Progress -->

<div
  class="circular-progress"
  ProgressStatus
  [value]="progressValue"
  [step]="step"
>
  <div class="circular-inside">{{ progressValue | number: "1.0-0" }}%</div>
</div>

<!-- Order Status -->
<div class="comments">
  <h2 class="m-0">Step {{ step }} of 3</h2>
  <hr />

  <!-- <h4 class="m-0"> Saved 1 Order Of 140 </h4> -->

  <div [ngSwitch]="step">
    <h3 *ngSwitchCase="1" class="m-0">
      Saved {{ processedOrder }} Shipments out of {{ totalOrder }} Shipments
    </h3>
    <h3 *ngSwitchCase="2" class="m-0">
      Booked {{ processedOrder }} Shipments out of {{ totalOrder }} Shipments
    </h3>
    <h3 *ngSwitchCase="3" class="m-0">
      Ready to ship {{ processedOrder }} Shipments out of
      {{ totalOrder }} Shipments
    </h3>
  </div>
</div>
