import { Component, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { FuseSidebarService } from "@fuse/components/sidebar/sidebar.service";
import { FuseConfigService } from "@fuse/services/config.service";
import { TranslateService } from "@ngx-translate/core";
import * as Sentry from "@sentry/angular";
import { BulkOrderStatusComponent } from "app/main/shared/modals/bulk-order-status/component/bulk-order-status.component";
import { BrowserStorageService } from "app/main/shared/services/browser-storage.service";
import { ClientAccountsService } from "app/main/shared/services/client-accounts.service";
import { LoginService } from "app/main/shared/services/login.service";
import { ProfileDataService } from "app/main/shared/services/profile-data.service";
import { TenantService } from "app/main/shared/services/tenant.service";
import * as _ from "lodash";
import { Subject, Subscription } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { SwitchPanelService } from "app/main/shared/services/switch-panel.service";

@Component({
  selector: "toolbar",
  templateUrl: "./toolbar.component.html",
  styleUrls: ["./toolbar.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class ToolbarComponent implements OnInit, OnDestroy {
  horizontalNavbar: boolean;
  rightNavbar: boolean;
  hiddenNavbar: boolean;
  languages: any;
  // navigation: any;
  selectedLanguage: any;
  userStatusOptions: any[];
  profile: any = {
    firstName: "",
    lastName: "",
    balance: 0,
    accountingDetails: "",
    status: 0,
    bulkProcess: ""
  };
  profileAvailable = false;
  profileDataSubscription: Subscription;
  showRefreshBalanceButton: boolean = false;
  refreshingBalance: boolean = false;
  isMultiTenantUser: boolean = false;

  lightTheme: boolean;
  isUserLoggedIn: boolean = this._loginService.isUserLoggedIn();

  clients: any[] = [];

  // Private
  private _unsubscribeAll: Subject<any>;

  tenantConfig = this._tenantService.getTenantConfig();

  constructor(
    private _fuseConfigService: FuseConfigService,
    private _fuseSidebarService: FuseSidebarService,
    private _translateService: TranslateService,
    private _loginService: LoginService,
    private _profileDataService: ProfileDataService,
    private _dialog: MatDialog,
    private _clientAccounts: ClientAccountsService,
    private _browserStorage: BrowserStorageService,
    private _tenantService: TenantService,
    private _switchPanelService: SwitchPanelService
  ) {
    // Set the defaults
    this.userStatusOptions = [
      {
        title: "Online",
        icon: "icon-checkbox-marked-circle",
        color: "#4CAF50"
      },
      {
        title: "Away",
        icon: "icon-clock",
        color: "#FFC107"
      },
      {
        title: "Do not Disturb",
        icon: "icon-minus-circle",
        color: "#F44336"
      },
      {
        title: "Invisible",
        icon: "icon-checkbox-blank-circle-outline",
        color: "#BDBDBD"
      },
      {
        title: "Offline",
        icon: "icon-checkbox-blank-circle-outline",
        color: "#616161"
      }
    ];

    this.languages = [
      {
        id: "en",
        title: "English",
        flag: "us"
      },
      {
        id: "tr",
        title: "Turkish",
        flag: "tr"
      }
    ];

    // this.navigation = navigation;

    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  // subUser = this._loginService.checkIfSubuser();

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Check and set user theme
    this.checkUserTheme();

    // Subscribe to the config changes
    this._fuseConfigService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((settings) => {
        this.horizontalNavbar = settings.layout.navbar.position === "top";
        this.rightNavbar = settings.layout.navbar.position === "right";
        this.hiddenNavbar = settings.layout.navbar.hidden === true;
      });

    // Set the selected language from default languages
    this.selectedLanguage = _.find(this.languages, {
      id: this._translateService.currentLang
    });

    if (
      this._loginService.isUserLoggedIn() &&
      !this._loginService.isOtpVerifyState()
    ) {
      this.patchAvatarName();
      this._profileDataService.getPermissions();
      this.fetchProfileData();
    }

    if (
      this._loginService.isUserLoggedIn() &&
      this._loginService.isMultipleAccount
    ) {
      this.fetchClients();
    }
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Toggle sidebar open
   *
   * @param key
   */
  toggleSidebarOpen(key): void {
    this._fuseSidebarService.getSidebar(key).toggleOpen();
  }

  /**
   * Search
   *
   * @param value
   */
  search(value): void {
    // Do your search here...
    console.log(value);
  }

  /**
   * Set the language
   *
   * @param lang
   */
  setLanguage(lang): void {
    // Set the selected language for the toolbar
    this.selectedLanguage = lang;

    // Use the selected language for translations
    this._translateService.use(lang.id);
  }

  logout(): void {
    this._loginService.logout();
  }

  fetchProfileData(): void {
    // console.log("fetchProfileData called toolbar.component.ts");
    this._profileDataService.getProfileData();

    this._profileDataService.profileData
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((profileObj) => {
        // console.log("profile sub toolbar.component.ts");
        if (!!Object.keys(profileObj).length) {
          const { profile, integrations } = profileObj;
          this.profile = profile;
          this.profileAvailable = true;

          //for refresh balance icon for unicommerce users
          if (integrations?.unicommerce !== 0 || integrations?.ESEC !== 0) {
            this.showRefreshBalanceButton = true;
          }
          this.refreshingBalance = false;

          if (profile?.bulkProcess === "open") {
            this.openBulkProcessing(profile);
          }
        }
      });
  }

  fetchClients(): void {
    let apiCalled = false;

    this._clientAccounts.clients
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((clients) => {
        if (clients.length || apiCalled) {
          this.clients = clients;
        } else if (!apiCalled) {
          apiCalled = true;
          this._clientAccounts.getClients();
        }
      });
  }

  refreshProfile() {
    this.refreshingBalance = true;
    this._profileDataService.getProfileData();
  }

  checkUserTheme(): void {
    const theme = this._browserStorage.getItemFromLS("colorTheme");
    theme ? this.setTheme(theme) : this.setTheme("theme-default");
  }

  setTheme(theme: string): void {
    this._browserStorage.setItemInLS("colorTheme", theme);
    this._fuseConfigService.config = { colorTheme: theme };

    if (theme === "theme-default") {
      this.lightTheme = true;
    } else this.lightTheme = false;
  }

  openBulkProcessing(profileData): void {
    if (!this._dialog.getDialogById("bulkProcessingModal")) {
      this._dialog.open(BulkOrderStatusComponent, {
        panelClass: "medium-modal",
        disableClose: true,
        id: "bulkProcessingModal",
        data: profileData
      });
    }
  }

  // openNotificationModal(): void {
  //   this._dialog.open(NotificationModalComponent, {
  //     id: "notificationModal",
  //     panelClass: "medium-modal",
  //     data: {
  //       show: {
  //         companyDetailsMsg: this.profile.status <= 1,
  //         rechargeMsg: this.profile.balance < 50000,
  //         accDetailsMsg: !this.profile.accountingDetails
  //       }
  //     }
  //   });
  // }

  patchAvatarName(): void {
    const { firstName, lastName, email, isMultiTenantUser } =
      this._loginService.getUser();

    if (isMultiTenantUser) {
      this.isMultiTenantUser = isMultiTenantUser;
    }

    if (firstName && lastName)
      Object.assign(this.profile, { firstName, lastName });

    this.captureUser(email);
  }

  // goToOldDashboard(): void {
  //   this._loginService.saveResponse(false);
  //   this._loginService.redirectToOldDashboard();
  // }

  // Capturing the User for sentry errors
  captureUser(email: string) {
    Sentry.configureScope((scope) => {
      scope.setUser({ email });
    });
  }

  switchToAnotherUser(SGID: string): void {
    const token = this._loginService.getToken();
    const rememberMe = this._loginService.isRememberMeChecked;

    this._clientAccounts.switchAccounts({ SGID, token }, (token: string) => {
      if (rememberMe) {
        this._loginService.setToken(token, true);
        window.location.reload();
      } else {
        const redirectUrl = `${window.location.origin}/login?token=${token}&rememberMe=no`;
        window.open(redirectUrl);
      }
    });
  }

  get isSwitchPanelVisible(): boolean {
    const switchPanelActiveFor = ["shippigo", "emiza"];

    return (
      this.isMultiTenantUser &&
      switchPanelActiveFor.includes(this.tenantConfig?.name)
    );
  }

  switchToAnotherPanel(switchTo: "shippigo" | "emiza") {
    const token = this._loginService.getToken();
    this._switchPanelService.switchToAnotherPanel(switchTo, token);
  }
}
