<div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="4px">
  <button mat-icon-button title="Track Order" routerLink="/actions/track-order">
    <mat-icon class="secondary-text">location_on</mat-icon>
  </button>

  <button
    mat-icon-button
    title="Rate Calculator"
    routerLink="/actions/rate-calculator"
  >
    <mat-icon
      title="Rate Calculator"
      svgIcon="calculator-alt"
      style="transform: scale(0.85) translate(0, -1px)"
      class="secondary-text"
    ></mat-icon>
  </button>

  <!-- Add Manual if b2b not present -->
  <button
    *ngIf="(b2bPermission$ | async) === false"
    mat-icon-button
    title="Add Manual Order"
    routerLink="/actions/manual-order/b2c"
  >
    <mat-icon class="secondary-text">add</mat-icon>
  </button>

  <!-- Add Manual if b2b is  present -->
  <button
    *ngIf="(b2bPermission$ | async) === true"
    mat-icon-button
    title="Add Manual Order"
    [matMenuTriggerFor]="addManual"
  >
    <mat-icon class="secondary-text">add</mat-icon>
  </button>

  <mat-menu #addManual="matMenu" xPosition="before" [overlapTrigger]="false">
    <button mat-menu-item routerLink="/actions/manual-order/b2b">B2B</button>

    <button mat-menu-item routerLink="/actions/manual-order/b2c">B2C</button>
  </mat-menu>

  <button
    mat-icon-button
    title="Import Orders"
    routerLink="/actions/import-orders"
  >
    <mat-icon class="secondary-text">add_to_queue</mat-icon>
  </button>

  <button mat-icon-button title="Bulk Orders" [matMenuTriggerFor]="bulkMenu">
    <mat-icon class="secondary-text">queue_play_next</mat-icon>
  </button>

  <mat-menu #bulkMenu="matMenu" xPosition="before" [overlapTrigger]="false">
    <button mat-menu-item routerLink="/actions/bulk-orders">
      New Bulk-Order
    </button>

    <button mat-menu-item routerLink="/batch-list/bulk">Bulk Batch</button>

    <!-- <button mat-menu-item routerLink="/batch-list/custom-batches">
      Custom Batch
    </button> -->

    <!-- <button mat-menu-item [matMenuTriggerFor]="portalsMenu">
      Online Portals
    </button> -->

    <!-- <mat-menu #portalsMenu="matMenu">
      <button mat-menu-item>
        Shopify
      </button>
      <button mat-menu-item>
        Magento
      </button>
    </mat-menu> -->
  </mat-menu>
</div>
