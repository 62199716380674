import { Component, Inject, OnInit, ViewEncapsulation } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "app-view-broadcast",
  templateUrl: "./view-broadcast.component.html",
  styleUrls: [
    "./view-broadcast.component.scss",
    "./../../../scss/responsive-modals.scss"
  ],
  encapsulation: ViewEncapsulation.None
})
export class ViewBroadcastComponent implements OnInit {
  title: string = "";
  content: string = "";

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    this.title = data.title;
    this.content = data.description;
  }

  ngOnInit(): void {}
}
