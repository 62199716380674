import { NgModule } from "@angular/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatCardModule } from "@angular/material/card";
import { DateRangeModalComponent } from "./date-range-modal/date-range-modal.component";
import { MatDialogModule } from "@angular/material/dialog";
import { MatButtonModule } from "@angular/material/button";
import { CommonModule } from "@angular/common";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatIconModule } from "@angular/material/icon";
import { FuseSharedModule } from "@fuse/shared.module";

@NgModule({
  declarations: [DateRangeModalComponent],
  imports: [
    CommonModule,
    MatCardModule,
    MatDatepickerModule,
    MatDialogModule,
    MatButtonModule,
    MatToolbarModule,
    MatIconModule,
    FuseSharedModule
  ]
})
export class DateRangeModule {}
