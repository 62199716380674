import { HttpClient } from "@angular/common/http";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { ToolbarNotification } from "app/main/shared/interfaces/toolbar-notificaton";
import { AppointmentDeliveryNotificationComponent } from "app/main/shared/modals/appointment-delivery-notification/appointment-delivery-notification/appointment-delivery-notification.component";
import { BulkOrderStatusComponent } from "app/main/shared/modals/bulk-order-status/component/bulk-order-status.component";
import { RequestFeedbackComponent } from "app/main/shared/modals/request-feedback/request-feedback.component";
import { ViewBroadcastComponent } from "app/main/shared/modals/view-broadcast/component/view-broadcast.component";
import { FullScreenLoaderService } from "app/main/shared/modules/full-screen-loader/full-screen-loader.service";
import { NotificationService } from "app/main/shared/services/notification.service";
import { OrdersDataService } from "app/main/shared/services/orders-data.service";
import { ProfileDataService } from "app/main/shared/services/profile-data.service";
import { BehaviorSubject, Subject, timer } from "rxjs";
import {
  concatMap,
  delay,
  retryWhen,
  share,
  switchMap,
  take,
  takeUntil
} from "rxjs/operators";
import { ToolbarNotificationService } from "../../toolbar-notification.service";

@Component({
  selector: "toolbar-notification",
  templateUrl: "./toolbar-notification.component.html",
  styleUrls: ["./toolbar-notification.component.scss"]
})
export class ToolbarNotificationComponent implements OnInit, OnDestroy {
  private _unsubscribeAll: Subject<any> = new Subject();
  isOpen: boolean;
  notifications: any[] = [];
  load$ = new BehaviorSubject("");
  refreshed: boolean = false;
  profileData: any[];

  constructor(
    private _toolbarNotification: ToolbarNotificationService,
    private _router: Router,
    private _ordersDataService: OrdersDataService,
    private _dialog: MatDialog,
    private _profileDataService: ProfileDataService,
    private _http: HttpClient,
    private _fullScreenLoader: FullScreenLoaderService,
    private _notification: NotificationService
  ) {}

  ngOnInit(): void {
    this.handleNotificationChange();
    this.fetchNotifications();
    this.fetchProfileData();
  }

  fetchProfileData() {
    this._profileDataService.profileData
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((profileObj) => {
        this.profileData = profileObj?.profile;
      });
  }

  refreshNotifications() {
    this.refreshed = true;
    this.load$.next("");
  }

  handleNotificationChange() {
    this._toolbarNotification.onNotificationUpdated
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(() => {
        this.notifications = this._toolbarNotification.get();
      });
  }

  fetchNotifications() {
    const oneMinute = 60 * 1000;
    const fiveMinute = 60 * 5000;

    this.load$
      .pipe(
        switchMap(() =>
          timer(0, fiveMinute).pipe(
            concatMap(() => this._toolbarNotification.getNotifications()),
            retryWhen((errors) => errors.pipe(delay(oneMinute * 3), take(3))),
            share()
          )
        ),
        takeUntil(this._unsubscribeAll)
      )
      .subscribe((res: any) => {
        this.refreshed = false;
        if (res.success) {
          this.pushNotifications(res.data);
        }
      });
  }

  pushNotifications(notifications: ToolbarNotification[]) {
    notifications.forEach((notification) => {
      let { link, icon, action, previousId, _id, show = true } = notification;
      let processingNotificationIndex: number = -1;

      if (!!previousId) {
        processingNotificationIndex = notifications.findIndex(
          (ele) => ele._id === previousId
        );
      }

      if (link) {
        icon = "link";
        action = "openURL";
      }

      if (!icon) {
        icon = "help_outline";
      }

      if (action === "refreshOrder") {
        icon = "update";
      }

      if (action === "updateWallet") {
        this._profileDataService.getProfileData();
        this.markAsRead([_id]);
        show = false;
      }

      if (show) {
        this._toolbarNotification.add({ ...notification, icon, action });
      }

      //checking for processing bulk-order notification to remove them
      if (processingNotificationIndex !== -1)
        this.dismiss(undefined, previousId);
    });
  }

  onClickOutside(): void {
    this.isOpen = false;
  }

  toggleDropdown(): void {
    this.isOpen = !this.isOpen;
  }

  takeAction(notification: ToolbarNotification) {
    const {
      url,
      link,
      action,
      batchId,
      previousId,
      _id,
      description,
      title,
      SGID,
      orderId,
      SG_ORDERID
    } = notification;

    switch (action) {
      case "changeRoute":
        this._router.navigateByUrl(url);
        break;

      case "openURL":
        window.open(link, "_blank");
        break;

      case "refreshOrder":
        this._ordersDataService.refreshOrders();
        this.clearSpecificActionNotifications(notification);
        break;

      // case "updateWallet":
      //   this._profileDataService.getProfileData();
      //   this.dismiss(undefined, _id);
      //   break;

      case "appointmentDeliveryModal":
        this._dialog.open(AppointmentDeliveryNotificationComponent, {
          panelClass: "medium-modal",
          disableClose: true,
          data: { SGID, orderId }
        });
        this.dismiss(undefined, _id);
        break;

      case "openProcessModal":
        this._dialog.open(BulkOrderStatusComponent, {
          panelClass: "medium-modal",
          disableClose: true,
          data: { ...this.profileData, batchId }
        });

        if (!!previousId) this.dismiss(undefined, _id);

      case "showBroadcast":
        this._dialog.open(ViewBroadcastComponent, {
          data: {
            title,
            description
          },
          panelClass: "medium-modal"
        });

        this.dismiss(undefined, _id);
        break;

      case "requests":
        this._router.navigateByUrl("orders/customer-response/request");
        this.getSingleOrder({
          type: action,
          SG_ORDERID
        });

        this.dismiss(undefined, _id);
        break;

      case "feedback":
        this._router.navigateByUrl("orders/customer-response/feedback");
        this.getSingleOrder({
          type: action,
          SG_ORDERID
        });

        this.dismiss(undefined, _id);
        break;

      default:
        break;
    }
  }

  getSingleOrder(body): void {
    this._fullScreenLoader.show();
    this._http
      .post("user/deliveryManager/getsingleorder", body)
      .subscribe((res: any) => {
        this._fullScreenLoader.hide();
        if (res.success) {
          const { type } = body;
          const heading = type === "requests" ? "Request" : "Feedback";
          this._dialog.open(RequestFeedbackComponent, {
            data: {
              heading,
              type,
              ...res.data
            },
            panelClass: "medium-modal"
          });
        } else {
          this._notification.show(res?.message || res?.msg);
        }
      });
  }

  // clear Specific Action type notification
  clearSpecificActionNotifications({ action }: ToolbarNotification) {
    const notificationIds = this.notifications.filter(
      (notification: ToolbarNotification) => {
        if (notification.action === action) {
          this._toolbarNotification.dismiss(notification._id);
          return true;
        }

        return false;
      }
    );

    this.markAsRead(notificationIds);
  }

  // to dismiss particular notification
  dismiss(event, id: string): void {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    this.markAsRead([id]);
    this._toolbarNotification.dismiss(id);
  }

  // to clear all notifications
  clearNotifications(): void {
    let notificationIds: any[] = [];
    let nonClearableNotifications: any[] = [];
    if (!this.notifications.length) return;

    this.notifications.forEach((notification: ToolbarNotification) => {
      notification?.canNotBeCleared
        ? nonClearableNotifications.push(notification)
        : notificationIds.push(notification._id);
    });

    this.markAsRead(notificationIds);
    this._toolbarNotification.removeClearableNotifications(
      nonClearableNotifications
    );
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  markAsRead(notificationIds: string[]) {
    this._toolbarNotification.markAsRead({ notificationIds }).subscribe(() => {
      // do nothing
    });
  }
}
