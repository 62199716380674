import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
  UrlTree
} from "@angular/router";
import { Observable } from "rxjs";
import { map, skipWhile, take, tap } from "rxjs/operators";
import { NotificationService } from "../services/notification.service";
import { ProfileDataService } from "../services/profile-data.service";

@Injectable({
  providedIn: "root"
})
export class BatchListGuard implements CanActivateChild {
  constructor(
    private _router: Router,
    private _profileDataService: ProfileDataService,
    private _notification: NotificationService
  ) {}

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const {
      routeConfig: { path }
    } = childRoute;

    if (path === "custom-batches") {
      const userPermissions = this._profileDataService._userPermissions.getValue();

      if (!Object.keys(userPermissions).length) {
        this._profileDataService.getPermissions();
      }

      return this._profileDataService.userPermissions.pipe(
        skipWhile((userPermissions) => !Object.keys(userPermissions).length),
        take(1),
        map((value) => {
          return !!value["filter"];
        }),
        tap((val) => {
          if (!val) {
            this.navigateToHome();
          }
        })
      );
      // } else {
      //   return !!userPermissions["filter"];
      // }
    } else {
      const profileData = this._profileDataService.profileData.getValue();

      if (!Object.keys(profileData).length) {
        this._profileDataService.getProfileData();
      }

      return this._profileDataService.integrations.pipe(
        skipWhile((integrations) => !Object.keys(integrations).length),
        take(1),
        map((value) => {
          return !!value[path] || path === "bulk";
        }),
        tap((val) => {
          if (!val) {
            this.navigateToHome();
          }
        })
      );
      //   return this._profileDataService.integrations.pipe(
      //     map((value) => {
      //       return !!value[path] || path === "bulk";
      //     }),
      //     tap((val) => {
      //       if (!val) {
      //         this.navigateToHome();
      //       }
      //     }),
      //     take(1)
      //   );
    }
  }

  navigateToHome(): void {
    this._notification.show("You are not authorised to access this feature.");
    this._router.navigate(["/"]);
  }
}
