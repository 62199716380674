import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { of } from "rxjs";
import { LoginService } from "../services/login.service";
import { ProfileDataService } from "../services/profile-data.service";
import { environment } from "environments/environment";

const apiEndPoint = environment.apiEndPoint;

@Injectable()
export class PermissionInterceptor implements HttpInterceptor {
  authError = of(
    new HttpResponse({
      status: 200,
      body: {
        success: false,
        msg: "You are not authorised to perform this action."
      }
    })
  );

  private dontCheckPermission = [
    "assets/",
    "check",
    "user/registration/register",
    "user/registration/login",
    "user/registration/checkCred",
    "user/config/forgotPassword",
    "user/config/updatePassword",
    "user/config/getPermissions"
  ];

  constructor(
    private _profileService: ProfileDataService,
    private _loginService: LoginService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const reqUrl = req.url;

    if (this.checkSkipPermission(reqUrl)) {
      return next.handle(req);
    }

    if (!this._loginService.checkIfSubuser()) {
      return next.handle(req);
    }

    const apiPermissions = this._profileService._apiPermissions.getValue();

    const [first, ...remainingPaths] = reqUrl
      .replace(apiEndPoint, "")
      .split("/")
      .filter((path) => !!path);

    if (!Object.keys(apiPermissions).length) {
      // If no value call api and return request
      this._profileService.getPermissions();
      return next.handle(req);
    } else {
      // check permission then pass
      if (this.checkPermission(remainingPaths, apiPermissions)) {
        return next.handle(req);
      } else {
        return this.authError;
      }
    }
  }

  checkSkipPermission(reqUrl: string): boolean {
    let skipPermission = false;

    for (const url of this.dontCheckPermission) {
      if (reqUrl.includes(url)) {
        skipPermission = true;
        break;
      }
    }

    return skipPermission;
  }

  checkPermission(apiPath: string[], permissionObject): boolean {
    const [first, second, third] = apiPath;

    let isAuthorised = true;

    if (typeof permissionObject[first] === "object") {
      if (typeof permissionObject[first][second] === "object") {
        isAuthorised = permissionObject[first][second][third];
      } else {
        isAuthorised = permissionObject[first][second];
      }
    } else {
      isAuthorised = permissionObject[first];
    }

    if (isAuthorised === false) return false;
    return true;
  }
}
