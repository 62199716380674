import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { NotificationService } from "app/main/shared/services/notification.service";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class NdrService {
  cardOrders: BehaviorSubject<any> = new BehaviorSubject({});

  constructor(
    private _http: HttpClient,
    private _notification: NotificationService
  ) {}

  getPendingNdrCount = (body) =>
    this._http.post("user/reports/ndrPendingCount", body);
}
