import { Injectable } from "@angular/core";
import { environment } from "environments/environment";
import { BrowserStorageService } from "./browser-storage.service";

@Injectable({
  providedIn: "root"
})
export class LogoByThemeService {
  constructor(private _browserStorage: BrowserStorageService) {}

  get themeMode(): "light" | "dark" {
    const colorTheme = this._browserStorage.getItemFromLS("colorTheme");

    if (colorTheme === "theme-blue-gray-dark") {
      return "dark";
    }
    return "light";
  }

  getLogosByTheme = (logoName: string) => {
    const { lightLogos, darkLogos } = environment.tenant;

    if (this.themeMode === "dark") {
      return lightLogos[logoName];
    }

    return darkLogos[logoName];
  };
}
