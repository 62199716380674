<div class="dialog-content-wrapper">
  <mat-toolbar matDialogTitle class="mat-accent m-0">
    <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
      <span class="title dialog-title">
        Reattempt Delivery
      </span>
      <button
        mat-icon-button
        [mat-dialog-close]="false"
        aria-label="Close dialog"
      >
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar-row>
  </mat-toolbar>

  <div mat-dialog-content class="p-24 pb-0 m-0" fusePerfectScrollbar>
    <mat-form-field appearance="outline" fxFlex>
      <mat-label>Add Instructions</mat-label>
      <textarea
        matInput
        [formControl]="instruction"
        required
        maxlength="120"
        rows="5"
      ></textarea>
      <mat-hint align="end">{{ instruction.value.length }} / 120</mat-hint>
      <mat-error
        ><error-message
          [formControlErr]="instruction"
        ></error-message>
      </mat-error>
    </mat-form-field>
  </div>

  <div
    mat-dialog-actions
    class="m-0 p-16"
    fxLayout="row"
    fxLayoutAlign="end center"
  >
    <button
      mat-flat-button
      class="mat-accent"
      [disabled]="instruction.invalid"
      (click)="submit()"
    >
      Submit
    </button>
  </div>
</div>
