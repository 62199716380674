import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatIconModule } from "@angular/material/icon";
import { FuseConfirmDialogModule } from "@fuse/components";
import { FuseSharedModule } from "@fuse/shared.module";
import { DateFieldComponent } from "./child-components/date-field/date-field.component";
import { InputFieldComponent } from "./child-components/input-field/input-field.component";
import { SelectFieldComponent } from "./child-components/select-field/select-field.component";
import { TableHeadComponent } from "./child-components/table-head/table-head.component";
import { TableRowsComponent } from "./child-components/table-rows/table-rows.component";
import { LargePreviewFormComponent } from "./components/large-preview-form.component";
import { BoxDataComponent } from "./child-components/box-data/box-data.component";
import { WarehouseListComponent } from "./child-components/warehouse-list/warehouse-list.component";
import { DirectivesModule } from "../../directives/directives.module";

@NgModule({
  declarations: [
    LargePreviewFormComponent,
    InputFieldComponent,
    TableHeadComponent,
    TableRowsComponent,
    DateFieldComponent,
    SelectFieldComponent,
    BoxDataComponent,
    WarehouseListComponent
  ],
  imports: [
    CommonModule,
    // shared
    FuseSharedModule,
    FuseConfirmDialogModule,
    DirectivesModule,

    // material
    MatButtonModule,
    MatIconModule,
    MatDatepickerModule
  ],
  exports: [LargePreviewFormComponent]
})
export class LargePreviewFormModule {}
