import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DecimalsDirective } from "./decimals.directive";
import { UppercaseDirective } from "./uppercase.directive";
import { DigitsDirective } from "./digits.directive";
import { ClickOutsideDirective } from "./click-outside.directive";
import { TrimInputValueDirective } from './trim-input-value.directive';

@NgModule({
  declarations: [
    DecimalsDirective,
    UppercaseDirective,
    DigitsDirective,
    ClickOutsideDirective,
    TrimInputValueDirective
  ],
  imports: [CommonModule],
  exports: [
    DecimalsDirective,
    UppercaseDirective,
    DigitsDirective,
    ClickOutsideDirective,
    TrimInputValueDirective
  ]
})
export class DirectivesModule {}
