import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "storenamepipe"
})
export class IntegratedStoresPipe implements PipeTransform {
  storenames = {
    localhost: "Localhost",
    shippigo: "Shippigo",
    shopify: "Shopify",
    woocommerce: "Woocommerce",
    magento1: "Magento 1.x",
    magento2: "Magento 2.x",
    unicommerce: "Unicommerce",
    ESEC: "Easyecom",
    zoho: "Zoho Inventory",
    amazon: "Amazon",
    instamojo: "Instamojo",
    flipshop: "FlipShop",
    emiza: "Emiza",
    clickpost: "Clickpost"
  };

  transform(value: string): string {
    return this.storenames[value] || value;
  }
}
