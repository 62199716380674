import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { NotificationService } from "./notification.service";
import { ToolbarNotificationService } from "app/main/ui/toolbar-notification.service";
import { allNotifications } from "./../ts/notificationConfig";
import { Router } from "@angular/router";
import { AbstractControl } from "@angular/forms";
import { FullScreenLoaderService } from "../modules/full-screen-loader/full-screen-loader.service";
import { ShippingApiService } from "app/main/settings/shipping/shipping-api.service";

@Injectable({
  providedIn: "root"
})
export class WarehouseService {
  constructor(
    private _http: HttpClient,
    private _notification: NotificationService,
    private _toolbarNotification: ToolbarNotificationService,
    private _router: Router,
    private _fullScreenLoader: FullScreenLoaderService,
    private _shippingApiService: ShippingApiService
  ) {}
  // declarations
  shippigoWarehouses: BehaviorSubject<any> = new BehaviorSubject([]);
  shopifyWarehouses: BehaviorSubject<any> = new BehaviorSubject([]);
  magento2Warehouses: BehaviorSubject<any> = new BehaviorSubject([]);

  storeIdObj = {
    shopify: undefined,
    woocommerce: undefined,
    magento: undefined
  };

  storeIdApiObject = {
    shopify: "user/order/getShopifyStores",
    woocommerce: "user/order/woo/getStores",
    magento2: "user/order/mag/2/getStores",
    magento1: "user/order/mag/1/getStores"
  };

  patchDefaultWarehouse(
    warehouses: any[],
    control: AbstractControl,
    warehouseCode?: string,
    callbackFn?
  ) {
    let selectedWarehouse;

    if (warehouseCode) {
      control.setValue(warehouseCode);
      selectedWarehouse = warehouseCode;
    } else {
      const defaultWarehouse = warehouses.find(
        (warehouse) => warehouse.default
      );

      if (defaultWarehouse) {
        control.setValue(defaultWarehouse.warehouseCode);
        selectedWarehouse = defaultWarehouse.warehouseCode;
      }
    }
    // to detect changes and call api after warehouse change
    control.markAsDirty();

    if (callbackFn) {
      callbackFn(selectedWarehouse);
    }
  }

  getShippigoWarehouses(
    notificationMsg:
      | string
      | null = "No warehouse found. Please add one. \nIt is required for Importing and Booking Orders."
  ): void {
    this._http.get("user/order/warehouse").subscribe(
      (res: any) => {
        if (res.success) {
          const warehouses = res.warehouse.filter(
            (warehouse) => warehouse.active
          );
          this.shippigoWarehouses.next(warehouses);

          // remove toolbar notification if warehouse added
          this._toolbarNotification.remove(allNotifications.warehouse._id);
        } else {
          if (res.notFound) {
            // add toolbar notification is warehouse is not added
            this._toolbarNotification.add(allNotifications.warehouse);
            if (notificationMsg) {
              this._notification
                .show(notificationMsg, "Add Now", 10000)
                .onAction()
                .subscribe(() =>
                  this._router.navigateByUrl("settings/shipping?tab=warehouses")
                );
            }
          } else {
            this._notification.show(res.msg);
          }

          this.shippigoWarehouses.next([]);
        }
      },
      (error) => {
        this.shippigoWarehouses.next([]);
        throw error;
      }
    );
  }

  getWarehousesFor(portalName: string, notificationMsg?: string | null): void {
    if (portalName !== "shippigo") {
      this.getStoreId(portalName, this.callPortalWarehouseApi(portalName));
    } else {
      this.getShippigoWarehouses(notificationMsg);
    }
  }

  callPortalWarehouseApi(portalName: string): () => void {
    const apiUrlObj = {
      shopify: "user/order/getShopifyWarehouse",
      magento2: "user/order/mag/2/getWarehouses"
    };

    return () => {
      this._http
        .post(apiUrlObj[portalName], {
          storeId: this.storeIdObj[portalName]
        })
        .subscribe(
          (res: any) => {
            if (res.success) {
              this[`${portalName}Warehouses`].next(res.warehouses);
            } else {
              this._notification.show(res.msg);
              this[`${portalName}Warehouses`].next([]);
            }
          },
          (err) => {
            this[`${portalName}Warehouses`].next([]);
            throw err;
          }
        );
    };
  }

  syncWarehouse(platformName) {
    const platform = platformName.toLowerCase();

    this.getStoreId(platform, this.callSyncWarehouseApi.bind(this, platform));
  }

  callSyncWarehouseApi(platform: string) {
    this._fullScreenLoader.show();

    this._shippingApiService
      .syncWarehouses(platform, {
        storeId: this.storeIdObj[platform]
      })
      .subscribe((res: any) => {
        this._fullScreenLoader.hide();
        if (res.success) {
          this.getWarehousesFor(platform);
        }
        this._notification.show(res.msg);
      });
  }

  getStoreId(route: string, callbackFn: () => void): void {
    const apiRoute = this.storeIdApiObject[route];

    if (!this.storeIdObj[route]) {
      this._http.get(apiRoute).subscribe(
        (res: any) => {
          if (res.success) {
            this.storeIdObj[route] = res.stores[0].storeId;
            if (callbackFn) {
              callbackFn();
            }
          } else {
            this.storeIdObj[route] = "";
            this._notification.show(res.msg);
          }
        },
        (err) => {
          this.storeIdObj[route] = "";
          throw err;
        }
      );
    } else if (!this.storeIdObj[route]) {
      this._notification.show("No stores found");
    } else {
      callbackFn();
    }
  }
}
